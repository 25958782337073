@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.documentRow {
  min-height: 55px;
  align-items: center;

  + .documentRow {
    border-top: 1px solid $thin-blue;
  }
}

.documentItems {
  padding: 8px 0;
  gap: 10px 20px
}

.documentFoldersTitle {
  font-size: $main-font-size;
  line-height: 24px;
  font-weight: bold;
  text-transform: unset;
  background-color: $light-blue-gray;
  color: $dark-blue;
  border-radius: $main-border-radius;
  padding: 8px 24px 8px 8px;
  margin-right: 10px;
}

.documentFoldersTitleIcon {
  display: flex;
  padding: 8px 7px;
}

.rotate {
  transform: rotate(180deg);
}

.documentFoldersPath {
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
}

.documentFoldersPathBold {
  font-weight: bold;
}

.popover {
  box-shadow: 0 0 8px rgba(0, 142, 221, 0.2);
  border-radius: $main-border-radius;
  background: $white;
}

.documentFolders {
  min-width: 344px;
  padding: 4px 0;

  [class*="MuiTreeItem-iconContainer"] {
    display: none;
  }

  [class*="MuiTreeItem-label"] {
    padding: 8px 12px;
    font-size: $main-font-size;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
  }

  [class*="MuiTreeItem-group"] {
    margin-left: 0;

    [class*="MuiTreeItem-label"] {
      padding-left: 30px;

      &:hover {
        background: $dropdown-hovered-color;
      }
    }
  }
}

.documentsCountLabel {
  display: inline-block;
  width: 16px;
  height: 20px;
  text-align: center;
  background: rgba($dusty-gray, .5);
  border-radius: $main-border-radius;
}

.label {
  &:hover {
    background: $dropdown-hovered-color;
  }
}

.card {
  height: 100%;
}

.breadcrumbs {
  color: $dark-blue;
  padding-top: 10px;
}

.sectionLabel {
  width: 128px;
  min-width: 128px;
  margin-right: 20px;

  p {
    font-weight: 600;
    font-size: 12px;
    color: $slate-gray;
  }
}

.selected {
  background: $dropdown-selected-color !important;
}

.content {
  padding: 0;
}
