@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.dashboardManagerBlock {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: $main-border-radius;
  min-height: 100%;
  background: $white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #fafbfc;
}

.dashboardManagerBlockTop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.relationshipManagerName {
  font-weight: 600;
  font-size: 14px;
}

.dashboardManagerBlockAvatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.dashboardManagerBlockAvatarContainer {
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  width: 100%;
  display: flex;
  width: 50px;
}

.dashboardManagerBlockDescription {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-left: 15px;
  align-items: center;
}
.dashboardManagerBlockBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.nameEmailContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboardManagerBlockSetupButton {
  display: flex;
  align-items: flex-end;
  margin-right: 15px;
}

.dashboardManager {
  display: flex;
  height: 57px;
  border: 1px solid $border-color;
  border-radius: $main-border-radius;
  padding: 8px 16px 8px 16px;
  flex-direction: row;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.questionsTitle {
  color: var(--Black, #1a202c);
  font-size: 14px;
  font-weight: 600;
}

.questionsText {
  color: var(--Grey-500, #718096);
  font-size: 12px;
  font-weight: 400;
}
.dashboardManagerBlockDivider {
  margin: 17px 0;
  width: 100%;
  height: 1px;
  background: $gallery;

  @media only screen and (max-width: 1279px) {
    margin: 15px 0 10px;
  }
}

.dashboardManagerBlockContactMeeting {
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;

  svg {
    display: inline-block;
  }

  a {
    text-decoration: none;
    color: $primary-color;
    font-size: 12px;
    font-weight: 400;

    &:hover {
      color: $secondary-color;
    }
  }

  + .dashboardManagerBlockContact {
    padding-top: 5px;
  }
}

.dashboardManagerBlockContact {
  font-size: $main-font-size;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  text-underline-position: under;

  svg {
    vertical-align: middle;
  }

  a {
    text-align: center;
    text-decoration: none;
    color: #1a202c;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      color: $secondary-color;
    }
  }

  + .dashboardManagerBlockContact {
    margin-left: 0px;
    padding-left: 2px;
  }
}

.contactIcon {
  width: 17px;
  height: 17px;
  margin-right: 3px;

  path,
  rect {
    stroke: var(--Grey-500, #718096) !important;
  }
}

.phoneIcon {
  width: 17px;
  height: 17px;
  margin-right: 3px;

  path,
  rect {
    stroke: var(--Grey-500, #718096) !important;
    stroke-width: 1;
  }
}

.contactLink {
  color: var(--Grey-500, #718096) !important;
  font-weight: 500 !important;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduleMeeting {
  color: var(--Grey-500, #718096) !important;
  font-weight: 500;
  margin-top: 2px;
}
