@import '../../assets/styles/variables.scss';

.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.loader {
  position: relative;
  top: $header-height;
  left: $drawer-collapsed-width;
  right: 0;
  width: 100%;
}

.reduced {
  left: $drawer-expanded-width;
}
