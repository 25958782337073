@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.label {
  margin: 0;
  position: relative;
  padding: 10px;
  width: 60px;
  height: 40px;
  border-radius: $small-border-radius;
  border: 1px solid $button-border-color;
  overflow: hidden;

  &:first-of-type {
    margin-right: 12px;
  }

  :global {
    .MuiButtonBase-root {
      svg {
        display: none;
      }
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      text-align: center;
      width: 100%;
      z-index: 1;
    }
  }

  .root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }

  .rootChecked {
    background: $success-green;

    + span {
      color: $white;
    }
  }
}
