@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../pages/ClientIntakePage/ClientIntakePage.module';

.formContent {
  padding: 40px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.integrateButton {
  background: $success-green;

  &:hover {
    background: $currency-up;
  }
}

.skipButton {
  background: transparent;
  color: $primary-color;
  text-decoration: underline;
  min-width: unset;

  &:hover {
    background: transparent;
  }
}
