@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.archivedChip {
  padding: 7px 8px;
  height: 32px;
  border-radius: $small-border-radius;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid $slate-gray;
  background: $slate-gray;
  color: $white;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: default;
}

.archivedChipIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;

  path {
    &:nth-child(2) {
      fill: $white;
    }
  }
}
