@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.clientsTable {
  tr {
    td,
    th {
      font-size: $main-font-size;

      &:nth-child(1) {
        width: 75px;
      }

      &:nth-child(2) {
        width: 200px;
      }

      &:nth-child(3),
      &:nth-child(10),
      &:nth-child(11) {
        width: 9vw;
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 275px;
        }

        &:nth-child(2) {
          width: 175px;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(9),
        &:nth-child(10) {
          width: auto;
        }

        &:last-child {
          width: 175px;
        }
      }
    }
  }
}

.clientsTableParticipant {
  tr {
    td,
    th {
      font-size: $main-font-size;

      &:nth-child(1) {
        width: 75px;
      }

      &:nth-child(2) {
        width: 200px;
      }

      &:nth-child(3) {
        width: 175px;
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 275px;
        }

        &:nth-child(2) {
          width: 175px;
        }

        &:nth-child(3),
        &:nth-child(4) {
          width: auto;
        }
      }
    }
  }
}

.clientsTable {
  table tbody {
    max-height: 60vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 75vh !important;
    }
  }
}

.itemsCount {
  font-weight: 0 !important;
}

.salesforceContainer {
  padding: 0 !important;
}

.value {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.minAndMax {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  width: calc(100% - 275px - 175px - 175px - 175px);
}

.pastClient {
  td {
    height: 30px;
  }
}

.footerLabel {
  overflow: visible;
}

.nestedRowColumn {
  padding: 15px;
  background: $white;
}

.sectionTitle {
  font-size: 14px;
}
