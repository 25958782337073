@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.iconRoot {
  width: 100%;
  height: 100%;
}

.checkIcon {
  fill: $de-york;
}

.selectInput {
  width: 100%;
}

.card {
  height: 100%;
  .selectInput {
    :global {
      .MuiInput-root {
        height: 36px;
        border-radius: $main-border-radius;
      }
      .MuiInputBase-input {
        padding: 8px 8px 8px 0 !important;
      }
      .MuiInputBase-root {
        padding: 0;
      }
    }
  }
}

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 200px;
        > svg {
          vertical-align: bottom;
          margin-right: 6px;
        }
      }
      &:nth-child(4) {
        width: 150px;
      }

      &:nth-child(5) {
        width: 200px;
      }

      &:last-child {
        width: 50px;
      }
    }
  }
}


.submitModalButton {
  width: 100%;
  min-height: 52px !important;
}

.fieldsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  table {
    width: 100%;
    table-layout: fixed;
  }

  td {
    padding-bottom: 2px !important;
  }
  tr:nth-child(2n) {
    padding-bottom: 8px;
    display: table-row;
  }
  label {
    font-weight: 600;
    font-size: 14px;
    color: $secondary-color;
    width:19px;
  }
  p {
    overflow: hidden;
  }
}

.bankAccountNumberTooltip {
  background-color: $dark-blue;
}

.bankAccountNumberTooltipArrow {
  color: $dark-blue;
}

.editModal {
  width: 465px;
}

.editModalFooter {
  justify-content: space-between;
  margin-top: 8px;
}
