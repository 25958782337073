@import '../../../assets/styles/colors.scss';

.filterIcon {
  height: 24px;
  // position to the left of the text
}

.buttonGrid {
  // fill the width of the parent
  width: 100%;
  // fill the height of the parent
  height: 100%;
  // center the content
  display: inherit;
}

.filterTitle {
  background-color: red;
}

.filterButtonActive {
  padding: 13px;
  height: 46px;
  min-width: 46px;
  width: auto;
  background-color: $debtor-gray;

  .filterButtonIcon {
    path {
      stroke: $secondary-color;
    }
  }

  &:hover {
    background-color: $debtor-gray;
  }
}

.filterButton {
  background: transparent;
  padding: 13px;
  color: $secondary-color;
  height: 46px;
  min-width: 46px;
  width: auto;

  &:hover {
    background-color: $button-border-color;
  }
}

.filterButtonActive {
  background-color: $debtor-gray;

  .filterButtonIcon {
    path {
      stroke: $secondary-color;
    }
  }
}

.filterButtonIcon {
  margin: 0;
  height: 20px;
  width: 20px;
}

.filterCount {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: $white;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  background-color: $primary-color;
}

.filterCountHidden {
  display: none;
}

.filterCountActive {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: $white;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  background-color: $primary-color;
}

.filterCount {
  font-size: 12px !important;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.filterButtonSmall {
  min-width: unset;
  width: 50px;
  padding: 8px 0;

  :global {
    .MuiButton-startIcon {
      margin: 0;
    }
    .MuiButton-endIcon {
      display: none;
    }
  }

  .filterText {
    display: none;
  }
}

.smallFilter {
  margin-right: 5px !important;
  width: 40px !important;
  height: 40px !important;
}
