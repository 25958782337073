@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
.card {
  background-color: $light-gray;
}

.button {
  margin-left: 10px;
}

.buttonIcon {
  margin-right: 10px;
}

.editPriorityButton {
  color: $sunshade !important;
  border-color: $sunshade !important;
}

.buttonIconWarning {
  > path {
    stroke: $sunshade;
  }
}


.paidOff {
  background-color: $green-background;
  color: $green-text;
  padding: 5px 10px;
  border-radius: $small-border-radius;
  }