@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.modal {
  height: 80vh;
  min-width: 1000px;
  padding: 26px 0px 0px 0px;
  > div > svg {
    margin-right: 0px !important;
  }
}

.aliasesTable {
  table {
    tbody {
      max-height: calc(80vh - 270px);
    }
  }
}
