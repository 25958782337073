@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.wrapper {
  min-height: 200px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  gap: 5px;
}

.tooltipError {
  background-color: $red-background;
  color: $secondary-color;
  padding: 10px;
  max-width: 15vw;
}

.errorTitle {
  color: $red-text;
  margin-bottom: 5px;;
}

.tooltipArrow {
  color: $red-background;

  &::before {
    background-color: $red-background;
  }
}

.row {
  padding: 2px 10px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  border-radius: $small-border-radius;
  box-sizing: border-box;
  background-color: $border-color;
  border: 1px solid $border-color;

  &.selected {
    background-color: $green-background;
    border: 1px solid $success-green;
  }

  &.error {
    background-color: $red-background;
    border: 1px solid $red;
  }
}

.link {
  display: flex;
}

.nameContainer {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-start;

  &.nameContainerPrimary {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fileName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: $secondary-color;
}

.sheetNameSelect {
  width: 275px;
  height: 36px;

  :global {
    .MuiInput-input {
      border: 1px solid $input-border-color;
    }
  }
}

.icons {
  display: flex;
  align-items: center;
}

.childBorders {
  position: relative;
  height: 1px;
  width: calc(100% - 13px);
  margin-left: 13px;
  background: $secondary-color;

  &:before {
    content: ' ';
    position: absolute;
    top: -15px;
    left: 0;
    height: 15px;
    width: 1px;
    background: $secondary-color;
  }
}

.menuItemDelete {
  color: $red-text;
  font-weight: 500;
}
