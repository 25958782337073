@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 200px;
  padding: 4px 10px;
  border-radius: $main-border-radius;
  color: $white;
  background-color: #22C55E;

  & > a {
    display: flex;
    align-items: center;
    color: $white;
    cursor: default;

    & > p {
      line-height: 15px;
      font-size: $main-font-size;
    }
  }
}

.icon {
  height: 28px;
  margin-right: 10px;

  svg {
    width: 21px;
    height: 28px;
  }
}

.action {
  position: absolute;
  padding: 3px;
  top: -8px;
  right: -8px;
  display: flex;
  border: 1px solid $white;
  background: $red;
  border-radius: 50%;
  cursor: pointer;

  >svg path {
      fill: $white;
  }

  &:hover {
    background: $light-red;
  }
}

.title {
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.notProvided {
  background-color: $concrete;
}

.clickable {
  cursor: pointer;
}

.sizeLabel {
  max-width: 56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $white;
}

.small {
  width: 185px;
}
