@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.container {
  min-width: 200px;
}

.progressBar {
  width: 80%;
}

.progressBarFullWidth {
  width: 100%;
}
