@import '../../../assets/styles/colors';

.root {
  background-color: transparent;
  color: $primary-color;
  text-transform: capitalize;

  &:hover {
    background-color: transparent;
  }
}

.disabled {
  cursor: not-allowed;

  > svg {
    path {
      stroke: $transparent-gray !important;
    }
  }
}

.label {
  font-size: 14px;
  white-space: nowrap;
  border: none;
  min-width: 32px;
  padding: 8px 4px;
  &:hover {
    border: none;
    background-color: $button-border-color;
  }
  > svg {
    width: 24px;
    height: 24px;
    > path {
      padding: 5.25px;
    }
  }
}

.icon * {
  stroke: $primary-color;
  stroke-width: 1px;
}

.big {
  height: 48px;
}
