@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.mappingContainer {
  min-height: 100%;
}

.fullScreenModal {
  height: calc(95vh);
  overflow-y: auto;
  :global {
    .MuiTableContainer-root {
      // Padding + card header
      max-height: calc(95vh - 22px) !important;
      max-width: 100% !important;
    }
  }
}
