@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.modalWrapper {
  right: calc($notesDrawerWidth + 10px);
  z-index: 1199;

  @media only screen and (min-width: 1800px) {
    right: calc($bigNotesDrawerWidth + 10px);
  }
}

.modal {
  position: absolute;
  background: $light-gray;
  padding: 0;
  top: $header-height;
  bottom: 0;
  left: unset;
  right: -$notesDrawerWidth;
  outline: 0;
  width: 705px;
  height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;
  box-shadow: -6px 0px 20px 0px rgba($black, 0.10);
  transition: right 300ms linear !important;

  @media only screen and (min-width: 1800px) {
    right: -$bigNotesDrawerWidth;
  }

  &.modalOpened {
    right: 0;
  }
}

.modalBackdrop {
  background: none;
  right: calc($notesDrawerWidth + 10px);

  @media only screen and (min-width: 1800px) {
    right: calc($bigNotesDrawerWidth + 10px);
  }
}

.modalHeader {
  padding: 24px 27px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalBreadcrumbs {
  padding: 0 27px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.modalBreadcrumbItem {
  display: flex;
  padding: 4px 8px;
  background: $gray-background-color;
  color: $secondary-color;
  font-size: 10px;
  line-height: 20px;
  font-weight: 700;
  border-radius: $small-border-radius;
  cursor: pointer;
  text-decoration: none;
}

.modalBreadcrumbItemSeparator {
  path {
    fill: $primary-color
  }
}

.modalTitle {
  font-size: 18px;
  font-weight: 800;
  line-height: 1.25;
}

.modalClose {
  cursor: pointer;
  width: 27px;
  height: 27px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 184px);
  overflow: hidden;
}

.modalBodySummary {
  padding: 0 12px 24px;
  border-bottom: 1px solid $mercury;
}

.modalBodyDetails {
  padding: 24px 27px;

  table {
    thead {
      tr {
        th {
          font-size: 11px;
        }
      }
    }

    tbody {
      min-height: 80px;
      max-height: calc(70vh - 360px);
    }
  }
}

.modalBodyDetailsLongValue {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.modalBodyReplies {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.modalBodyRepliesLabel {
  padding: 0 27px 24px;
  color: $secondary-color;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
}

.modalBodyRepliesEmptyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.modalBodyRepliesEmptyContainerText {
  color: $secondary-color;
  font-size: 14px;
  line-height: 1.5;
}

.modalBodyRepliesChildContainer {
  flex: 1;
  overflow: auto;
}

.modalBodyRepliesReply {
  margin: 0;
  padding: 14px 32px;
  border-top: 1px solid $gallery;
  border-radius: 0;

  :global {
    .DraftEditor-root {
      max-height: 100px !important;
      overflow: auto;
    }
  }
}
