@import '../../assets/styles/colors';

.chatButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1250;
}

.chatPanel {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 750px;
  height: 750px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  z-index: 1250;
  overflow: hidden;
  transition: all 0.3s ease;

  &.fullscreen {
    top: 25px;
    left: 75px;
    right: 75px;
    bottom: 25px;
    width: calc(100% - 150px);
    height: calc(100% - 50px);
    border-radius: 10px;
  }
}

.chatHeader {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 1;

  .fullscreen & {
    padding: 16px 24px;
  }
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chatContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: hidden;
  width: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.chatMessages {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  min-height: 0;

  .fullscreen & {
    padding: 24px;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
  }
}

.chatInput {
  padding: 12px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 1;

  .fullscreen & {
    padding: 16px;
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
  }
}

.inputWithModelSelector {
  position: relative;
  width: 100%;
}

.modelSelectorForm {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 90px;
  z-index: 2;

  .modelSelector {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 10px;
    padding: 0;
    max-width: 125px;
    height: 20px;
    
    .MuiSelect-select {
      padding: 0 4px;
      min-height: unset !important;
      line-height: 20px;
    }

    svg {
      margin-top:4px;
      width: 16px;
      height: 16px;
    }
  }
}

// Add styles to make the dropdown menu appear above input
:global(.MuiPopover-root) {
  z-index: 1300 !important;
}

:global(.MuiMenu-paper) {
  margin-top: -46px !important;
}

.userMessage,
.assistantMessage {
  margin-bottom: 16px;
  padding: 12px 12px 2px 12px;
  border-radius: 8px;
  max-width: 85%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content;
}

.userMessage {
  background-color: $primary-color;
  margin-left: auto;
  .messageContent {
    color: white !important;
  }
}

.assistantMessage {
  background-color: white;
  margin-right: auto;
  .messageContent {
    color: black !important;
  }
}

.messageContent {
  white-space: pre-wrap;
  word-break: break-word;
}

.messageLink {
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.typingIndicator {
  display: flex;
  gap: 4px;

  span {
    width: 8px;
    height: 8px;
    background-color: #bdbdbd;
    border-radius: 50%;
    animation: typing 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

.emptyChat {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 32px;
  text-align: center;
}

.sqlAccordion, 
.resultsAccordion {
  margin-top: 0px;
  background-color: $light-grey !important;
  box-shadow: none !important;

  &::before {
    display: none;
  }
  
  :global(.MuiAccordionSummary-content) {
    margin: 0px !important;
    display: flex;
    align-items: center;
    
  }
}

.sqlSummary,
.resultsSummary {
  padding: 0 8px !important;
  min-height: 24px !important;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  margin-top: 4px;

  .codeIcon,
  .tableIcon {
    margin-right: 8px;
    height: 18px;
    width: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.expandIcon {
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sqlSummaryText,
.resultsSummaryText {
  font-size: 11px;
}

.sqlDetails,
.resultsDetails {
  padding: 8px !important;
}

.sqlCode {
  margin: 0;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-size: 12px;
  overflow-x: auto;
}

// Sidebar styles
.sidebar {
  width: 250px;
  height: 100%;
  transition: all 0.3s ease;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  
  .MuiDrawer-paper {
    position: static;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: none;
  }
  
  &.closed {
    width: 0;
    
    .MuiDrawer-paper {
      width: 0;
      overflow: hidden;
    }
  }
}

.sidebarHeader {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);


  .fullscreen & {
    padding: 16px 24px;
  }

  &.closed {
    visibility: hidden;
    opacity: 0;
  }
}

.sidebarContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sessionList {
  width: 100%;
  padding: 0;
  overflow-y: auto;
  height: calc(100% - 64px);

}


.lastMessage {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
}



.sessionItem {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  .deleteButton {
    display: none;
    position: absolute;
    right: 8px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    .deleteButton {
      display: flex;
    }
    background-color: rgba(0, 0, 0, 0.05);
  }
}

@keyframes typing {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

.resultsTable {
  th {
    font-weight: 600 ;
  }
    tr {
     th, td {
        line-height: 14px ;
        font-size: 12px ;
        padding: 5px ;
        text-wrap: nowrap ;
      }
    }
}

.showAllButton {
  font-size: 10px ;
  text-transform: none ;
  display: flex ;
  float: right ;
  margin-bottom: 4px ;
}

.timestamp {
  font-size: 10px;
  color: #666;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
}

.userTimestamp {
  text-align: right;
  color: white;
}

.messageFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.feedbackContainer {
  display: flex;
  align-items: center;
  gap: 2px;
  opacity: 0.5;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
}

.likeButton:hover{
  color: $success-green;
}

.dislikeButton:hover{
  color: $red;
}

.feedbackButton {
  padding: 2px;
  color: rgba(0, 0, 0, 0.54);

  svg {
    width: 16px;
    height: 16px;
  }

  &.liked {
    color: $success-green;
  }

  &.disliked {
    color: $red;
  }
}

.clearFeedbackButton {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  padding: 1px;
  min-width: 20px;
  min-height: 20px;
}

.feedbackLabel {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 2px;
  font-size: 11px;
}

.thinking {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  color: transparent;
  -webkit-text-fill-color: transparent; /* Ensures text is transparent */
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.3) 0%,   /* Very subtle start */
    rgba(0, 0, 0, 0.4) 45%,  /* Soft fade-in */
    rgba(0, 0, 0, 0.8) 50%,  /* Gentle highlight */
    rgba(0, 0, 0, 0.4) 55%,  /* Soft fade-out */
    rgba(0, 0, 0, 0.3) 100%  /* Very subtle end */
  );
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: shine 3s linear infinite; /* Consistently smooth movement */
}

@keyframes shine {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: -100% 0;
  }
}