@import '../../assets/styles/colors';

.wrapper {
  margin: 0 auto;
  max-width: 524px;
}

.heading {
  font-weight: 800;
font-size: 20px !important;
  color: $secondary-color;
  margin: 0px 0px 18px;
  text-align: left;
  height: 25px;

}

.companyRoleLabel{
  font-weight: 600;
font-size: 14px;
color: $slate-gray;

}

.role {
  color: $secondary-color;
  font-weight: 600;
font-size: 18px
}

.button {
  margin-top: 5px;
  height: 48px;
  width: 100%;
}


.inputLabel {
  color: $secondary-color;
  font-size: 14px;
  font-weight: 600;
}

.avatar {
  height: 72px;
  width: 72px;
  font-size: 32px;
}

.iconButton {
  cursor: pointer;
}

.imageUploadWrapper {
  min-width: 360px;
}
