@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.effectiveDateContainer {
  h4 {
    font-size: 14px;
  }

  & > h4, p {
    margin-bottom: 0;
    text-align: left;
  }
}

.effectiveDate {
  width: 100%;
}

.restartTermContainer {
  margin: 0;
}

.restartTerm {
  padding: 0;
}

.card {
  background-color: $light-gray;
}

.value {
  flex: 1;
  text-align: right;

  :global {
    .MuiInput-root {
      width: 100% !important;
    }

    [class*="KeyboardDatePicker_root"] {
      width: 100% !important;
    }
  }
}

.label {
  flex: 1;
}
