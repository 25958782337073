@import "../../../assets/styles/mixins";
@import "../ClientAccountActivity/ClientAccountActivity.module";

.label{
    margin-top: 8px;
}

.wireFeeCheckboxWrapper {
    text-align: left;
}

.modalRoot {
    max-height: 90vh;
    overflow: hidden;
}

.modalBody {
    max-height: calc(90vh - 114px);
    overflow-x: hidden;
    overflow-y: auto;

    @include thin-scrollbar;
}
