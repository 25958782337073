@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.modal {
  position: absolute;
  min-width: 376px;
  background: $light-gray;
  border-radius: $main-border-radius;
  padding: 32px;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
  max-height: 98vh;
  overflow-y: overlay;
}

.modalClose {
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
  font-size: 24px;
  z-index: 1;
}

.modalTitle {
  color: $secondary-color;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  text-align: left;
  max-width: calc(100% - 40px);
}

.modalHeaderTitle {
  display: flex;
  align-items: center;
  margin-top: -2px;
  margin-bottom: 10px;
}

.editTableTitle {
  padding-left: 32px;
 
}

.modalBody {
  font-size: $main-font-size;
  line-height: 20px;
  text-align: center;
  max-height: 90vh;
  max-width: 90vw;
}

.modalFooter {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.smallModal {
  width: 448px;
}

.fullscreenModal {
  @extend .modal;
  width: 90%;
  padding: 10px;

  .modalFooter {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
}
