@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.ddModal {
  padding: 0;
  width: 95vw;
  height: 95vh;
}

.ddModalClose {
  top: 20px;
  right: 64px;
}

.ddModalBody {
  height: 95vh;
  max-height: 95vh;
  max-width: 95vw;
  overflow: hidden;
}

.ddModalContainer {
  display: flex;
  height: 100%;
}

.ddModalStepperWrapper {
  position: relative;
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $catskill-white;
  gap: 40px;
}

.ddModalGoBackIcon {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 26px;
  left: 38px;
  cursor: pointer;
}

.ddModalStepperProgressContainer {
  position: relative;
  display: inline-flex;
  width: 96px;
  height: 96px;
}

.ddModalStepperProgressText {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 700;
  color: $secondary-color;
}

.ddModalStepperProgress {
  position: absolute;
}

.ddModalStepperProgressCircleBackground {
  stroke: rgba($mountain-meadow, 0.24);
}

.ddModalStepperProgressCircle {
  stroke: $success-green;
  stroke-linecap: round;
}

.ddModalStepper {
  .ddModalStepperStepConnectorRoot {
    margin-left: 24px;
  }

  .ddModalStepperStepConnectorLine {
    border-color: $raven;
    border-left-width: 1px;
    border-left-style: dashed;
    min-height: 17px;
  }

  .ddModalStepperStepItemRoot {
    padding: 8px 24px 8px 8px;
    width: 240px;
    border-radius: 10px;
    cursor: pointer;
  }

  .ddModalStepperStepItemRootActive {
    background: $primary-color;
  }

  .ddModalStepperStepItemIconContainer {
    padding-right: 16px;
  }

  .ddModalStepperStepItemIconContainerCurrent {
    .ddModalStepperStepItemIconText {
      background: $white;
      color: $primary-color;
      border: 2px solid $white;
    }

    .ddModalStepperStepItemIconActive {
      path {
        fill: $white;
      }
    }
  }

  .ddModalStepperStepItemLabel {
    font-size: 14px;
    line-height: 1.5;
    color: $slate-gray;
    font-weight: 500;
  }

  .ddModalStepperStepItemLabelCompleted {
    color: $slate-gray;
  }

  .ddModalStepperStepItemLabelCurrent {
    color: $white;
  }

  .ddModalStepperStepItemIcon {
    width: 32px;
    height: 32px;
  }

  .ddModalStepperStepItemIconCompleted {
    border: 2px solid $primary-color;
    border-radius: 50%;
  }

  .ddModalStepperStepItemIconActive {
    path {
      fill: $primary-color;
    }
  }

  .ddModalStepperStepItemIconText {
    padding: 6px 8px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
    color: $slate-gray;
    border: 2px solid $border-color;
  }
}

.ddModalContentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 310px);
}

.ddModalHeaderWrapper {
  padding: 20px 140px 20px 60px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $catskill-white;
}

.ddModalHeader {
  color: $secondary-color;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 20px;
}

.ddModalSaveState {
  margin-top: 0;
}

.ddModalContentContainer {
  padding: 20px 70px 32px 60px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.ddModalContent {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.ddModalContentButtonWrapper {
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ddModalContentButton {
  height: 56px;
  min-width: 110px;
}

.ddModalContentButtonNext {
  min-width: 150px;
}
