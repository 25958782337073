@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tbody {
    max-height: 50vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 60vh !important;
    }
  }

  tr {
    height: 30px;
    td,
    th {
      &:nth-child(1) {
        width: 300px;
      }

      &:nth-child(2) {
        width: 75px;
      }

      &:last-child {
        width: 200px;
      }
    }
    &.newRow {
      background-color: $mabel !important;
    }

    .entityPreviewIcon {
      display: none;
    }

    &:hover {
      .entityPreviewIcon {
        display: inline;
      }
    }
  }

  &.tableEligibility {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 35%;
        }

        &:nth-child(2) {
          width: 10%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 15%;
        }
      }
    }
  }
}

.ineligibleCategoryList {
  width: 97%;

  :global {
    .MuiSelect-select {
      padding: 2px 7px;
    }
    .MuiSelect-icon {
      top: 4px;
      padding: 2px 5px;
    }
  }
}


.priceAdornment {
  color: $secondary-color;
}

.entityLink {
  max-width: calc(100% - 17px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;

  &.entityLinkShort {
    max-width: calc(100% - 95px);
  }
}
