@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables';

.pageContainer {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.itemContainer {
  justify-content: flex-start;
  padding-bottom: 20px;
}

.tabItemContainer {
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-left: 30px;
}

.tabsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userBodyContainer {
  width: 100%;
  padding-left: 30px;
}

.generalBodyContainer {
  width: 50%;
  padding-left: 30px;
}
