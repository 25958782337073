@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.card {
  background-color: $light-gray;
}

.button {
  margin-left: 10px;
}

.buttonIcon {
  margin-right: 10px;
}

.paidOff {
  color: $green-text;
  background-color: $green-background;
  padding: 5px;
  border-radius: $small-border-radius;
}
