.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    &:nth-child(1) {
      flex: 1;
    }
  }
}

.warning {
  display: flex;
  justify-content: flex-end;
}
