@import '../../assets/styles/colors.scss';

.card {
  height: 100%;
}

.iconRoot {
  width: 100%;
  height: 100%;
}

.table {
  tr {
    td,
    th {
      &:nth-child(2) {
        width: 80px;
      }
    }
  }
  td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  tbody {
    padding: 0 !important;
    width: 100% !important;
  }
  thead {
    display: table;
    width: 100% !important;
  }
}

.link {
  color: $primary-color !important;
  cursor: pointer;
  font-weight: 600;
}

.parentTitle {
  font-weight: 600;
  font-size: 18px;
}

.parentName {
  font-weight: 600;
  font-size: 16px;
}
.autocompleteField {
  :global {
    .MuiAutocomplete-inputRoot {
      min-height: 48px;
      height: 48px;
      border: 1px solid $input-border-color;
      border-radius: 5px;
      padding: 8px 36px 8px 8px !important;

      .MuiInputBase-input {
        font-size: 14px;
      }

      .MuiAutocomplete-endAdornment {
        right: 5px;
      }

      &:before {
        display: none;
      }
    }
  }
}

.fieldName{
  text-align: left;
}


.addParent{
  color: $primary-color !important;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.editIcon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  svg {
    fill: $slate-gray;
    width: 32px;
    height: 32px;
  }
}
