@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.headerWrapper {
  &:hover {
    .icons {
      opacity: 1;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 700;
  color: $secondary-color;
  cursor: pointer;

  > svg {
    margin: 6px;
    width: 12px;
    height: 12px;

    > path {
      fill: $primary-color;
    }
  }
}

.icons {
  opacity: 0;

  &.iconsActive {
    opacity: 1;
  }
}

.fieldWrapper {
  position: relative;

  &:hover {
    .editor {
      border-color: $input-border-color;
    }

    .fieldIcons {
      opacity: 1;
    }
  }
}

.fieldHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $button-border-color;
  padding-bottom: 7px;
  margin-bottom: 5px;
}

.fieldTitle {
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: $secondary-color;
  text-align: left;
}

.fieldIcons {
  display: flex;
  opacity: 0;
  gap: 7px;

  &.fieldIconsActive {
    opacity: 1;
  }
}

.fieldIcon {
  display: flex;
  padding: 2px;
  cursor: pointer;
}

.helperText {
  color: $secondary-color;
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
  line-height: 150%;
  margin-bottom: 5px;
}

.editorWrapper {
  .toolbarContainer {
    position: absolute;
    top: 0;
    right: 62px;
  }

  .toolbarStaticStyles {
    margin: 0;

    .toolbarButtonContainer {
      gap: 7px;
      padding: 3px 7px;
    }

    .toolbarButton {
      width: 15px;
      height: 15px;
      padding: 0;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.editor {
  min-height: 72px;
  padding: 5px 10px;
  border-radius: $small-border-radius;
  border: 1px solid transparent;

  &.editMode {
    border-color: $input-border-color;
  }

  &.readOnly {
    margin-bottom: 10px;
  }
}

.fullScreenModal {
  padding: 0 20px;
}

.repliesContainer {
  margin-bottom: 15px;
}

.repliesShowMoreButton {
  cursor: pointer;
  color: $primary-color;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}
