@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.root {
  background-color: transparent;
}

.selectFieldWrapper {
  width: 100%;
}

.select {
  font-size: $main-font-size;
  padding: 4px 7px;
  background-color: $input-background-color;
  color: $secondary-color;
  border-radius: $small-border-radius;
  border: none;
  text-align: left;
  height: 48px;

  &:focus,
  &[aria-expanded='true'] {
    background-color: $input-hovered-background-color;
    border-radius: $small-border-radius;
  }

  :global {
    .MuiFormControl-root .MuiSelect-select {
      .MuiInputBase-root.Mui-disabled {
        & > div {
          color: $disabled-input;
        }
      }
    }
  }
}

.medium {
  line-height: 24px;
}

.small {
  height: 36px;
}

.big {
  height: 48px;
  border-radius: $small-border-radius;
  border: 1px solid $input-border-color;
  font-size: 14px;
  font-weight: 400;
  background-color: white;

  :global {
    .MuiInput-root {
      height: 48px;

      border-radius: $small-border-radius;
    }

    .MuiInputBase-input {
      // padding: 8px 8px;
      font-size: 14px;
    }

    .MuiSelect-icon {
      right: 8px;
    }
  }
}

.menuPaper {
  padding: 7px 5px;
  box-shadow: 0 0 2px 0 rgba($white, 0.06);
  border-radius: $small-border-radius;
  max-height: 375px;

  ul.menuList {
    color: $secondary-color;

    li.listItemRoot {
      // min-width: 140px;
      padding: 5px;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.2px;

      + .listItemRoot {
        margin-top: 10px;
      }

      &:hover {
        background: $gray-background-color;
      }
    }

    li.selected,
    li.selected:hover {
      background: $dropdown-selected-color !important;
    }
  }
}

.rightAlignSelect {
  text-align: right;
  padding-right: 25px !important;
}

.listItemRightAlign {
  display: flex;
  justify-content: flex-end;
}

.selected {
  background-color: $dropdown-selected-color !important;
  color: $secondary-color !important;
}

.placeholder {
  color: $p-color;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.iconLabel {
  margin-left: 10px;
}

.border {
  border: 1px solid $input-border-color;
  border-radius: $small-border-radius;
}

.withTopLabel {
  @include select-field-with-top-label;
}

.clearIcon {
  padding: 0;
  cursor: pointer;

  width: 20px;
  height: 20px;
  fill: $transparent-gray;

  &:hover {
    background: none;

    fill: $icon-color;
  }
}

.withClearIcon {
  display: none;
}

.withClearIconSelect {
  padding-right: 2px !important;
}

.withClearIconSelectCompnent {
  padding-right: 5px !important;
}

.menuListShadow {
  ul.menuList {
    box-shadow: 0px 2px 8px rgba(35, 47, 53, 0.09);
  }
}