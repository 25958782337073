@import "../../../assets/styles/colors";

.adornment {
  fill: $p-color !important;
  width: 18px;
  height: 18px;
}

.input {
  input {
    text-align: right;
  }
}
