@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.autoReportingChip {
  padding: 7px 8px;
  height: 32px;
  border-radius: $small-border-radius;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid $green-text;
  background: $green-text;
  color: $white;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: default;

  &.autoReportingFailedChip {
    border-color: $red-text;
    background: $red-text;
  }
}

.autoReportingChipIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
}
