@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins";

.flagContainer {
  overflow: visible !important;
  padding: 10px 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flagsList {
  flex-grow: 1;
  padding-bottom: 5px;
  overflow-x: visible !important;
  overflow-y: auto !important;

  @include thin-scrollbar;
}

.infiniteScroll {
  overflow: visible !important;
  padding: 10px 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flagContainer {
  overflow: visible !important;
  padding: 0;
}

.flagCardContainer {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 124px);
}

.fullscreenFlagContainerWrapper {
  display: flex;

  &.fullscreenFlagContainerWrapperExpanded {
    .fullscreenFlagContainer {
      flex: 5;
      overflow-y: auto;
    }

    .expandedContainer {
      flex: 7;
      height: auto;
      overflow-y: auto;
    }
  }
}

.fullscreenFlagContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flagDescription {
  padding-left: 104px;
  padding-right: 28px;
  font-size: 14px;
  border-bottom: 1px solid $border-color;
  padding-bottom: 10px;
  line-height: 1.5;
  cursor: default;
}

.table {
  padding: 8px 28px;
  flex: 1;
}

.tableWrapper {
  flex: 1;
}

.expandedContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.flagTitle {
  height: 100px;
}

.expandedContainerContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.emptyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.emptyContainerText {
  color: $secondary-color;
  font-size: 14px;
  line-height: 1.5;
}

.expandedCardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.notesRepliesContainerList {
  flex: 1;
}

.noteExpandedContainer {
  height: 100%;

  .editor {
    &.isExpanded {
      margin-bottom: 65px;
    }
  }

  .isEditing {
    .toolbarStyles {
      visibility: visible !important;
      transform: none !important;
      top: unset !important;
      left: 14px !important;
      right: 14px !important;
      bottom: 10px !important;
      border-radius: $main-border-radius;
      background: $light-gray;
      box-shadow: none;

      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.detailsBody {
  height: 208px;
}



.select {
  width: 160px;
  height: 34px;
  display: none;

  :global {
    .MuiInputBase-input {
      max-width: 114px;
    }
  }
}


.searchField {
  display: none;
  height: 34px;

  :global {
    .MuiInputBase-root {
      padding-left: 6px;
      height: 34px;
      input {
        line-height: 18px;
        height: 18px;
      }
    }
  }
}

.fieldOpen {
  display: block;
  :global {
    .MuiInputBase-root {
      background-color: $white;
    }
  }
}

.button {
  cursor: pointer;
  border-radius: $small-border-radius;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  padding: 7.5px;

  &:hover {
    background-color: $button-border-color;
  }
}

.fullPageSortButton {
  width: 46px;
  height: 46px;
  padding: 13px;
  margin-left: 15px;
}

.buttonActive {
  background-color: $debtor-gray;
}

.searchButton {
  path {
    fill: $secondary-color;
  }
}

.startAdornment {
  margin-right: 3px;
}

.hiddenButton {
  display: none;
}

.filterCount {
  margin: 6px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: $white;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  background-color: $primary-color;
  text-align: center;
}


.actionsContainer {
  padding: 10px 22px;
  border-bottom: 1px solid $button-border-color;
  border-top: 1px solid $button-border-color;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.emptyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.emptyContainerText {
  color: $secondary-color;
  font-size: 14px;
  line-height: 1.5;
}





.actionsBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  &.commentActionsBar {
    margin: 10px 0 5px;
  }
}



.filterButton {
  background-color: white;
  color: #1a202c;
  height: 44px;
  width: 108px;
  &:hover {
    background-color: white;
  }
}

.selectedSort {
  background-color: $dropdown-selected-color;
}

.actionsMenu{
  margin-top: 2px;
}

.resolveInDetail {
  position: absolute;
  right: 0;
  top: 0;
}

.flagFullPage {
  &.flagContainer {
    gap: 0;
  }

  .flagCardContainer {
    max-height: calc(100vh - 179px);

    > div {
      &:first-child {
        max-height: 378px;
      }

      &:last-child {
        flex: 1;
        overflow-y: auto;
      }
    }
  }
}

.expandedContainer {
  background: white;

  .commentsContainer {
    max-height: unset !important;
    overflow: unset;
  }

  .stickyAddComment {
    position: sticky;
    width: 100%;
    bottom: 0;
    background: white;
    z-index: 1;
  }
}

.expandModalBody {
  overflow: auto;
}

.filterMenuPopoverPaper {
  width: 250px;
  padding: 20px;
}

.actionButtonContainerFilterContainer {
  flex: 1;

  > div {
    padding: 0;

    > div {
      padding: 0;
    }
  }

  [class*='FilterContainer_clearAllButton'] {
    margin-left: 0;
  }

  [data-search="open"] [class*="FilterContainer_clearAllButton_"] {
    flex: 0 0 100%;
    order: 1;
  }

  [class*="FilterButton_filterButton_"] {
    height: 35px;
    min-width: 35px;
    padding: 7.5px;
  }

  [class*="FilterContainer_searchField_"] {
    :global {
      .MuiInput-root {
        height: 35px;
      }
    }
  }

  [class*="FilterContainer_searchButton"] {
    height: 35px;
    width: 35px;
    padding: 7.5px;
  }
}

.sortButton {
  margin-left: 4px;
}

.sortMenuPopoverPaper {
  width: 175px;
  padding: 5px;
}

.sortMenuItem {
  padding: 10px 16px;
  font-size: 12px;
  line-height: 15px;
  color: $secondary-color;

  &:hover {
    background-color: $gallery;
  }

  &.sortMenuItemSelected {
    background-color: $button-border-color;

    &:hover {
      background-color: $gallery;
    }
  }
}
