@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tr {
    td, th {
      &:nth-child(1) {
        width: 18%
      }

      &:nth-child(2) {
        width: 18%
      }

      &:nth-child(3) {
        width: 18%
      }

      &:nth-child(4) {
        width: 18%
      }

      &:nth-child(5) {
        width: 10%
      }
    }
  }
}

.percentList {
  width: 48% !important;
  
}

.percentFields {
  > div {
    width: 48% !important;

    &:first-of-type {
      margin-right: 5px;
    }
  }
}

.iconMenu {
  padding: 5px 10px;
  box-shadow: 0 0 2px 0 rgba($black, 0.2);
  border-radius: $main-border-radius;
}

.iconMenuItem {
  min-width: 80px;
  padding: 10px;
  font-size: 14px;
  line-height: 21px;
}


.adjustmentTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: $black;
  // left align
  text-align: left;

}

.adjustmentRadio {
  // left align
  text-align: left;
  color: $black !important;
  
  font-size: 14px !important;
    font-weight: 600 !important;
    margin-left: 8px;
 
}



.saveButton {
  width: 100%;
}

