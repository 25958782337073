@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.table {
  tr {
    td, th {
      &:nth-child(1) {
        width: 3%;
      }

      &:nth-child(2) {
        width: 16%;
      }
    }

    .entityPreviewIcon {
      display: none;
    }

    &:hover {
      .entityPreviewIcon {
        display: inline;
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 19%;
        }

        &:nth-child(2) {
          width: auto;
        }
      }
    }
  }

  thead {
    tr {
      th {
        padding: 2px 5px 3px;
        line-height: 28px;

        &.highlightedCurrent {
          background: $nepal;
          color: $white;
        }

        &.highlightedDate {
          background: $slate-gray;
          color: $white;
        }
      }
    }
  }
}

.recordDateTitle {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: $secondary-color;
}

.recordDateList {
  margin-left: 10px;
  width: 160px;
  text-align: left;
}

.exportButton {
  height: 42px;
  padding: 8px 24px;
}

.exportButtonIcon {
  margin-right: 10px;
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer
      max-height: calc(95vh - 368px) !important;
    }
  }
}


.selectField {
  width: 105px;
  height: 36px;
}

.selectFieldWithTopLabel {
  width: 135px;
  height: 36px;

  @include select-field-with-top-label;
}

.chartContainer {
  height: calc(100vh - 500px);
  min-height: 400px;
}

.fullScreen {
  height: calc(100vh - 230px);
}

.entityLink {
  max-width: calc(100% - 17px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;

  &.entityLinkWithPriority {
    max-width: calc(100% - 89px);
  }
}
