@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

@mixin mui-form-control-root{
    align-items: center;
    justify-content: flex-end;
    height: 24px;
  }




.big {
  tr {
    height: 36px;
  }
}



.container {
  padding: 0;
  overflow-y: hidden; // hide scroll related to margin -8

  table {
    thead {
      border-collapse: separate;
      border-spacing: 0 8px;
      margin: -8px 0;
    }
  }

  table tbody {
    display: block;
    max-height: 350px;
    overflow-y: scroll;
  }

  table thead,
  table tfoot,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    width: 100% !important;

    tr {
      th {
        font-weight: 700;
        font-size: 11px;
        color: $secondary-color;
        position: relative;


        &:first-child {
          &:before {
            display: none;
          }
        }
      }

      &:last-child {
        th {
          border-bottom: 2px solid $gallery;
        }
      }
    }
  }

  tbody {
    @include thin-scrollbar;

    tr {
      width: 100% !important;
      td {
        border-bottom: none;

        :global {
          .MuiFormControl-root {
            @include mui-form-control-root;


            .Mui-focused:not(.MuiFormHelperText-root) {
              background-color: $input-hovered-background-color !important;
              outline: 1.5px solid $cerulean !important;
              border-radius: $small-border-radius !important;
              transform: scale(.98, 1.00);
              transition: transform 0.2s linear;
            }

            .MuiFormHelperText-root {
              &.Mui-error {
                // Hide error for now and try to find place for error in table
                position: absolute;
                top: -1px;
                left: 5px;
                font-size: 10px;
                max-width: 65%;
                overflow: hidden;
              }
            }

            >div {
              width: 100% !important;
              height: 24px;

              &.MuiAutocomplete-inputRoot {
                padding-top: 0 !important;
                padding-bottom: 0 !important;


                &:before {
                  top: 5px;
                }

                .MuiAutocomplete-input {
                  padding: 2px 5px !important;
                }
              }

              @media only screen and (max-width: 1279px) {
                width: 110px;
              }
            }

            .MuiSelect-select {
              padding: 2px 7px;
            }

            .MuiSelect-icon {
              width: 18px;
              height: 18px;
              top: 3px;
              padding: 2px 5px;
              line-height: 14px;
              display: inline-block;
            }
          }
        }
      }

      &:nth-child(odd) {
        background-color: $white;
      }

      &:nth-child(even) {
        background-color: $row-background-color;
      }


            &:hover {
              background-color: $hover-row-color;
            }

    }
  }

  tfoot {
    width: 100% !important;

    tr {
      td {
        background: $slate-gray;
        color: $white;
        font-weight: 700;
        border: none;
        border-bottom: 1px solid $white;

        > span {
          color: $white;
        }
      }

      &:last-of-type {
        td {
          border-bottom: none;
        }
      }
    }
  }

  tr {
    height: 30px;
    font-size: $main-font-size;
    line-height: 20px;

    td,
    th {
      text-align: center;
      color: $tundora;
      font-size: $main-font-size;
    }
  }


  thead {
    tr {
      height: 36px;
      background: $row-background-color !important;

      th {
        height: 36px;
      }


      &:hover {
        background: $row-background-color !important;
      }
    }
  }

  .small {
    thead{
    tr {
      height: 24px;
      line-height: 24px;
      th {
        height: 24px;
        line-height: 24px;

      }
    }
  }


}


  :global {
    table {
      thead {
        th {
          &.activableColumn {
            cursor: pointer;
          }

          &.summaryColumn {
            position: sticky !important;
            background-color: inherit !important;
            right: 0;
            z-index: 1;
          }

          &.activeCell {
            background-color: $debtor-gray !important;
          }
        }
      }

      tbody {
        tr {
          &.activableRow {
            cursor: pointer;
          }

          &.activeRow {
            background-color: $active-row-color !important;
            > td {
              background-color: $active-row-color !important;

            }
          }

          td {
            &.activeCell {
              background-color: $debtor-gray !important;
              color: $tundora !important;

              > div {
                color: $tundora !important;
              }
            }

            &.summaryColumn {
              position: sticky !important;
              right: 0;
              z-index: 1;
              background: $white !important;
              border-left: 1px solid $button-border-color;
              box-shadow: -7px 20px 20px 0 rgba(0, 0, 0, 0.2);
            }
          }

          &.summaryRow {
            height: 40px;
            position: sticky !important;
            bottom: 0;
            z-index: 1;
            background: $white !important;
            border-top: 1px solid $button-border-color;
            font-weight: 700;
          }
        }
      }

      tfoot {
        tr {
          &.summaryRow {
            height: 40px;
            position: sticky !important;
            bottom: 0;
            z-index: 1;
            border-top: 1px solid $button-border-color;
            font-weight: 700;

            td {
              background: $white !important;
              color: $tundora !important;
            }
          }
        }
      }
    }
  }
}


.big {
  tr {
    height: 36px;
  }
}


