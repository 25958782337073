@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.editorContainer {
  padding: 10px;
  background: $light-blue-gray;
  border-radius: 20px;
}

.editorWrapper {
  display: flex;
  flex-flow: column;
  height: 100%;

  .editorToolbar {
    border: none;
    padding: 0;
    background: transparent;
  }

  .editor {
    padding: 0 15px;
    background: $white;
    border-radius: 20px;
    min-height: 400px;
  }

  :global {
    .rdw-editor-toolbar {
      .rdw-dropdown-carettoopen {
        top: 50%;
        transform: translateY(-50%);
      }

      .rdw-fontfamily-wrapper,
      .rdw-fontsize-wrapper {
        > .rdw-dropdown-wrapper {
          margin: 0;
          border-radius: 10px;
          height: 32px;

          &:hover {
            background: $alabaster;
            box-shadow: none;
          }

          > .rdw-dropdown-optionwrapper {
            margin: 4px 0;
            padding: 10px 0;
            border-radius: 4px;

            > li {
              margin: 2px 5px;
              padding: 0 5px;

              &.rdw-dropdownoption-highlighted,
              &.rdw-dropdownoption-active {
                border-radius: 7px;
                background: $derby;
                background-color: $derby;
              }
            }

            &:hover {
              box-shadow: none;
            }
          }
        }
      }

      .rdw-inline-wrapper,
      .rdw-colorpicker-wrapper,
      .rdw-list-wrapper {
        margin-bottom: 0 !important;

        > .rdw-option-wrapper {
          height: 23px;
          border-radius: 10px !important;
          border: 0;
          margin: 4px 10px 4px 3px;

          &:hover {
            background: $alabaster !important;
            box-shadow: none !important;
          }

          &:first-child {
            margin-left: 4px;
          }

          &:last-child {
            margin-right: 32px !important;
          }

          &:not(:last-child) {
            position: relative;
          }
          &.rdw-option-active {
            border-radius: 7px;
            background: $alabaster;
            background-color: $alabaster;
          }
        }
      }

      .rdw-link-wrapper,
      .rdw-fontsize-wrapper,
      .rdw-image-wrapper,
      .rdw-text-align-wrapper,
      .rdw-emoji-wrapper {
        margin: 0 0 0 0 !important;

        > .rdw-option-wrapper {
          height: 23px;
          border-radius: 10px !important;
          border: 0;
          margin: 4px 10px 4px 3px;

          &:hover {
            background: $alabaster !important;
            box-shadow: none !important;
          }
          &:not(:last-child) {
            position: relative;
          }
          &.rdw-option-active {
            border-radius: 7px;
            background: $alabaster;
            background-color: $alabaster;
          }
        }
      }

      .rdw-link-wrapper {
        border-right: 0;
        > .rdw-option-wrapper {
          position: relative;
          padding-right: 4px;
        }
      }

      .rdw-image-wrapper {
        border-left: 0;
        > .rdw-option-wrapper {
          padding-right: 4px;
          border: 0 !important;
          margin-right: 10px;
        }
      }

      .rdw-fontsize-wrapper {
        border-left: 0;
        margin: 0 0 0 0 !important;

        > .rdw-fontsize-dropdown {
          padding-right: 4px;
          border: 0 !important;
        }
      }

      .rdw-emoji-wrapper {
        border-radius: 0 10px 10px 0 !important;
        border-left: 0;
        > .rdw-option-wrapper {
          padding-right: 4px;
          border: none !important;
        }
      }

      .rdw-fontfamily-wrapper,
      .rdw-fontsize-wrapper,
      .rdw-colorpicker-wrapper,
      .rdw-image-wrapper,
      .rdw-remove-wrapper,
      .rdw-text-align-wrapper {
        margin-right: 10px;
      }
    }

    .rdw-list-wrapper {
      > .rdw-option-wrapper {
        padding-right: 4px;

        margin: 0 0 0 0 !important;
      }
    }

    .rdw-editor-main {
      font-size: 12px;
    }

    .public-DraftStyleDefault-block {
      margin: 0;
    }

    .public-DraftStyleDefault-orderedListItem.rdw-center-aligned-block,
    .public-DraftStyleDefault-orderedListItem.rdw-right-aligned-block {
      &:before {
        box-sizing: inherit;
        position: relative !important;
        left: 0 !important;
      }
    }

    .public-DraftStyleDefault-unorderedListItem.rdw-center-aligned-block,
    .public-DraftStyleDefault-unorderedListItem.rdw-right-aligned-block {
      list-style-position: inside;
    }
  }
}
