@import "../../../assets/styles/mixins";

.selectField {
  width: 150px;
  height: 36px;

  @include select-field-with-top-label;
}

.chartContainer {
  position: relative;
  height: calc(100vh - 500px);
  min-height: 400px;;
  max-height: 750px;
  width: 100vw;
}

.card {
  margin-bottom: 100px;
}
