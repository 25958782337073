@import '../../assets/styles/colors';

.table {
  tbody {
    max-height: 80vh !important;
  }
  tr {
    height: 38px;

    td,
    th {
      &:nth-child(1) {
        width: 35%;
      }

      &:nth-child(2) {
        width: 17%;
      }

      &:nth-child(3) {
        width: 17%;
      }

      &:nth-child(4) {
        width: 10%;
      }

      &:nth-child(5) {
        width: 11%;
      }

      &:nth-child(6) {
        width: 10%;
      }
    }
  }
}

.sendButtonIcon {
  transform: rotate(-90deg);
}

.sendButton {
  margin-right: 5px;
  font-weight: 600;
  stroke: $primary-color;
}

.sendButtonAll {
  font-weight: 600;
  stroke: $white;
}

.deletedTableSentButton {
  margin-right: 5px;
  font-weight: 600;
  color: $success-green;
  border-color: $success-green;

  &:hover {
    color: $success-green;
    border-color: $success-green;
  }
}

.modal {
  min-width: 480px;
}

.dateField {
  width: 100%;

  :global {
    .MuiInput-root {
      width: 100% !important;
    }
  }
}
