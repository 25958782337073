@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.button {
  width: 100%;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
}

.blank{
  height: 36px;
}


.fieldsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  table {
    width: 100%;
    table-layout: fixed;
  }

  td {
    padding-bottom: 2px !important;
  }
  tr:nth-child(2n) {
    padding-bottom: 8px;
    display: table-row;
  }
  label {
    font-weight: 600;
    font-size: 14px;
    color: $secondary-color;
  }
  p {
    overflow: hidden;
  }
}

.autocomplete {
  border: 1px solid $input-border-color !important;
  border-radius: $small-border-radius;
  min-height: 36px;
  padding: 3px 0;
}

.datePicker {
  width: 100%;

  :global {
    .MuiInput-root {
      padding: 1px 5px !important;
      width: 100% !important;
    }

    .KeyboardDatePicker_root {
      width: 100% !important;
    }

    .MuiInput-input {
      color: $gothic;
    }

    .MuiFormHelperText-root {
      margin: 0;
      font-size: 10px;
      text-align: center;
    }

    .MuiInputAdornment-positionEnd {
      margin-left: 2px;
    }
  }
}

.infoIcon {
  vertical-align: middle;
  margin-left: 5px;
}

.gridItem {
  > div {
    flex-direction: column;
    align-items: flex-start;
    box-shadow: none;
    > div {
      width: 100% !important;
      padding-bottom: 18px;
      font-weight: 600 !important;
      color: black;
    }
    > div:nth-child(2) {
      font-size: 18px !important;
      max-width: none !important;
    }
    > div:nth-child(2) {
      flex-direction: column;
      max-width: none !important;
    }
  }
}

.gridTitle {
  padding: 10px 15px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: $header1-font-size;
}

.gridTitleCloseMonth {
  margin-top: 20px;
  padding: 10px 15px;
  margin-bottom: 2px;
  font-weight: 800;
  font-size: $header1-font-size;
}
