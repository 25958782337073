@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';


.table {
  td,th{
    &:nth-child(3){
      width: 110px;
    }
  }
  tbody {
    max-height: calc(100vh - 390px) !important;
  }
}

.footer {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 20px;
}

.keyTitle {
  color: var(--Black, #1a202c);
  font-size: 14px;
  font-weight: 600;
}

.keyItem {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
}

.ratingTag {
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  box-sizing: border-box;
}



.selectField {
  width: 150px;
  height: 36px;
}