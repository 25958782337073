@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.diligenceProgress {
  width: 430px;
  padding: 40px 28px 50px 36px;
  background: $cobalt;
  border-radius: $main-border-radius;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.diligenceProgressTitle {
  font-size: 20px;
  line-height: 1.25;
  color: $white;
  font-weight: 800;
}

.diligenceProgressSteps {
  display: flex;
  flex-direction: column;
}

.diligenceProgressDateContainer {
  padding: 25px 30px 20px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba($white, 0.15);
  border-radius: 8px;
}

.diligenceProgressDateTextContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.diligenceProgressDateLabel {
  font-size: 14px;
  line-height: 1.25;
  color: $white;
}

.diligenceProgressDate {
  font-size: 20px;
  line-height: 1.25;
  color: $white;
  font-weight: 800;
}

.diligenceProgressDateIcon {
  cursor: pointer;
}

.diligenceProgressDateIconDisabled {
  cursor: not-allowed;
}

.diligenceActions {
  width: 476px;
  display: flex;
  flex-wrap: wrap;
  gap: 25px 20px;
}

.downChevron {
  margin-left: 10px;
  margin-top: 3px;
  fill: $primary-color;
}

.button {
  align-items: center;
}

.avatar {
  height: 140px;
  width: 140px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 64px;
  color: $secondary-color;
  background-color: $white !important;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  margin: 0;
}

.avatarContainer {
  height: 130px;
  width: 130px;
  justify-content: center;
  align-items: center;
  display: flex;

}

.avatarImg {
  max-height: 130px;
  max-width: 130px;
}

.headerTitle {
  text-align: left;
  color: $transparent-gray;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.headerValue {
  text-align: left;
  color: $secondary-color;
  font-size: 14px;
  font-weight: 600;
}

.infoDivider {
  width: 100%;
  border-bottom: 1px solid $gallery;
}

.notStarted {
  :global {
    .MuiSelect-select {
      background: $border-color;
      color: $slate-gray !important;
    }
  }
}

.inProgress {
  :global {
    .MuiSelect-select {
      background: $yellow-background;
      color: $yellow-text;
    }
  }
}

.complete {
  :global {
    .MuiSelect-select {
      background: $green-background;
      color: $green-text !important;
    }
  }
}

.progressSelect {
  :global {
    .MuiSelect-select {
      $medium-border-radius: 16px;
      font-size: $header3-font-size;
      font-weight: 500;
      padding-right: 30px;
    }
  }
}

.tabsContainer {
  padding: 0px 16px;
}

.fieldsContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}



.modalRoot {
  width: 465px !important;
}

.textFormField {
  padding-top: 28px;

  :global {
    .MuiInput-root {
      max-height: 150px;
      overflow: scroll;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}

.menuItemLink {
  color: $black;
  text-decoration: none;
}

.editIcon {
  position: absolute;
  bottom: 7px;
  right: 7px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.avatar:hover .editIcon {
  visibility: visible;
  opacity: 1;
  > path {
    fill: $white;
  }
}

.avatar:hover .avatarContainer {
  filter: brightness(50%) grayscale(60%);
  background-color: $white;
}

.avatarText {
  width: 130px;
  height: 130px;
  text-align: center;
  align-content: center;
}

.avatar:hover .avatarText {
  filter: brightness(50%) grayscale(60%);
  background-color: $white;
}

.headerContainer {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  align-items: flex-start;
  margin-top: 16px;
}

.contentContainer {
  flex: 1;
  margin-bottom: 8px;
}

.avatarGridContainer {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}