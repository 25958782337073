@import '../../assets/styles/variables.scss';
@import '../../assets/styles/colors.scss';

.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}


.logoContainer {



}
.loader {
  position: relative;
  top: $header-height;
  left: 0;
  right: 0;
  width: 100%;
}


.progress {
  border-radius: $success-green;
  height: 7px;
  background-color: #CBD5E0;
      background-image: linear-gradient(90deg,
            transparent 19%,
            white 19%,
            white 21%, 
            transparent 21%,
            transparent 39%,
              white 39%,
              white 41%,
              transparent 41%,
              transparent 59%,
              white 59%,
                white 61%,
                transparent 61%,   
            transparent 79%,
            white 79%,
            white 81%,
            transparent 81%,


          );

  :global {
    .MuiLinearProgress-bar {
      border-radius: #CBD5E0;
      background-color: $success-green;
background-image: linear-gradient(90deg,
            transparent 19%,
              white 19%,
              white 21%,
              transparent 21%,
              transparent 39%,
              white 39%,
              white 41%,
              transparent 41%,
              transparent 59%,
              white 59%,
              white 61%,
              transparent 61%,
              transparent 79%,
              white 79%,
              white 81%,
              transparent 81%,

    )




    }
  }
}

.goodPassword {
  // text align right 
  text-align: right;
  color: $success-green;
  font-size: $main-font-size;
  font-weight: lighter;
  // align the div to the right
  justify-content: right;
  align-items: right;
}

.strongPassword {
  // text align right 
  text-align: right;
  font-weight: lighter;
  color: $success-green;
  font-size: $main-font-size;
  // align the div to the right
  justify-content: right;
  align-items: right;
}