@import '../../assets/styles/colors';

.card {
  height: 100%;
}

.iconRoot {
  width: 100%;
  height: 100%;
}

.table {
  tr {
    td,
    th {
      &:nth-child(4) {
        width: 80px;
      }
    }
  }
  td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  tbody {
    padding: 0 !important;
    width: 100% !important;
  }
  thead {
    display: table;
    width: 100% !important;
  }
}

.reportingContainer {
  width: 100%;
}

.tableContainer {
  padding: 15px 0;
}

.editModalFooter {
  margin-top: 24px;
}

.editFields {
  width: 300px;
}

.submitButton {
  width: 100% !important;
}

.fieldsContainer {
  tr:first-child {
    margin-bottom: 10px;
  }
  table {
    width: 100%;
  }
  tr {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    td {
      padding-bottom: 2px !important;
      width: 100% !important;
      > div {
        width: 100%;
      }
    }
    label {
      font-weight: 600;
      font-size: 14px;
      color: $secondary-color;
    }
  }
}
