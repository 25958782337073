@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.aggregationPage {
  + .aggregationPage {
    margin-top: 15px;
  }
}

.aggregationTable {
  tbody {
    max-height: 55vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 72vh !important;
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 40px;
      }

    }
  }
}

.aggregationTableTotals {
  background: $athens-light-gray;
}

.expandIcon {
  margin: 0;
  padding: 0;
  svg {
    fill: black;
  }
}

.expandedRow {
  background: $dropdown-selected-color;
}

.aggregationClient {
  color: $primary-color;
}

.aggregationCountLabel {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  background: $derby;
  border-radius: 50%;
  text-align: center;
}

.exportButton {
  position: absolute;
  top: 12px;
  right: 20px;
  color: $secondary-color;
  font-size: $main-font-size;
  line-height: 20px;
  cursor: pointer;
}

.rowTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: $black;
  // left align
  text-align: left;
}