@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.title {
  font-size: 24px;
  line-height: 1;
  font-weight: 800;
}

.addButton {
  text-transform: capitalize !important;
}

.tableContainer {
  &.tableContainerContacts {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 35%;
        }

        &:nth-child(4) {
          width: 10%;
        }
      }
    }
  }

  &.tableContainerExecutives {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 15%;
        }

        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 30%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 10%;
        }
      }
    }
  }

  &.tableContainerBoardMembers {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 5%;
        }
      }
    }
  }

  &.tableContainerReferences {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 15%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 17%;
        }

        &:nth-child(5) {
          width: 18%;
        }

        &:nth-child(6) {
          width: 10%;
        }
      }
    }
  }

  &.tableContainerInventoryLocations {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 19%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 26%;
        }

        &:nth-child(4) {
          width: 28%;
        }

        &:nth-child(5) {
          width: 7%;
        }
      }
    }
  }

  &.tableContainerBankAccounts {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 18%;
        }

        &:nth-child(4) {
          width: 25%;
        }

        &:nth-child(5) {
          width: 7%;
        }
      }
    }
  }
}

.emptyRow {
  background: transparent !important;

  td {
    width: 100%;
  }
}

.emptyRowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 65px 0;
  gap: 5px
}

.emptyRowTitle {
  color: $secondary-color;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5
}

.emptyRowText {
  color: $slate-gray;
  font-size: 14px;
  line-height: 23px;
}

.addIcon {
  path {
    stroke: $white;
  }
}

.socialSecurityNumberWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formWrapper {
  width: 100%;
  max-height: calc(90vh - 200px);
  overflow-y: auto;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px 12px;
  width: 100%;
  min-width: 410px;
  max-width: 600px;

  > div {
    flex: 1;
    width: 100%;
    overflow: hidden;

    &.fullWidthRow {
      flex: 0 100% !important;
    }
  }

  &.formContentTwoColumn {
    flex-wrap: wrap;
    flex-direction: row;
    > div {
      flex: 0 calc(50% - 6px);
    }
  }

  @media only screen and (max-height: 960px) {
    flex-wrap: wrap;
    flex-direction: row;
    > div {
      flex: 0 calc(50% - 6px);
    }
  }
}

.inputLabel {
  text-align: left;
  color: $secondary-color;
  letter-spacing: 0.2px;
  font-weight: bolder;

  &.incomplete {
    color: $red-text;
  }
}

.iconButtonVisibility {
  color: $p-color;
  padding: 2px;
  margin-right: 5px;
}

.inputField {
  :global {
    .MuiInputBase-input {
      font-weight: normal;
      text-align: left;
    }
  }

  &.incomplete {
    :global {
      .MuiInput-root {
        border-color: $red-text;
      }
    }
  }
}

.selectField {
  :global {
    .MuiSelect-select {
      line-height: 30px;
      border-radius: $small-border-radius;
      font-size: 14px;
      outline: none !important;
      border: 1px solid $input-border-color;
    }

    .MuiSelect-icon {
      top: 12px
    }
  }

  &.incomplete {
    :global {
      .MuiSelect-select {
        border-color: $red-text;
      }
    }
  }
}

.datePickerField {
  width: 100%;

  :global {
    .MuiInput-root {
      width: 100% !important;
      height: 48px;
      padding: 8px;

      .MuiInputBase-input {
        font-size: 14px;
      }
    }
  }

  &.incomplete {
    :global {
      .MuiInput-root {
        border-color: $red-text;
      }
    }
  }
}

.creatableSelectField {
  :global {
    .MuiInput-root {
      height: 46px;
    }
  }
}

.autocompleteField {
  :global {
    .MuiAutocomplete-inputRoot {
      min-height: 48px;
      height: 48px;
      border: 1px solid $input-border-color;
      border-radius: 5px;
      padding: 8px 36px 8px 8px !important;

      .MuiInputBase-input {
        font-size: 14px;
      }

      .MuiAutocomplete-endAdornment {
        right: 5px;
      }

      &:before {
        display: none;
      }
    }
  }

  &.incomplete {
    :global {
      .MuiAutocomplete-inputRoot {
        border-color: $red-text;
      }
    }
  }
}

.profileContent {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px 12px;
  width: 100%;
  max-width: 400px;
  text-align: left;

  > div {
    flex: 0 calc(50% - 6px);
    overflow: hidden;

    &.fullWidthRow {
      flex: 0 100%;
    }
  }
}

.profileLabel {
  color: $slate-gray;
  margin-bottom: 5px;
}

.profileValue {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modalBody {
  max-height: calc(90vh - 128px);
  overflow-y: auto;

  &.addNewModalBody {
    .formWrapper {
      max-height: calc(90vh - 264px);
    }
  }
}

.modalButtonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.bankAccountNumberTooltip {
  background-color: $dark-blue;
}

.bankAccountNumberTooltipArrow {
  color: $dark-blue;
}

.warningIcon {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
