@import "../../assets/styles/colors";
@import "../../assets/styles/variables.scss";


.icons {
  > div, > a, > button {
    margin-left: 10px;
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  mix-blend-mode: normal;
  /* Shadow-depth-1 */
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
.boxIcon{
  width: 25px;
height: 13.33px
}




.card {
  margin-bottom : 10px;
}

.salesforceLinkWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.linkButton {
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: $primary-color;
  background: none;
  border: 1px solid $primary-color;
  box-shadow: none;
  vertical-align: middle;

  &:hover {
    color: $cerulean;
    border-color: $cerulean;
  }
}

.downChevron {
  margin-left: 10px;
  margin-top: 3px;
  fill: $primary-color;
}

.actionsMenuItem {
  min-width: 91px;
}


