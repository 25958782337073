@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.chartContainer {
  height: calc(100vh - 500px);
  min-height: 400px;
  width: 100%;
}

.chartIcon {
  height: 20px;
  width: 20px;
}
