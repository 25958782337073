@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins';

.childrenContainer {
  padding: 0;
  margin: 0;
  overflow: visible;
}

.expandText {
  color: $primary-color;
  cursor: pointer;
}

.expandTextContainer {
  padding: 0 0 5px 0;
  text-align: center;
  font-weight: 700;
}

.flagContainer {
  overflow: visible !important;
  padding: 0;
}

.emptyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.emptyContainerText {
  color: $secondary-color;
  font-size: 14px;
  line-height: 1.5;
}

.flag {
  padding: 0;
  overflow: hidden;

  &:not(&.isExpandModal) {
    &:not(&.child) {
      cursor: pointer;
    }
  }

  &.isEditing {
    overflow: visible;
  }
}

.flagTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  height: 34px;
  background: $gray-background-color;
  border-radius: $main-border-radius $main-border-radius 0 0;
}

.flagBreadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.flagBreadcrumbItem {
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  color: $secondary-color;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
}
.isCurrentWorkflow {
  color: $primary-color;
  background: $blue-background;
}

.flagBreadcrumbSeparator {
  margin: 0 5px;

  path {
    fill: $primary-color;
  }
}

.flagTopRowActions {
  display: flex;
  gap: 4px;
}

.flagTopRowAction {
  padding: 6px;
  cursor: pointer;
  width: 28px;
  height: 28px;

  svg {
    width: 16px;
    height: 16px;
  }

  &.flagTopRowActionDisabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.flagTopRowActionResolved {
    svg  path:first-child {
      stroke: $success-green;
    }
  }

  &.flagTopRowActionPinned {
    svg {
      path {
        stroke: $gold;
      }

      fill: $gold;
    }
  }

  &.flagTopRowActionMenu {
    padding: 4px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.flagContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
}

.flagHeading {
  display: flex;
  gap: 8px;
  align-items: center;
}

.flagBody {
  color: $secondary-color;
  font-size: 12px;
  line-height: 22px;
  padding: 0 14px 14px 14px;

  span > p {
    margin: 0;
    padding: 0;
  }

  :global {
    .public-DraftStyleDefault-ul,
    .public-DraftStyleDefault-ol {
      margin: 0;
    }
  }

  .readOnly {
    &:not(&.isExpanded) {
      :global {
        .DraftEditor-root {
          overflow: hidden;
          max-height: 66px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}

.selected {
  border-color: $primary-color !important;
}

.isExpandModal {
  height: 100%;
  border: none !important;

  .flagBody {
    font-size: 14px;
  }

  &.isEditing {
    .flagWrapperParent {
      .flagBody {
        border-radius: $small-border-radius;
        border: 1px solid $gallery;

        .editor {
          background: $white;
          min-height: 200px;
          max-height: calc(70vh - 355px);
          overflow-y: auto;
        }

        background: $white;

        .commentActionsBar {
          margin: 10px 14px;
        }
      }
    }
  }

  .flagWrapperParent {
    padding: 8px 32px 8px;
    background: $wild-sand;
    min-height: 290px;
    max-height: calc(70vh - 200px);

    .readOnly {
      max-height: calc(70vh - 250px);
      overflow-y: auto;
    }
  }

  .flagTopRow {
    padding: 0 32px 14px;
    background: $wild-sand;
  }

  .child {
    padding: 0 16px 8px;

    .flagWrapper {
      padding-top: 16px;
    }

    &:before {
      width: calc(100% - 32px);
      margin-left: 16px;
    }

    &:first-of-type {
      &:before {
        display: none;
      }
    }
  }

  .flagCardContent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .childrenContainer {
    display: flex;
    flex: 1;
  }

  .childrenContainerContent {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .childrenContainerList {
    flex: 1;
    max-height: calc(100vh - 540px) !important;
    overflow: scroll;
  }

  .commentContainer {
    margin: 0;
    padding: 14px 32px;
    border-top: 1px solid $gallery;
    border-radius: 0;
  }
}

.flagRepliesLabel {
  padding: 10px 32px;
  color: var(--Black, #1a202c);
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
}

.titleContainer {
  display: flex;
  gap: 8px;
  padding: 14px 14px 6px 14px;
  width: 100%;
}

.alertWarningContainer {
  width: 36px;
  height: 36px;
  border-radius: $small-border-radius;
  display: grid;
  place-items: center;
}

.alertIconBakcground {
  background: $red-background;
}

.warningIconBackground {
  background: $yellow-background;
}

.flagTitle {
  font-weight: 600;
  size: 14px;
  display: grid;
  place-items: center;
}


