@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
  height: 28px;
  min-height: 28px;
  color: $secondary-color;
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  width: fit-content;
  border-bottom: 1px solid $button-border-color;

  > div {
    flex: none;
  }

  .flexContainer {
    gap: 24px;
  }

  .indicator {
    border-radius: 10px 10px 0 0;
  }
}

.rootIcon {
  border-radius: $small-border-radius;
  border: 1px solid $border-color;
  padding: 10px 2px 10px 2px;
  height: 38px;
  min-height: 38px;
  align-items: center;
  width: fit-content;

  > div {
    flex: none;
  }

  .indicator {
    display: none;
  }
}

.tabRoot {
  padding: 0 0 7px;
  height: 28px;
  min-height: 28px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  text-transform: capitalize;
  background: none !important;
  color: $secondary-color;
  min-width: unset;

  > span {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 5px;

    svg {
      margin-right: 10px;

      path {
        fill: $gothic;
      }
    }
  }
}

.tabRootIcon {
  height: 30px;
  min-height: 30px;
  padding: 0 !important;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 24px;
  width: 30px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  text-transform: none;
  border-radius: 5px;
  margin-left: 3px !important;
  margin-right: 3px !important;

  color: $secondary-color;
  > span {
    display: inline-flex;
    align-items: center;
    flex-direction: row;

    svg {
      margin-right: 10px;
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.actionRequired {
  margin-left: 8px;
  content: '\A';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $coral-red;
  display: inline-block;
}

.downArrow {
  width: 20px;
  height: 20px;
  margin-left: 3px;
  margin-right: 0 !important;
  path {
    fill: $secondary-color !important;
  }
}

.menuPaper {
  background-color: $white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba($black, 0.08);
}

.menuRoot {
  margin: 0;
  padding: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
  color: $secondary-color;

  + .menuRoot {
    margin-top: 10px;
  }

  > span {
    display: inline-flex;
    align-items: center;
    flex-direction: row;

    svg {
      margin-right: 10px;

      path {
        fill: $secondary-color;
      }
    }
  }
}

.tabSelected {
  color: $primary-color !important;
  border-color: $primary-color !important;

  > span {
    svg {
      path {
        fill: $primary-color !important;
      }
    }
  }
}

.tabSelectedIcon {
  background: $primary-color !important;
  color: $white !important;
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 6px 16px rgba(0, 0, 0, 0.1);

  svg {
    path {
      stroke: $white !important;
      fill: $white !important;
    }
  }
}
