@import "../../../assets/styles/colors.scss";
@import "../../../assets/styles/variables.scss";

.iconWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  padding: 6px;
  border-radius: $small-border-radius;
  position: relative;

  &.iconButtonWrapper {
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: $small-border-radius;
  }

  &.iconWrapperSmall {
    width: 24px;
    height: 24px;
    padding: 2px;
    display: inline-flex;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: $button-border-color;
  }

  &:active, &.iconWrapperActive {
    background-color: $debtor-gray;
  }

  &.iconWrapperDisabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }
}

.settingsLinkIcon,
.salesforceLinkIcon,
.boxLinkIcon,
.tableauLinkIcon {
  cursor: pointer;
  display: block;
}

.editMappingIcon {
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.linkIcon {
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.badge {
  span {
    background-color: red;
    color: $white;
  }
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate 1.5s linear infinite;;
}

.mappingRequired {
  svg {
    path {
      stroke: $yellow-text;
    }
  }

  &:hover {
    background-color: $yellow-background;

    svg {
      path {
        stroke: $yellow-text;
      }
    }
  }

  &:active {
    background-color: $yellow-background-dark;

    svg {
      path {
        stroke: $yellow-text;
      }
    }
  }
}

.mappingDisabled {
  cursor: not-allowed;

  svg {
    path {
      stroke: $diabled-button;
    }
  }
}

@keyframes oscillate {
  0% { transform: translateY(0); }
  20% { transform: translateY(-2px); }
  40% { transform: translateY(2px); }
  60% { transform: translateY(-2px); }
  80% { transform: translateY(2px); }
  100% { transform: translateY(0); }
}

.shaking {
  animation: oscillate 1s infinite;
}

.visibilityIcon {
  svg {
    height: 22px;
  }
}

.clearIcon {
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $santas-gray;
  border-radius: 50%;

  > svg {
    height: 14px;
    width: 14px;
    path {
      fill: $white;
    }
  }
}

.clearIconLoading {
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px $primary-color dashed;
    border-radius: inherit;
    animation: spin 5s infinite linear;
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pencilIcon{
  height: 24px;
  width: 24px;
    path {
    fill: $santas-gray;
}
}


.error {
  svg {
    path {
      stroke: $red;
    }
  }

  &:hover {
    background-color: $red-background;

    > svg {
      path {
        stroke: $red;
      }
    }
  }

  &:active {
    background-color: $red-background-dark;

    > svg {
      path {
        stroke: $red;
      }
    }
  }
}

.plusIcon {
  path {
    stroke-width: 1px;
    stroke: $active-blue;
  }
}

.gearIcon {
  width: 20px;
  height: 20px;
}
