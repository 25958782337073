@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }

  tr {
    td, th {
      &:nth-child(1) {
        width: 18% !important;
      }

      &:nth-child(2) {
        width: 120px !important;
      }

      &:nth-child(3) {
        width: 100px !important;
      }


      &:last-child {
        width: 120px !important;
      }
    }
  }

  tbody {
    position: relative;

    tr {
      td {
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          white-space: pre-wrap;
        }

        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }
      }
    }
  }
}

.changedValue {
  white-space: nowrap;
}

.scrollableDiv {
  overflow-y: scroll;
  max-height: 240px !important;
}
