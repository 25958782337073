@import '../../assets/styles/colors.scss';

.table {
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none ; 
}

  tbody {
    display: table !important;
    max-height: initial !important;
    border-collapse: separate !important;
  }

  thead {
    background-color: $row-background-color;
  }
  tr {
    background-color: transparent !important;
    
    td ,
    th {

      &:nth-child(1) {
        position: sticky !important;
        background-color: inherit;
        left: 0;
        width: 225px;
        text-align: left;
        z-index: 1;
        //box shadow on the right 
        box-shadow: 7px 20px 20px 0px rgba(0, 0, 0, 0.2)

      }

      &:not(:first-child) {
        width: 150px;
        >div{
          font-weight: 700;
        }
        }
    }
    td {
      &:nth-child(1){
        background-color: white;
      }
    }
  }
  tr {
    height: 40px;
  }
}

.header {
  font-weight: 700;
  color: $secondary-color;
  font-size: 10px;

}

.headerStatement {
  font-weight: 700;
  color: $secondary-color;
  font-size: 12px;
  text-align: left;
  margin-left: 10%;
}
.validCell {
  background: $light-green;
  color: $secondary-color;
  font-weight: 400;
    font-size: 12px;
  border-top: 9px solid $white;
}

.invalidCell {
  font-weight: 400;
  font-size: 12px;
  background-color: $red-background;
  color: $secondary-color;
    border-top: 9px solid $white;
}

.infoIcon {
float: right;
margin-right: 15px;

  vertical-align: middle;
  cursor: pointer;
}
.infoIconSize {
  font-size: 1rem;
}


.infoIconContainer{
  float: right;

}

.instructions {
  float: right;
}
.reconciliation {
 background-color: $white;
}

.account {
  float: left;
  color: $secondary-color;
  font-weight: 400;
  font-size: 12px;
  width: 100%;


}