@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.recordDate {
  width: 100%;
  height: 36px !important;

  :global {
    .MuiInput-root {
      width: 100% !important;
      height: 36px !important;
    }
  }
}

.radioLabel {
  font-size: 12px;
  font-weight: 500;
  color: $black;
}

.amountField {
  margin: 0;

  > div > input {
    padding: 0 !important;
  }
}



