@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.dateRangeField {
  width: 130px;
  height: 36px;

  :global {
    .MuiInput-root {
      cursor: pointer;
    }
    .MuiInputBase-input {
      text-align: left;
      cursor: pointer;
    }
  }

  @include text-field-with-top-label;
}

.dateRangeFieldIcon {
  margin-right: 5px;
}

.dateRangeGrid {
  padding: 10px 3px 10px 10px;
  border-radius: $big-border-radius;
}
