@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.tableContainer {
  padding-top: 10px !important;
}

.documentTable {
  table {
    tbody {
      max-height: unset;
    }

    tr {
      th,
      td {
        padding-left: 20px !important;
        &:nth-child(1) {
          width: 35%;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 10%;
        }

        &:nth-child(4) {
          width: 30%;
        }

        &:nth-child(5) {
          width: 15%;
        }
      }
    }
  }
}

.documentTableItemTitle {
  width: 100% !important;
  height: 100%;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.documentTableItemStatus {
  &.documentTableItemStatusNotStarted {
    :global {
      .MuiInput-input {
        background: $border-color;
        color: $slate-gray !important;
      }
    }
  }

  &.documentTableItemStatusInProgress {
    :global {
      .MuiInput-input {
        background: $yellow-background;
        color: $yellow-text;
      }
    }
  }

  &.documentTableItemStatusCompleted {
    :global {
      .MuiInput-input {
        background: $green-background;
        color: $green-text !important;
      }
    }
  }

  :global {
    .MuiInput-input {
      display: flex;
      padding: 3px 32px 3px 8px !important;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      align-items: center;
      gap: 8px;
    }
  }
}

.diligenceProgressStepItemStatusOption {
  svg {
    margin-right: 8px;
    path {
      fill: $input-color;
    }
  }
}
