@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 60px;
  text-align: left;
  height: 100%;
  padding: 0 20px 10px 0;
}

.documentRequestListWrapper {
  min-width: 385px;
  width: 35%;
  padding-right: 5px;
  max-height: calc(95vh - 220px);
  overflow-y: auto;
  z-index: 1;
  background: $white;
}

.documentRequestListHeader {
  color: $secondary-color;
  font-size: 14px;
  line-height: 18px;
  font-weight: 800;
  margin-bottom: 10px;
}

.documentRequestList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.documentRequestListItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 13px 21px;
  border: 1px solid $button-border-color;
  border-radius: $small-border-radius;
  cursor: pointer;
  min-height: 75px;
}

.documentRequestListItemSelected {
  border-color: $primary-color;
}

.documentRequestListItemText {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.documentRequestListItemName {
  color: $secondary-color;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}

.documentRequestListItemHelperText {
  color: $slate-gray;
  font-size: 10px;
  line-height: 16px;
}

.documentRequestListItemIcons {
  display: flex;
}

.documentRequestListItemCount {
  width: 20px;
  height: 20px;
  background: $success-green;
  border-radius: 50%;
  text-align: center;
  font-size: $main-font-size;
  line-height: 20px;
  color: $white;
  font-weight: 700;
}

.documentsUploadWrapper {
  flex: 1;
  display: flex;
  height: 100%;
  max-width: 50%;
}

.documentsUpload {
  padding: 10px 15px;
  flex: 1;
  box-shadow: 0 0 15px 0 rgba(17, 24, 38, 0.08);
  border-radius: 10px;
  height: 100%;
}

.documentsUploadHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  color: $secondary-color;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid $button-border-color;
  cursor: pointer;
}

.documentsUploadHeaderTemplateLink {
  cursor: pointer;
  color: $primary-color;
  font-size: $main-font-size;
}


.autoReporting{
  color: $slate-gray;
  font-weight: 500;
  margin-bottom: 10px;
}

.autoReportingLink{
  color: $primary-color;
  font-weight: 700 ;
  cursor: pointer;
}
