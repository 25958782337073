@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
  font-size: $main-font-size;
  font-weight: inherit;
  padding: 3px 0;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover, &:focus, &:active,  &:focus-within, &:target {
    :global .tableFormField {
    width: 98% !important;
    display: block; /* Make the form field visible */
    opacity: 1; /* Fade in the form field */
    pointer-events: auto; /* Allow interaction */
      transition: opacity 0.3s ease-in-out;
  } 
  :global  .tableInactiveFormField {
    display: none;
  }
  :global .syntheticInputField {
    cursor: text;
    background: $white;
    padding: 2px 5px 2px 5px;
    border-radius: 5px;
    width: 98%;
    display: flex;
    align-items: center;
    }
  
  :global .syntheticSelectField {
    cursor: pointer;
    background: $white;
    padding: 2px 5px 2px 7px;
    border-radius: 5px;
    width: 98%;
    display: flex;
    }
  }
}

:global .tableFormField {
  width: 98%;
}

.headRow {
  font-size: $main-font-size;
  color: $dark-blue;
  font-weight: 700;
}

.autocompleteFilter {
  > :last-child {
    :global {
      .MuiInputBase-root {
        .MuiSelect-select {
          border-radius: 0 10px 10px 0;
        }
      }
    }

    &:first-child {
      :global {
        .MuiInputBase-root {
          .MuiSelect-select {
            border-radius: $main-border-radius;
          }
        }
      }
    }
  }
}

.amountFilter {
  :global {
    .MuiTextField-root {
      > div {
        max-width: 69px;
      }

      .MuiFormHelperText-root {
        position: absolute;
        top: 36px;
        min-width: 90px;
        margin: 0;
        font-size: 10px;
        white-space: break-spaces;
      }

      input {
        height: 20px;
        padding: 8px 10px;
        font-size: $main-font-size;
        line-height: 20px;
        text-align: center;
      }

      + .MuiTextField-root {
        padding-left: 20px;

        &:before {
          position: absolute;
          left: 0;
          display: inline-block;
          width: 20px;
          line-height: 20px;
          padding: 8px 3px;
          background: $light-blue-gray;
          color: $dark-blue;
          content: '—';
          text-align: center;
        }
      }
    }
  }
}

.textFilter {
  :global {
    .MuiTextField-root {
      max-width: 160px;

      .MuiFormHelperText-root {
        position: absolute;
        top: 36px;
        margin: 0;
        font-size: 10px;
        white-space: break-spaces;
      }

      input {
        height: 20px;
        padding: 8px 10px;
        font-size: $main-font-size;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}

.dateFilter {
  :global {
    .MuiTextField-root {
      > div {
        max-width: 110px;
      }

      .MuiFormHelperText-root {
        position: absolute;
        top: 36px;
        margin: 0;
        font-size: 10px;
        white-space: break-spaces;
      }

      .MuiInputAdornment-positionEnd {
        margin-left: 5px;
      }
    }
  }
}

.autocompleteFilter {
  :global {
    .MuiAutocomplete-root {
      display: inline-flex;
      vertical-align: top;
    }
  }
}


