@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.container {
  display: flex;
  align-items: center;
  min-height: 55px;
  padding: 10px 15px;
  background: $white;
  border-radius: $main-border-radius;
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);

}

.title {
  min-width: 25%;
  color:  $header2-color;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  padding-right: 10px;
}

.content {
  flex: 1;
  display: flex;
  max-width: calc(75% - 120px);
  flex-wrap: wrap;
  gap: 10px;
}

.action {
  min-width: 120px;
}
