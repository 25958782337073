@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 35px;

  svg {
    cursor: pointer
  }
}

.countBadge {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0 6px;
  background: $persimmon;
  color: $white;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1;
  min-width: 20px;
  border-radius: 50%;
}

.popover {
  position: relative;
  overflow: visible;
  padding: 40px 16px;
  width: 400px;
  max-height: 515px;
  box-shadow: 0 0 2px rgba($black, 0.06), 0 8px 24px rgba($black, 0.1);
  border-radius: 24px;

  @media only screen and (max-height: 600px) {
    max-height: calc(100vh - 75px);
  }

  &:before {
    background: transparent;
    width: 0;
    height: 0;
    border-width: 16px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: $modal-background-color;
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 32px / 2);
    top: -32px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
  padding: 0 16px;
}

.linkButton {
  height: 40px;
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: $white;
  background: $primary-color;
  text-transform: none !important;
  line-height: 20px;
  box-shadow: none;
  border-radius: $small-border-radius;
  white-space: nowrap;

  &:hover {
    background-color: $cerulean;
    box-shadow: none;
  }
}

.notificationsList {
  display: flex;
  flex-direction: column;
  max-height: 385px;
  overflow-y: auto;

  @media only screen and (max-height: 600px) {
    max-height: calc(100vh - 130px - 75px);
  }
}

.notificationItemLink {
  text-decoration: none;
}

.notificationItem {
  padding: 16px;
  display: flex;
  gap: 25px;
  align-items: center;

  &:hover {
    background: $gray-background-color;
    cursor: pointer;
  }
}

.notificationItemAvatar {
  height: 45px;
  width: 45px;
}

.notificationItemContent {
  flex: 1 1 calc(100% - 45px - 25px - 25px - 12px); // Avatar - Gap - Gap - Unread icon
  display: flex;
  flex-direction: column;
}

.notificationItemText {
  font-size: 14px;
  font-weight: 600;
  color: $secondary-color;
}

.notificationItemDate {
  font-size: 10px;
  color: $raven;
}

.notificationsEmpty {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: $p-color;
  text-align: center;
}
