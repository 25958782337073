@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.table {
  tbody {
    max-height: 50vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 60vh !important;
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 25%;
      }

      &:nth-child(2) {
        width: 18%;
      }

      &:nth-child(3) {
        width: 70px;
      }

      &:nth-child(4) {
        width: 20%;
      }

      &:nth-child(5) {
        width: 15%;
      }

      &:nth-child(6) {
        width: 100px  ;
      }

      &:nth-child(7) {
        width: 15%;
      }
    }

    &.newRow {
      background-color: $mabel !important;
    }

    .entityPreviewIcon {
      display: none;
    }

    &:hover {
      .entityPreviewIcon {
        display: inline;
      }
    }
  }

  &.tableEligibility {
    tr {

      td,
      th {
        &:nth-child(1) {
          width: 35%;
        }

        &:nth-child(2) {
          width: 10%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 15%;
        }
      }
    }
  }
}

.ineligibleCategoryList {
  width: 97%;

  :global {
    .MuiSelect-select {
      padding: 2px 7px;
    }

    .MuiSelect-icon {
      top: 4px;
      padding: 2px 5px;
    }
  }
}


.priceAdornment {
  color: $secondary-color;

}

.disabledCheckbox {

  cursor: default !important;
  // override the class MuiButtonBase-root-MuiCheckbox-root to have padding 0

  :global {
    .MuiSelect-select {
      padding: 2px 7px;
    }

    .MuiSelect-icon {
      top: 4px;
      padding: 2px 5px;
    }

    .MuiCheckbox-root {
      padding: 0;
    }

    .MuiFormControlLabel-root {
      margin-left: 0;
    }
  }

}

.entityLink {
  max-width: calc(100% - 17px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
