

.inputLabel {
    margin-top: 10px;
}

.button {
    margin-top: 20px;
}

.modal {
    width:600px;
}

.participationCreateCell{
    padding: 10px 0px; 
}

.effectiveDateContainer {
    h4 {
      font-size: 14px;
    }
  
    & > h4, p {
      margin-bottom: 0;
      text-align: left;
    }
  }
  
  .effectiveDate {
    width: 100%;
  }
  

  .terminationDate {
    width: 100%;
    gap: 10px;
    margin-left:8px;
      >h3 {
        margin-bottom: 5px;;
      }

  }