@import '../../assets/styles/variables.scss';
@import '../../assets/styles/colors.scss';


.card {
  margin-top: 10px;
}

.chartContainer {
  position: relative;
  min-height: 400px;
  max-height: 750px;
  width: 100vw;
}

  .totalMetrics {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 12px;
  }

  .totalMetrics > div {
    margin-right: 20px;
  }

  .totalMetricsTitle {
    font-weight: 600;
    color: $slate-gray;
    margin-right:5px;
  }

