@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - $header-height - 48px);
}

.actionsContainer {
  padding: 10px 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $button-border-color;
  border-top: 1px solid $button-border-color;
  gap: 8px;
}

.actionButtonContainer {
  border-radius: $small-border-radius;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.actionButtonContainerFilterContainer {
  flex: 1;

  > div {
    padding: 0;

    > div {
      padding: 0;
    }
  }

  [class*="FilterButton_filterButton_"] {
    height: 35px;
    min-width: 35px;
    padding: 7.5px;
  }
}

.button {
  cursor: pointer;
  border-radius: $small-border-radius;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  padding: 7.5px;

  &:hover {
    background-color: $button-border-color;
  }
}

.buttonActive {
  background-color: $debtor-gray;
}

.logsContainer {
  padding: 10px 7px;
  max-height: calc(100vh - $header-height - 48px - 56px);
  overflow-y: auto;
  flex-grow: 1;
}

.infiniteScroll {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.emptyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.sortButton {
  margin-left: 4px;
}

.sortMenuPopoverPaper {
  width: 175px;
  padding: 5px;
}

.sortMenuItem {
  padding: 10px 16px;
  font-size: 12px;
  line-height: 15px;
  color: $secondary-color;

  &:hover {
    background-color: $gallery;
  }

  &.sortMenuItemSelected {
    background-color: $button-border-color;

    &:hover {
      background-color: $gallery;
    }
  }
}
