@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.container {
  margin-top: 10px;
  min-height: 24px;
  display: flex;
  align-items: center;
  gap: 5px
}

.label {
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: $slate-gray;
  text-align: right;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}
