@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables";

.filters {
  > div {
    &:nth-child(1) {
      max-width: 200px;

      :global {
        .MuiFormControl-root {
          max-width: 165px;
          margin-left: 5px;
        }
      }
    }

    &:nth-child(2) {
      max-width: 290px;

      :global {
        .MuiAutocomplete-root {
          width: 100%;
          max-width: 255px;
          margin-left: 5px;
          display: inline-flex;
          vertical-align: top;
        }
      }
    }

    &:nth-child(3) {
      max-width: 200px;

      :global {
        .MuiAutocomplete-root {
          width: 100%;
          max-width: 165px;
          margin-left: 5px;
          display: inline-flex;
          vertical-align: top;
        }
      }
    }

    &:nth-child(4) {
      max-width: 285px;

      :global {
        .MuiBox-root {
          margin-left: 5px;
          display: inline-flex;
          vertical-align: top;
        }

        .MuiTextField-root {
          max-width: 115px;
        }
      }
    }
  }
}

.activityQueueRowDisabled {
  .activityQueueRowLink {
    cursor: default;
  }
}

.activityQueueRowDisabledBBC {
  opacity: 0.7;

  .activityQueueRowLink {
    pointer-events: none;
  }
}

.activityQueueRowLink {
  display: inline-block;
  cursor: pointer;
  color: $primary-color;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pendingIcon {
  path {
    fill: $slate-gray !important;
  }
}

.fundedIcon {
  path {
    stroke: $white
  }
}

.activityFilters {
  position: relative;
  padding: 0;
  min-height: 40px;
  overflow: visible;
}

.activityTable {
  table tbody {
    max-height: calc(100vh - 350px);
  }

  tr {
    td, th {

      &:last-of-type {
        width: 90px;
      }
    }
  }
}

.downChevron {
  margin-left: 10px;
  margin-top: 3px;
  fill: $primary-color;
}

.status {
  display: inline-block;
  padding: 3px 8px;
  border-radius: $small-border-radius;
}

.activityBBCBlockedIcon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 5px;
}

.row {
  cursor: pointer;
}
