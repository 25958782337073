@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.documentsAndCommentsSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.commentsWrapper {
  flex: 0;
  display: flex;
  flex-direction: column;
  gap: 17px;
  overflow-y: auto;
  margin-bottom: 10px;
  transition: flex-basis 0.4s;
  scroll-behavior: smooth;
  @include thin-scrollbar;

  &.commentsWrapperExpanded {
    flex-basis: 100%;
  }
}

.comment {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 2px 10px 0px 10px;

  + .comment {
    position: relative;

    &:before {
      content: '';
      height: 1px;
      width: 100%;
      background: $button-border-color;
      position: absolute;
      top: -15px;
      left: 0;
      right: 100%;
    }
  }
}

.commentUserAndActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commentUser {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 11px;
  line-height: 1.4;
  color: $black;
  div {
    height: 32px;
    width: 32px;
    font-size: 14px;

  }
}

.commentText {
  padding: 2px 8px 0 8px;
  font-size: 14px;
  line-height: 20px;
  overflow-wrap: break-word;
}

.commentActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.commentDate {
  text-align: right;
  color: $slate-gray;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5;
}

.addCommentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.commentButton {
  width: 100%;
}

.commentsEmpty {
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: $p-color;
  font-size: 12px;
  padding: 0px 31px;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;

    circle {
      fill: $p-color;
    }
    path {
      stroke: $p-color;
    }
  }
}
.readOnly {
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
  div > div {
    margin: 0;
    vertical-align: middle;
  }
  :global {
    .rdw-editor-toolbar {
      display: none;
    }
  }
}

.editorContainer {
  padding: 10px 10px 5px 10px;
  border: 1px solid $input-border-color;
  background: white;
  border-radius: 5px;
  width: 100%;
}

.editorWrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex-direction: column-reverse;
  max-height:300px;

  .editorToolbar {
    border-radius: 5px;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    margin: 10px 0 5px;
    height: 24px;
    border: none;

    div {
      img {
        width: 20px;
        height: 20px;
      }
    }

    :global {
      .MuiButtonBase-root {
        width: 50px !important;
        height: 32px;
        font-size: 12px;
      }
    }
  }

  .editor {
    padding: 0 5px;
    background: $white;
    border-radius: 20px;
    min-height: 50px;
  }

  :global {
    .rdw-editor-toolbar {
      .rdw-dropdown-carettoopen {
        top: 50%;
        transform: translateY(-50%);
      }

      .rdw-inline-wrapper {
        margin-bottom: 0 !important;
        background: $wild-sand;
        border-radius: $small-border-radius;
        padding: 5px;
        gap: 4px;

        > .rdw-option-wrapper {
          width: 22px;
          min-width: 22px;
          height: 22px;
          padding: 2px;
          border-radius: 5px !important;
          border: 0;
          background: none;
          margin: 0;
          box-sizing: border-box;

          &:hover {
            box-shadow: none;
          }

          img {
            width: 18px;
            height: 18px;
          }

          &:not(:last-child) {
            position: relative;
          }

          &.rdw-option-active {
            border-radius: 5px;
            background: $mercury;
          }
        }
      }
    }

    .rdw-editor-main {
      font-size: 12px;
    }

    .public-DraftStyleDefault-block {
      margin: 0;
    }
  }
}

.dateContainer {
  display: flex;
  align-items: center;
}
