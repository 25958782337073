@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.clientDashboardHeaderAvatar {
  height: 140px;
  width: 140px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 64px;
  color: $secondary-color;
  background-color: $white !important;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
}
.clientDashboardHeaderAvatarImg{
  height: 130px;
  width: 130px;
}

.clientDashboardHeaderTitle {
  text-align: left;
  color: $transparent-gray;
  font-size: 14px;
  font-weight: 500;
}

.clientDashboardHeaderValue {
  text-align: left;
  color: $secondary-color;
  font-size: 18px;
  font-weight: 600;

}

.clientSetupToolTip {
  background: $dark-blue;
  color: $white;
  font-size: 12px;
  line-height: 14px;
}

.clientSetupToolTipArrow {
  color: $dark-blue;
}

.infoDivider {
  width: 100%;
  border-bottom: 1px solid $gallery;
  padding-top: 24px;
}


.menuItemLink {
  color: $black;
  text-decoration: none;
}

.headerContainer {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  align-items: center;
  margin-top: 16px;
  min-height: 140px;
}

.contentContainer {
  flex: 1;
  margin-bottom: 8px;
  height: 100%;
  display: flex;
  align-items: center;
}

.avatarGridContainer {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 140px;
}
