@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tbody {
    max-height: 75vh !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 250px;
      }

      &:nth-child(9) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
      }
    }
  }
}

.linkIcon {
  margin-top: 3px;
  margin-left: 7px;
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

.salesforceLinkIcon {
  margin-top: 3px;
  margin-left: 7px;
  vertical-align: middle;
  height: 25px;
  width: 25px;
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer + new filters
      max-height: calc(95vh - 267px) !important;
    }
  }
}



.refreshButton {
  margin-left: auto;
  margin-right: 15px;
}


.tooltip {
  background-color: $primary-color;
}


