@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins';

.detailsContainer {
  width: 37.5%;
  background: $white;
  border-left: 1px solid $input-border-color;
  padding: 25px;
  overflow-y: scroll;
  height: 85vh;
}

.headerContainer {
  display: flex;
  gap: 8px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 90%;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.title {
  font-size: 16px;
  font-weight: 800;
  width: 90%;
}

.titleEditing {
  width: 100%;

  :global {
    .MuiInput-root {
      border: none;
      border-radius: 7px !important;
      border: 1px solid $edit-border !important;
      border-color: $edit-border !important;
    }
    .MuiInput-root:focus {
      outline: none;

      > div {
        outline: none;
      }
    }
    .MuiTextField-root:focus {
      outline: none;
      > div {
        outline: none;
      }
    }
  }
}

.tags {
  font-size: 10px;
  font-weight: 700;
  color: $blue-header;
  margin-right: 6px;
  display: inline-block;
  line-height: 14px;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  color: $secondary-color;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  > svg {
    top: 0px;
    position: absolute;
    > path {
      fill: transparent;
    }
  }
}

.avatarImg {
  max-height: 60px;
  max-width: 60px;
}

.date {
  color: var(--Greyscale-Grey-500, #718096);
  text-align: right;
  font-size: 10px;
  font-weight: 500;
}

.editFieldIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  > path {
    fill: black;
    stroke: $white !important;
  }
  &:hover {
    background-color: $button-border-color;
    border-radius: $small-border-radius;
  }
}

.deleteIcon {
  cursor: pointer;
  &:hover {
    background-color: $button-border-color;
    border-radius: $small-border-radius;
  }
}

.headlineSummary {
  padding: 15px 0px;
  border-bottom: 1px solid $input-border-color;
  color: $black;
  font-size: 16px;
  font-weight: 400;
}

.linkedAccounts {
  padding: 25px 0px;
  display: flex;
}

.bodyItem {
  color: $transparent-gray;
  align-content: center;
}

.bodyItemLink {
  color: $primary-color;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines before truncation */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline !important;
  }
}

.bodyItemValue {
  font-size: 14px;
  font-weight: 400;
}

.bodyContainer {
  gap: 5px;
  padding-top: 25px;
}

.bodyTitle {
  font-size: 16px;
  font-weight: 800;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  :global {
    .MuiInput-root {
      height: 28px;
    }
  }
}

.bodyDetails {
  display: flex;
  gap: 12px;
  padding-bottom: 6px;
  font-size: 14px;
  max-width: 30vw;
}

.autocompleteField {
  :global {
    .MuiTextField-root {
      > div {
        width: 250px;
        height: 34px;
        border: 1px solid $input-border-color;
      }
    }
  }
}

.iconContainer {
  display: flex;
  gap: 5px;
}

.editIcon {
  position: absolute;
  bottom: 7px;
  right: 7px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.avatar:hover .editIcon {
  visibility: visible;
  opacity: 1;
  > path {
    fill: $white;
  }
}

.avatar:hover .avatarContainer {
  filter: brightness(50%) grayscale(60%);
  background-color: $white;
}

.avatarText {
  width: 60px;
  height: 60px;
  text-align: center;
  align-content: center;
  font-size: 35px;
}

.avatar:hover .avatarText {
  filter: brightness(50%) grayscale(60%);
  background-color: $white;
}

.avatar {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 64px;
  color: $secondary-color;
  background-color: $white !important;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
  position: relative;
}

.avatarContainer {
  height: 60px;
  width: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bodyDetailsBrands {
  display: flex;
  gap: 12px;
  padding-bottom: 6px;
  font-size: 14px;
  max-width: 30vw;
  align-items: center;
}

.autoCompleteField {
  :global {
    .MuiAutocomplete-root {
      border: 1px solid $input-border-color;
      border-radius: $small-border-radius;
    }
  }
}

.addNewBrand {
  color: $primary-color;
  font-size: 10px;
  cursor: pointer;
}

.addBrandModalRoot {
  width: 465px !important;
}

.newBrandField {
  padding-top: 6px;
  :global {
    .MuiInputBase-input {
      height: 50px !important;
    }
  }
}

.newBrandLabel {
  color: $black;
  font-weight: 600;
}

.selectField {
  width: 100%;
  height: 34px;
  fieldset {
    border: 1px solid $input-border-color;
  }
}

.textField {
  width: 100%;
  p {
    color: $red-error !important;
  }
}

.selectField:hover {
  fieldset {
    border: 1px solid $input-border-color !important;
  }
}

.typeTag {
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  color: $secondary-color;
  box-sizing: border-box;
  background-color: white;
}

.typeTagContainer {
  position: relative;
  display: inline-block;
}

.tagClose {
  display: none;
}

.industryName {
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
}

.industryHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  width: 230px;
  cursor: pointer;
}

.industryCheckboxTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.expandChevron {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.chevronOpen {
  transform: rotate(180deg);
}

.industryRow {
  display: flex;
  gap: 40px;
  align-items: flex-start;
  padding-bottom: 10px;
}

.clientList {
  max-height: 22vh;
  overflow: scroll;
  font-size: 14px;
  line-height: 27px;
  @include thin-scrollbar;
  padding-top: 5px;
}

.typeTagContainer:hover {
  > svg {
    display: inline !important;
    cursor: pointer;
    position: absolute;
    top: -3px;
    right: 3px;
    height: 8px !important;
    width: 8px !important;
    > path {
      fill: $black;
    }
  }
}

.recordDate {
  position: sticky;
  top: 0;
  background-color: $white;
  :global {
    .MuiInputBase-input {
      font-size: 14px;
    }
  }
}

.clientListContainer {
  display: flex;
  flex-direction: column;
}

.taggingField {
  :global {
    .MuiInput-root {
      min-height: 34px;
    }
  }
}

.select {
  width: 138px;
  height: 34px;
}

.fieldOpen {
  display: block;
  :global {
    .MuiInputBase-root {
      background-color: $white;
    }
  }
}

.addTags {
  display: flex;
  align-items: center;
  color: $primary-color;
  font-size: 10px;
  font-weight: 600;
  line-height: 25px;
  margin: 0 5px;
  > svg {
    height: 10px;
    width: 10px;
    > path {
      fill: $primary-color;
    }
    > rect {
      stroke: $white;
    }
  }
}

.addTagsText {
  padding-left: 6px;
  cursor: pointer;
}

.tagsContainer {
  align-items: center;
  display: flex;
}
