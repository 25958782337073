@import '../../assets/styles/colors';
@import '../../assets/styles/variables';


.table {
  tr {

    td,
    th {
      &:nth-child(1) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 50%;
        padding-left: 5px;
      }


    }
  }

  tbody {
    tr {
      background: $white;

      &.activeRow {
        background: $dropdown-selected-color;
      }

      td {
        :global {
          .MuiFormControl-root {
            width: 100%;

            >div {
              width: 100% ;
            }

            .Mui-focused {
              background-color: $input-hovered-background-color !important;
              outline: 1.5px solid $cerulean !important;
              border-radius: $main-border-radius !important;
            }
          }

          .Mui-focused {
            outline: none !important;
          }
        }
      }
    }
  }
}

