@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  padding: 4px 0;
}

.label {
  font-size: $main-font-size;
  line-height: 20px;
  color: $gothic;
  text-transform: uppercase;
}

.progress {
  width: 200px;
  margin: auto 10px;
  border-radius: 2px;
  height: 4px;
  background-color: $light-blue-gray;

  :global {
    .MuiLinearProgress-bar {
      border-radius: 2px;
      background-color: $success-green;
    }
  }
}

.percent {
  font-size: $main-font-size;
  line-height: 20px;
  color: $success-green;
  font-weight: bold;
}
