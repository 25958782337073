@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../pages/ClientIntakePage/ClientIntakePage.module';

.formContent {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  > div {
    flex: 0 calc(50% - 12px);
  }
}
