@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 50px;
      }

      &:nth-child(2) {
        width: 100px;
      }
    }
  }
}

.loanLedgerWiresTable {
  tr {
    td,
    th {
      width: auto !important;

      &:nth-child(4) {
        width: 60px !important;
      }
    }

    &.deletedRow {
      opacity: 0.5;
    }
  }
}

.value {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.button {
  align-items: center;
}

.plusIcon {
  path {
    stroke: $primary-color;
  }
}

.highlightedCurrent {
  background: $nepal;
  color: $white !important;
}


.selectField {
  width: 120px;
  height: 36px;

  @include select-field-with-top-label;
}
