@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.eligibilityButtonWrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.eligibilityButton {
  padding: 8px;
  display: flex;
  align-items: center;
  height: 35px;
  background: $white;
  color: $dark-blue;
  border-radius: $main-border-radius;
  font-size: $main-font-size;
  line-height: 20px;

  > div {
    border: $primary-color 1px solid;
    border-radius: 5px;
  }

  span {
    color: $primary-color;
  }

  svg {
    fill: $primary-color;
    stroke: $primary-color;
  }

  :global {
    .MuiInput-root {
      margin-left: 10px;

      .MuiSelect-select {
        background: transparent;
      }
    }
  }
}

.ineligbleFieldsCard {
  padding: 10px 18px;
}

.eligibilityTableWrapper {
  padding-top: 18px !important;
}
