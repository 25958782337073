$white: #ffffff;
$black: #000000;
$curious-blue: #20a5ce;
$mercury: #e8e8e8;
$alabaster: #f7f7f7;
$gallery: #eaeaea;
$concrete: #f2f2f2;
$light-gray: #fcfcfc;
$wild-sand: #f6f6f6;
$derby: #ffeddb;
$barley-white: #fff3c7;
$vis-vis: #ffeda5;
$candlelight: #fecd1a;
$gold: #ffd700;
$sun: #ffad0f;
$corn: #e2a300;

$yellow-tag-background: #fbf0e0;
$yellow-tag: #e78b0a;

$red-tag-background: #ffa7a7;
$red-tag: #761818;

$sunshade: #ffa62e;
$sundown: #ffb3b3;
$outrageous-orange: #ff602e;
$persimmon: #ff6a55;
$coral-red: #ff4141;
$dusty-gray: #999999;
$silver: #cccccc;
$alto: #d1d1d1;
$santas-gray: #9fa2b4;
$raven: #777e90;
$onahau: #c2efff;
$mabel: #dbf6ff;
$alice-blue: #edfaff;
$blue-wale: #012f49;
$light-red: #eb5757;
$dark-slate-blue: #323b4b;

$red: #f20622;
$crimson: #eb1e43;
$de-york: #6fcf97;
$vista-blue: #83d7ac;
$caribbean-green: #01cb9d;
$jungle-green: #27ae60;
$salem: #03a02f;
$mountain-meadow: #22c55e;
$azure: #eff4f5;
$lily-white: #ebfaff;
$hint-of-green: #edfff4;
$valid-row-green: #f2fdf6;
$fair-pink: #ffeded;
$electric-violet: #9e00ff;

$navy-blue: #5285aa;
$dark-blue: #002e4a;
$light-blue: #f1faff;
$blue-gray: #acc1d0;
$light-blue-gray: #f2f6fe;
$athens-light-gray: #f3f5f8;
$athens-gray: #e8eaf0;
$active-blue: #4285f4;
$azure-radiance: #0094ff;
$thin-blue: #e0eaff;
$selago: #e9f1fc;
$bright-navy-blue: #486f87;
$gothic: #7992a4;
$nepal: #96a3be;
$botticelli: #c7dae7;
$chip-blue: #00b0ff;
$lochmara: #0070bb;
$select-blue: #1252d1;
$cobalt: #004cb7;
$white-lilac: #e3e9f4;
$anakiwa: #89e2ff;
$catskill-white: #e2e8f0;
$button-border-color: #ededed;

$input-label-color: #7a98aa;

$input-color: $dark-blue;
$input-adornment-background-color: $white;
$input-background-color: $white;
$input-hovered-background-color: $white;

$main-bg: $athens-light-gray;
$primary-color: #0066f5;
$primary-color-hovered: #0052cc;
$primary-background-color: #0068fe;
$secondary-color: #1a202c;

$off-white: #fafbfc;
$active-color: #b8ebe3;

$transparent-gray: #c2c2c2;
$prediction-possible: #ffedd5;
$prediction-likely: #ffc982;
$prediction-very-likely: #ffa52e;
$debtor-gray: #d8d9dc;
$transparent: transparent;
$light-grey: #f5f5f5;

$tundora: #444444;
$cerulean: #008fde;
$diabled-button: #80b3fa;
$perano: #9cc0f1;
$slate-gray: #718096;
$pale-sky: #6f767e;
$cadet-blue: #b0b7c3;
$header3: #00b1ff;
$disabled-input: #00000061;
$disabled-input-background: #f7f8fb;
$french-pass: #cbeffd;

$blue-header: #00b1ff;

$currency-up: #03a02f;
$currency-down: #ff0000;

$pinned-color: #fdf9cb;

$header2-color: $dark-blue;
$cool: #303640;
$row-background-color: #fafafa;

$icon-color: #28303f;

$border-color: #edf2f7;

$input-border-color: #dee1e5;

$edit-background: #f9f9f9;
$edit-border: #efefef;

$light-green: #e0ffec;
$success-green: #66b485;

$light-red: #fef2f2;

$scrollbar-color: #b9b9b9;

$p-color: #98999a;

$red-background: #ffebeb;
$red-background-dark: #ffdada;
$red-text: #cc5858;
$red-error: #d32f2f;

$yellow-background: #fbf0e0;
$yellow-background-dark: #ffe8c6;
$yellow-text: #e78a00;

$green-background: #e0ffec;
$green-text: #66b485;

$blue-background: #d9e8fe;

$gray-background-color: #f7fafc;
$past-gray-background: #d6dbe3;
$modal-background-color: #fcfcfd;

$dropdown-selected-color: $border-color;
$dropdown-hovered-color: $border-color;

$hover-row-color: $border-color;
$active-row-color: $catskill-white;
$active-column-color: $debtor-gray;

$navy-background: #e2e8eb;
$navy-text: #0b4467;
$geyser: #cbd5e0;

$red-border: #ff4747;
$alert-text: #1a1a25;
$success-alert-background: #e3fbe4;
