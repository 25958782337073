@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 45px;
      }

      &:nth-child(2) {
        width: 50%;
      }

      &:nth-child(3) {
        width: 40%;
      }

      &:nth-child(4) {
        width: 9%;
      }

    }
  }

  tbody {
    position: relative;

    tr {
      td {
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          white-space: pre-wrap;
        }

        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }
      }

      .entityPreviewIcon {
        display: none;
      }

      &:hover {
        .entityPreviewIcon {
          display: inline;
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        &:nth-child(1) {
          width: 29%;
        }

        &:nth-child(7) {
          width: 6%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(10) {
          width: auto;
        }
      }
    }
  }
}

.titleContainer {
  width: 200px !important;
}
