@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/forms';

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: $white;
}

.checkbox {
  display: none;
}

.checkbox + label {
  height: 16px;
  width: 16px;
  display: inline-block;
  padding: 0;
}

.checkbox:checked + label {
  height: 16px;
  width: 16px;
  display: inline-block;
  padding: 0;
}

.setupContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $gray-background-color;
}

.logoContainer {
  // vertically center image
  flex: 1 auto;
  width: 35%;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: calc(100vh - 195px);
  }

  background-color: $primary-background-color;
  background-size: contain;
  @media only screen and (max-width: 1023px) {
    flex: 0;
  }
}

.resetPwdFormWrapper {
  // put contents in middle
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $primary-background-color;
}

.formWrapper {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  width: 65%;
  margin: 0;
  border-radius: 0 !important;
  flex: 1 auto;
  overflow-y: auto;

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
}

.centeredContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.setupHeading {
  text-align: center;
  width: 100%;
  color: $secondary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 800;
  line-height: 50.4px;
}

.setupHeadingInfo {
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 18px;
  font-weight: 400;
  font-size: 14px;
  color: $p-color;
  line-height: 17.64px;
}

.resetButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  border-radius: 5px;
}

.resetButton:hover {
  background: $primary-color-hovered;
}

.resetFormWrapper {
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* Others / White */
  background: $white;
  box-shadow: 0 16px 24px rgba(93, 106, 131, 0.02);
  border-radius: 16px;
  font-weight: bold;
}

.forgotPwd {
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 0.2px;
  line-height: 40px;
  color: $secondary-color;
}

.emailSent {
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 0.2px;
  margin-top: 25px;
  color: $secondary-color;
}

.headingWrapper {
  justify-content: center;
  color: $secondary-color;
  font-size: 32px;
  // put at the center vertically
  display: flex;
  align-items: center;
}

.motto {
  max-width: 465px;
  text-align: center;
  line-height: 50.4px;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  vertical-align: top;
  color: $white;
  font-weight: 800;

  @media only screen and (max-width: 1279px) {
    font-size: 36px;
  }
}

.mottoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.mottoInfo {
  padding-top: 10px;
  font-weight: 600;
  line-height: 150%;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  color: $white;
  opacity: 0.7;
}

.resetFormContainer {
  margin: auto;
  text-align: center;
  height: 50% !important;

  a {
    font-weight: 600;
  }

  :global {
    .MuiFormControl-root {
      .MuiInputBase-root {
        .MuiInput-input {
          font-size: 14px;
        }
      }
    }
  }
}

.successLogo {
  height: 76px;
  width: 76px;
}

.setupFormContainer {
  margin: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;

  a {
    font-weight: bold;
  }

  :global {
    .MuiFormControl-root {
      .MuiInputBase-root {
        .MuiInput-input {
          font-size: 14px;
        }
      }
    }
  }
}

.dwightLogo {
  display: block;
  justify-content: center;
}

.formContainer {
  margin: auto;
  max-width: 428px;
  text-align: center;
  padding: 10px 0 20px;

  a {
    font-weight: bold;
  }

  :global {
    .MuiFormControl-root {
      .MuiInputBase-root {
        .MuiInput-input {
          text-align: left !important;
          font-size: 14px;
        }
      }
    }
  }
}

.heading {
  text-align: center;
  width: 100%;

  letter-spacing: 0.2px;
  color: $secondary-color;
  line-height: 30px;

  // center the text
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  font-weight: bold;
}

.emailInput {
  text-align: left;
  color: $secondary-color;
  letter-spacing: 0.2px;
  border-color: $primary-color;
}

.headingLogin, .headingError {
  width: 350px;
  height: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.headingCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headingError {
  width: 580px;
  font-size: 24px;
  line-height: 1.25;
  height: auto;
}

.headingInfo {
  padding-top: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 350px;
  color: $p-color;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
}

.info {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 10px;
  color: $p-color;
}

.successInfo {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 10px;
  color: $p-color;
}

h3 {
  text-align: center;
}

.infoContainer {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  margin-bottom: 20px;
}

.footerLink {
  float: left;
  color: $p-color;
  text-decoration: none;
  bottom: 3%;
  position: fixed;
  left: 38%;
  font-size: 14px;
  font-weight: 600;

  @media only screen and (max-width: 1023px) {
    left: 3%;
  }
}

.resetFooterLink {
  float: left;
  color: $p-color;
  text-decoration: none;
  position: fixed;
  left: 3%;
  bottom: 3%;
  font-size: 14px;
  font-weight: 600;
}

.copyright {
  float: right;
  color: $p-color;
  text-decoration: none;
  text-align: right;
  position: fixed;
  right: 3%;
  bottom: 3%;
  font-size: 14px;
  font-weight: 600;
}

.leftPoint {
  // make image larger
  width: 20px;
  height: 20px;

  // move down
  position: absolute;
  left: 26px;
}

.backToLogin {
  position: fixed;
  top: 30px;
  left: 20px;

  a {
    color: $secondary-color;
    text-decoration: none;
  }

  font-size: 16px;
  font-weight: 600;
  // put text at center of div
  text-align: right;
  width: 165px; // including the distance for the arrow
  height: 24px;
  letter-spacing: 0.2px;
}

.inputField {
  text-align: left;
  color: $secondary-color;
  letter-spacing: 0.2px;
  font-weight: bolder;
}

.setUpPassword {
  min-width: 350px;
}

.goBackButton {
  margin-top: 24px;
  width: 475px;
}

.goHomeButton {
  margin-top: 10px;
  width: 475px;
}

.headingErrorIcon {
  margin-bottom: 32px;
}
