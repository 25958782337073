@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.cardContainer {
  background: rgba(251, 251, 251, 0.99);
  border-radius: $small-border-radius !important;
  margin-top: 25px;

  :global {
    .MuiCardContent-root {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }
}

.card {
  // 20px = page offset, 25px = cardContainer margin-top
  height: calc(100vh - 20px - $header-height - 25px);
}
