.stressCaseTable {

  th, td {
    &:nth-child(2n) {
      width: 120px;
    }
    &:nth-child(2n+1) {
      width: 100px;
    }
    
    &:first-child {
      width: 235px;
    }
    
    &:nth-child(3){
      width: 120px;
    }

  }
  border-collapse: collapse;
  margin-bottom: 1rem;

}

.cell {
  padding: 0.75rem;
  vertical-align: middle;
  
  &.editable {
    padding: 0;
    
    input {
      width: 100%;
      height: 100%;
      padding: 0.75rem;
      border: none;
      background: transparent;
      
      &:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}
