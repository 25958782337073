@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins';

.titleContainer {
  display: flex;
  gap: 8px;
  padding: 14px 14px 6px 14px;
  width: 100%;
}

.titleContainerSidePanel {
  padding: 32px 28px 8px 28px !important;
  gap: 16px;
}

.alertWarningContainer {
  width: 36px;
  height: 36px;
  border-radius: $small-border-radius;
  display: grid;
  place-items: center;
  svg {
    width: 20px;
    height: 20px;
  }
}

.alertWarningContainerSidePanel {
  width: 60px !important;
  height: 60px !important;
  > svg {
    width: 40px;
    height: 40px;
  }
}

.alertIconBakcground {
  background: $red-background;
}

.warningIconBackground {
  background: $yellow-background;
}

.flagTitleText{
  cursor: default;
}

.flagTitle {
  font-weight: 600;
  font-size: 14px;
  display: grid;
  place-items: center;
}

.flagTitleSidePanel {
  font-weight: 800 !important;
  font-size: 18px !important;
  place-items: flex-start !important;
}

.likeMenu {
  margin-left: auto;
  display: flex;
  gap: 6px;
  align-items: center;
}

.modalBreadcrumbItem {
  display: flex;
  padding: 4px 8px;
  background: $gray-background-color;
  color: $secondary-color;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  border-radius: $small-border-radius;
  cursor: pointer;
  text-decoration: none;
}

.modalBreadcrumbItemSeparator {
  path {
    fill: $primary-color;
  }
}

.modalBreadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.liked {
  path {
    fill: $green-text;
  }
}

.disliked {
  path {
    fill: $red-text;
  }
}

.dislikeButton {
  rotate: 180deg;
}

.feedbackButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 3px;
  > svg {
    height: 20px;
    width: 20px;
  }
}

.likeMenuPopper {
  padding: 6px 6px;
  z-index: 1300;
  cursor: default;
  background-color: $white !important;
}

.feedMenuPopper {
  padding: 6px 6px;
  background-color: $white !important;

  > div {
    margin-right: 5px;
  }
}


.resolveInDetail{
  cursor: pointer;
  display: flex;
  align-items: center;
 svg {
    width: 25px;
    height: 20px;
  }
 }
