$min-width: 525px;
$drawer-collapsed-width: 79px;
$drawer-margin: 10px;
$drawer-expanded-width: 256px;

$notesDrawerWidth: 350px;
$bigNotesDrawerWidth: 450px;

$notesDrawerWidthFlag: 650px;
$bigNotesDrawerWidthFlag: 750px;

$main-font-size: 12px;
$main-border-radius: 10px;
$small-border-radius: 5px;
$medium-border-radius: 16px;
$big-border-radius: 24px;

$header1-font-size: 24px;
$header2-font-size: 18px;
$header3-font-size: 14px;

$p-font-size: 12px;

$header-height: 75px;
$login-as-client-container-height: 44px;

$box-viewer-width: 43%;