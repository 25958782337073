@import '../../assets/styles/colors';

.wrapper {
  max-width: 1200px;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
}

.avatarImg {
  width: 90px;
  max-height: 90px;
}

.imageUploadWrapper {
  min-width: 520px;
}

.optionClassName {
  min-height: 21px;
}
