@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.tableContainer {

  &.tableContainerContacts {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 40%;
        }
      }
    }
  }

  &.tableContainerExecutives {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 3%;
        }

        &:nth-child(2) {
          width: 25%;
        }

        &:nth-child(3) {
          width: 12%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 40%;
        }
      }
    }
  }

  &.tableContainerBoardMembers {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 30%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 20%;
        }
      }
    }
  }

  &.tableContainerReferences {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 15%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 25%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 20%;
        }
      }
    }
  }

  &.tableContainerInventoryLocations {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 3%;
        }

        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3) {
          width: 27%;
        }

        &:nth-child(4) {
          width: 25%;
        }

        &:nth-child(5) {
          width: 25%;
        }
      }
    }
  }

  &.tableContainerBankAccounts {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 3%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 30%;
        }

        &:nth-child(4) {
          width: 37%;
        }
      }
    }
  }
}

.emptyRow {
  background: transparent !important;

  td {
    width: 100%;
  }
}

.emptyRowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 65px 0;
  gap: 5px
}

.emptyRowTitle {
  color: $secondary-color;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5
}

.emptyRowText {
  color: $slate-gray;
  font-size: 14px;
  line-height: 23px;
}

.bankAccountNumberTooltip {
  background-color: $dark-blue;
}

.bankAccountNumberTooltipArrow {
  color: $dark-blue;
}

.nestedRowColumn {
  padding: 24px 90px 50px 70px !important;
}

.booleanLabel {
  font-size: 14px;
  font-weight: 500;
}

.booleanValue {
  font-size: 14px;
  font-weight: 600;
}

.booleanComment {
  padding: 16px 10px;
  border-radius: $main-border-radius;
  background: $white;
  color: $slate-gray;
  font-size: 12px;
  line-height: 2;
}
