@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";
@import "../../../pages/ClientIntakePage/ClientIntakePage.module";
@import "../../../assets/styles/mixins.scss";

.formContent {
  max-width: 720px;
  display: flex;
  overflow: hidden;
}

.documentsTypesList {
  padding: 5px 18px;
  display: flex;
  flex-direction: column;
  flex: 0 0 310px;
  gap: 10px;
  z-index: 1;
  background: $white;
}

.documentTypeItem {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 13px 21px;
  border: 1px solid $button-border-color;
  border-radius: 5px;
  cursor: pointer;
  min-height: 75px;
}

.documentTypeItemSelected {
  border-color: $primary-color;
}

.documentTypeItemContainer {
  display: flex;
  flex: 1 1 calc(100% - 46px);
  flex-direction: column;
  justify-content: center;
}

.documentTypeItemTitle {
  font-size: 14px;
  font-weight: bold;
}

.documentTypeItemDescription {
  font-size: 10px;
  color: $slate-gray;
}

.documentTypeItemIcons {
  display: flex;
}

.documentTypeItemCount {
  width: 20px;
  height: 20px;
  background: $success-green;
  border-radius: 50%;
  text-align: center;
  font-size: $main-font-size;
  line-height: 20px;
  color: $white;
  font-weight: 700;
}

.documentsListWrapper {
  flex: 1;
  max-width: 390px;
}

.documentsList {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-height: 45vh;
  overflow-y: auto;
  @include thin-scrollbar;

}

.documentsListItem {
  display: flex;
  align-items: center;
  gap: 16px
}

.documentsListItemTextContainer {
  flex: 1;
  max-width: calc(100% - 48px); // 32px delete icon + 16px gap
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.documentsListItemFileName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentsListItemLine {
  height: 4px;
  background: $success-green;
  border-radius: 10px;
}

.documentsListItemDate {
  font-size: 10px;
  color: $slate-gray;
}

.documentsListItemDeleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: $light-red;
  border-radius: 24px;
  cursor: pointer;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.documentsListItemDeleteLoadingIcon {
  animation: rotate 2s linear infinite;
  path {
    fill: $red-text;
  }
}

.autoReporting{
  color: $slate-gray;
  font-weight: 500;
}

.autoReportingLink{
  color: $primary-color;
  font-weight: 700 ;
  cursor: pointer;
}

.uploadContainer {
  max-width: 390px;
  padding: 5px 10px;
}

.uploadWrapper {
  padding: 10px 15px;
  box-shadow: 0 0 15px 0 rgba(17, 24, 38, 0.08);
  border-radius: 10px;
}
