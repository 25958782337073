@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.wrapper {
  margin-bottom: 20px;
}

.title {
  font-size: 32px;
  line-height: 1.25;
  color: $secondary-color;
  text-align: center;
  font-weight: 800;
  margin-bottom: 8px;
}

.description {
  font-size: $header3-font-size;
  line-height: 1.25;
  color: $slate-gray;
  text-align: center;
  font-weight: 400;
}

.divider {
  width: 100px;
  height: 1px;
  background: $border-color;
  margin: 12px auto 0;
}
