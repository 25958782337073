@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.card {
  height: 100%;
}

.companyLabel {
  position: relative;
  width: 100%;
  padding: 8px 14px;
  display: inline-flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  font-size: $main-font-size;
  line-height: 20px;
  background-color: $light-blue-gray;
  border-radius: $main-border-radius;
  border: none;

  svg {
    position: absolute;
    right: 6px;
    color: rgba(0, 0, 0, 0.54);
  }
}

.textArea {
  :global {
    .MuiInputBase-root {
      height: auto;

      textarea {
        height: auto;
      }
    }
  }
}

.infoIcon {
  vertical-align: middle;
  margin-left: 5px;
}
