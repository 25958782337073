@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
  height: 24px;
  width: 24px;
  border-radius: $main-border-radius;
  padding: 2px;
  background-color: transparent;
}
