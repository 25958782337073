@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
.wrapper {
    margin: 0 auto;
    max-width: 674px;
    min-width: 650px;
  }

  .integration {
    height: 96px;
    border: 1px solid $border-color;
    border-radius: $main-border-radius;
    margin-bottom: 14px;
  }

.heading {
    font-weight: 800;
  font-size: 20px !important;
    color: $secondary-color;
    margin: 0px 0px 18px;
    text-align: left;
    height: 25px;

  }

  .description {
    color: $slate-gray;
    font-size: 14px;
    margin-bottom: 40px;
  }

  .integrationTitle{
    font-weight: 800;
font-size: 16px;
margin-bottom: 0px;
  }

  .integrationDescription{
    font-weight: 400;
    font-size: 12px;
    color: $slate-gray;
    margin-top: 10px;
  }

  .integrationIcon{
    display: grid;
    place-items: center;
    align-items: center;
    justify-items: center;

  }

  .active{
    font-weight: 600;
    font-size: $main-font-size;
    color: $success-green;
    text-align: right;
    line-height: 15px;

  }

  .activeIcon{
    display: grid;
    place-items: center;
    align-items: center;
    justify-items: flex-start;
    padding-left: 8px;
    width: 14px;
    height: 14px;

  }

  .integrationButton{
    float: right;
    margin-right: 33px;

  }

