@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.wrapper {
  max-width: 524px;
}



.label {
  font-size: $main-font-size;
  line-height: 20px;
  color: $blue-wale;
}

.progress {
  border-radius: $success-green;
  height: 7px;
  background-color: #CBD5E0;

  :global {
    .MuiLinearProgress-bar {
      border-radius:#CBD5E0;
      background-color: $success-green;
    }
  }
}

.checkSpot {

  float: left;
  padding-top: 1px;

}
.checkIcon {
  // make twice as large
  height: 64px;
  width: 64px;

}
.list {
  padding: 4px 0;
  margin: 15px 0 20px 0;

  list-style: none;
  background-color: #F7FAFC;
  border-radius: 5px;
  text-align: left;
  padding-left: 3%;
  li {
    color: #012F49;
    font-size: $main-font-size;
    line-height: 20px;
    padding: 4px 0;
    svg {
      margin-right: 7px;
      vertical-align: left;
      height: 16px;
      width: 16px;
      padding: 3px;

      path {
        fill: #012F49;
      }
    }

    &.isValid {
      color: $success-green;

      svg {
        path {
          fill: $success-green;
        }
      }
    }

    + li {
      margin-top: 3px;
    }
  }
}
