@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.filesListItem {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.filesListItemDocuments {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
}

.downloadButton {
  border: none !important;
  padding: 0;
  font-size: $main-font-size;
  line-height: 20px;
  height: 20px;
  min-width: auto;

  &:hover {
    border: none;
  }
}

.templatesDivider {
  margin-top: 4px;
  height: 13px;
  width: 1px;
  background-color: $gallery;
}

