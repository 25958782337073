@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
  height: 24px;
  width: 24px;
  border-radius: $main-border-radius;
  padding: 4px;
  margin-right: 8px;

}

.label {
  span {
    font-size: $main-font-size;
    line-height: 20px;
    font-weight: 600;
    color: $black;



  }

  + .label {
    margin-left: 5px;
  }
}

.disabled{
  height: 15px !important;
  >span{ 
    font-size: 9px ;
    >svg{
    visibility:hidden}
  }}

  .icon {
    >svg {
      fill: $primary-color
    }
  }