@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

@mixin prediction-card {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 1.25;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  font-weight: 600;
  cursor: pointer;
}

.predictionCardPossible {
  @include prediction-card;
  color: $red-text;
  background-color: $red-background;
}

.predictionCardLikely {
  @include prediction-card;
  color: $yellow-text;
  background-color: $yellow-background;
}

.predictionCardVeryLikely {
  @include prediction-card;
  color: $green-text;
  background-color: $green-background;
}

.predictionCardDebtor {
  @include prediction-card;
  color: $slate-gray;
  background: $border-color;
}

.predictionCardEntity {
  @include prediction-card;
  color: $white;
  background-color: $primary-color;
}

.predictionCardIntermediary {
  @include prediction-card;
  color: $white;
  background-color: $slate-gray;
}

@mixin prediction-chip {
  font-size: $main-font-size;
  line-height: 1.25;
  border-radius: $small-border-radius;
  font-weight: 600;
}

.predictionChipEntity {
  @include prediction-chip;
  color: $white;
  background-color: $primary-color;

  svg {
    color: $white;
  }
}

.predictionChipIntermediary {
  @include prediction-chip;
  color: $white;
  background-color: $slate-gray;
}

.predictionCardNewValue {
  @include prediction-chip;
  background-color: $blue-background;
  color: $primary-color;
}

.keyLabel {
  font-size: $header3-font-size;
  line-height: 1.5;
  font-weight: 600;
  color: $secondary-color;
}
