@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";


.tableRow {
  height: 48px;
  background-color: $row-background-color;

}


.tableCell {
  &:hover {
    .sortableIcon {
      display: inline-flex;
    }
  }

  >div {
    border-radius: $main-border-radius;
    width: 100%;
  }



  .sortableIcon {
    margin-right: 10px;
    display: none;
  }

}

.tableCellWithoutBorder {
  &:before {
    display: none;
  }
}

.highlighted {
  background: $catskill-white;
}

.highlightedPrimary {
  background: $border-color !important;
}

.highlightedSecondary {
  background: $catskill-white !important;
}

.tableCellSorted {

  >div .filterTitleWrapper .sortableIcon {
    display: inline-flex !important;
  }

        >div .filterTitleWrapperHidden .sortableIcon {
          display: inline-flex !important;
        }
  .filterTitleWrapper {
    .filterTitle {
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

    .filterTitleWrapperHidden {
      .filterTitle {
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
}

.filterTitleWrapper {
  .filterTitle {
    font-size: 11px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
.filterTitleWrapperHidden {
  .filterTitle {
    font-size: 11px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}


.tableCellText {
  padding-left: 10px;
  text-align: left !important;

  >div {
    width: 100%;

    .filterTitleWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.tableTextRight {
  span {
    width: 100%;
  }
  .filterTitle {
    text-align: right !important;
  }
}

.filterTitle {
  &.filterTitleFullWidth {
    flex: 1;
  }
}


.alignLeft {
  text-align: left !important;
}

.alignRight {
  text-align: right !important;
}

.filterIcon {
  vertical-align: middle;
  background: transparent !important;
  padding: 0;

  &:hover {
    background: $thin-blue;
  }
}

.hasValue {
  position: relative;

  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 2px;
    right: 0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid $white;
    background: $sunshade;
  }
}

.filterIconBlue {
  fill: $active-blue;

  path {
    fill: $active-blue;
  }
}

.filterIconDropDownArrow {
  height: 22px;
  line-height: 6px;
  position: static;
}

.tooltipPopper {
  pointer-events: auto;
}


.tooltip {
  padding: 0;
  color: $gothic;
  background: $thin-blue;
  border-radius: $main-border-radius;
  box-shadow: 0px 2px 1px -1px $gothic;
}

.tooltipArrow {
  color: $white;

  &:before {
    border: 1px solid rgba($gothic, 0.5);
  }
}

.tooltipWrapper {
  padding: 3px;
  position: relative;
  border-radius: $main-border-radius;
  background: $white;
}

.tooltipCloseIcon {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: -14px;
  transform: rotate(180deg);
}

.amountFilter,
.dateFilter,
.dateTimeFilter,
.textFilter {
  :global {
    .MuiTextField-root {
      background: $input-background-color;

      >div {
        background: $input-background-color;
      }

      &:first-child {
        border-top-left-radius: $small-border-radius;
        border-bottom-left-radius: $small-border-radius;
      }

      &:last-child {
        border-top-right-radius: $small-border-radius;
        border-bottom-right-radius: $small-border-radius;
        background: $input-background-color;
      }

      +.MuiTextField-root {
        padding-left: 26px;

        &:before {
          position: absolute;
          left: 10px;
          display: inline-block;
          width: 6px;
          line-height: 24px;
          padding: 0;
          color: $gothic;
          content: "—";
          text-align: center;
        }
      }

      .MuiFormHelperText-root {
        position: absolute;
        top: 30px;
        margin: 0;
        min-width: 90px;
        font-size: 10px;
        white-space: break-spaces;
      }
    }
  }
}

.amountFilter {
  :global {
    .MuiTextField-root {
      >div {
        max-width: 68px;
      }

      &:first-child {
        width: 70px;
      }

      input {
        height: 24px;
        padding: 2px 5px;
        font-size: $main-font-size;
        line-height: 20px;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
}


.dateFilter,
.dateTimeFilter {
  :global {
    .MuiTextField-root {
      >div {
        max-width: 90px;
        height: 24px;
      }

      .MuiInputAdornment-positionEnd {
        margin-left: 5px;
      }
    }
  }
}

.dateTimeFilter {
  :global {
    .MuiTextField-root {
      >div {
        padding: 1px 3px;
        max-width: 124px;

        .MuiInputAdornment-positionEnd {
          margin-left: 3px;
          width: 18px;
        }
      }
    }
  }
}

.textFilter {
  :global {
    .MuiTextField-root {
      max-width: 160px;

      input {
        height: 24px;
        padding: 2px 5px;
        font-size: $main-font-size;
        line-height: 20px;
        box-sizing: border-box;
        text-align: left;
      }
    }
  }
}

.filter {
  display: flex;
}

.autocompleteFilter {
  :global {
    .MuiSelect-select {
      min-width: 153px;
      padding: 2px 30px 2px 5px;
      border-radius: $small-border-radius;
    }

    .MuiSelect-icon {
      top: 2px;
      height: 20px;
      width: 20px;
      padding: 3px 6px;
    }

    .MuiAutocomplete-root {
      display: inline-flex;
      vertical-align: top;
      min-width: 153px;
      background: $input-background-color;

      .MuiAutocomplete-inputRoot {
        padding-top: 2px !important;
        padding-bottom: 2px !important;

        &:before {
          top: 4px
        }

        input {
          padding: 0 !important;
        }
      }
    }
  }
}

.filterCell {
  text-align: left;
  border: none !important
}

.tableNumberCell {
  .filterTitleWrapper {
      display: flex;
      flex-direction: row-reverse;

      .filterTitle {
        margin-right: 10px;
      }

      .filterActionContainer {
        display: flex;
        flex-direction: row-reverse;

        .filterAction {
          margin-right: 10px;
        }
      }

    }



        .filterTitleWrapperHidden {
          display: flex;
          flex-direction: row-reverse;

          .filterTitle {
            margin-right: 10px;
          }

          .filterActionContainerHidden {
            flex-direction: row-reverse;

            .filterAction {
              margin-right: 10px;
            }


          }

          .filterActionContainerHidden:hover {
            display: inline-flex;
            align-items: center;
          }
        }

}



.filterActionContainer {
  display: flex;
  align-items: center;
}

.filterActionContainerHidden {
  display: none;


}

.filterTitleWrapperHidden:hover {
  .filterActionContainerHidden {
    display: inline-flex;
    align-items: center;
  }
}
.clickable {
  cursor: pointer;
}

.resizing {
  pointer-events: none;
}