@import '../../../assets/styles/colors.scss';


.breadcrumbs{
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 21px;
    z-index: 1;
    // align items center within the list item
    li{
        display: flex;
        align-items: center;
        height: 21px;
        a{
            display: flex;
        align-items: center;
        height: 21px;
        }
    }
}

.breadcrumbLink{
    color: $secondary-color;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.noMarginLeft{
    margin-left: 0;
}

