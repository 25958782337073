@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.drawer {
  margin-top: 60px;
  flex-shrink: 0;
  box-sizing: border-box;
  transition: width 0.2s linear;
}

.expanded {
  transition: width 0.2s linear;
  transition-duration: 1s;
  width: $notesDrawerWidth;
  // z-index: 1101;
  @media only screen and (min-width: 1800px) {
    width: $bigNotesDrawerWidth;
  }
}

.drawerPaper {
  width: $notesDrawerWidth;
  overflow-y: visible !important;
  background: $white;
  transition: width 0.2s linear;
  margin-top: $header-height;
  height: calc(100vh - $header-height);
  @media only screen and (min-width: 1800px) {
    width: $bigNotesDrawerWidth;
  }
}

.tabPanel {
  padding: 0;
}

.tabName {
  font-weight: 600;
  font-size: 14px;
  text-transform: none !important;
  text-align: left;
}

.flagCount {
  background-color: $red-text;
  color: $white;
  border-radius: 50%;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabNameContainer {
  padding: 5px 16px;
}
