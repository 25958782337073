@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables';

.modalClose {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: -20px;
  font-size: 24px;
  z-index: 6000;
  height: 40px;
  width: 40px;

}


  .modalBody {
    font-size: $main-font-size;
    line-height: 20px;
    padding: 6px 0px;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    height: 100%;
  }
  


  .iframe{
    border: 0px;
  }

.drawer {
  margin-top: 60px;
  flex-shrink: 0;
  box-sizing: border-box;
  transition: width 0.2s linear;
}



.expanded {
  transition: width 0.2s linear;
  transition-duration: 1s;
  width: $box-viewer-width;
 
}

.drawerPaper {
  width: $box-viewer-width;
  overflow-y: visible !important;
  background: $white;
  transition: width 0.2s linear;
  margin-top: $header-height;
  height: calc(100vh - $header-height);
 
}



.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customDrawer {
  position: fixed;
  top: $header-height;
  right: -100%;
  height: calc(100vh - $header-height);
  width: $box-viewer-width;
  background: $white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease;
  z-index: 1300;
  overflow: visible;

  > div {
    height: 100%;
    overflow-y: auto;
  }

  &.expanded {
    right: 0;
  }

}

.isAdminLoggedAsClient {
  margin-top: $login-as-client-container-height;
  height: calc(100vh - $header-height - $login-as-client-container-height);
}