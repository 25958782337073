@import "../../assets/styles/variables";
@import "../../assets/styles/colors";

.overdueReportingBanner {
  display: flex;
  height: 60px;
  margin: 10px 0;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  width: 100%;
  background: $yellow-background;
  border-radius: $main-border-radius;
  color: $secondary-color;
}

.overdueContent {
  flex: 1;
  font-size: 14px;
  line-height: 21px;
}

.title {
  font-weight: 600;
}

.link {
  color: $secondary-color !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: right !important;
  text-decoration-line: underline !important;
  cursor: pointer;

  &:hover {
    color: $black !important;
  }
}
