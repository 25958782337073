@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.chartContainer {
  height: calc(100vh - 500px);
  min-height: 400px;

  &.chartContainerFullScreen {
    height: calc(95vh - 115px);
  }
}

.chartIcon {
  height: 20px;
  width: 20px;
}
