@import '../../assets/styles/colors.scss';



.container {
    padding-top: 100px;
    border-right: 1px solid $border-color;
    height: 100%;

}
.icon {
    path, ellipse, circle {
    stroke: $icon-color;
    }
    rect {
        fill: $border-color;

    }
}

.header {
    margin-left: 25px;
    margin-bottom: 25px;
}

.primary{
    font-weight: 800;
    font-size: 14px;
    color: $secondary-color;
}

.secondary{
    font-weight: 500;
    font-size: 12px;
    color: $slate-gray;
}

.listItem{
    height: 90px;
    cursor: pointer;

}

.active {
    background-color: #FAFAFA;
    path, ellipse, circle {
        stroke: $primary-color;
        }
        rect {
            fill: $white;

        }
    border-left: 3px solid $primary-color;
}
