@import "../../assets/styles/colors";

.forgotPassword {
  color: $primary-color;
  float: center;
  text-align: center;
  align-content: flex;
  text-decoration: none;
  font-size: 16px;
  margin-top: 24px;
  font-weight: 500;
}
