@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.title {
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;

  /* Text 1 */

  color: $dark-slate-blue;
  margin-left: $drawer-collapsed-width + $drawer-margin + 50px;
}
.titleExpanded {
  margin-left: $drawer-expanded-width + $drawer-margin + 50px;
  transition: all 0.2s linear;
}
.searchBar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notesContainer {
  margin-top: 60px;
}

.toggleNotes {
  cursor: pointer;
  position: absolute;
  height: 24px;
  top: calc(($header-height - 24px) / 2);
  right: 16px;
}

.user {
  float: right;
  width: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-right: 20px;
  gap: 10px;
  cursor: pointer;
}

.userName {
  font-size: 14px;
  font-weight: 600;
  color: $secondary-color;
}

.appBar {
  background-color: $white;
  height: $header-height;
  box-shadow: none;
  border-bottom: 1px solid $border-color;
}

.loggedAsClientAppBar {
  top: $login-as-client-container-height;
}

.appBarContainer {
  height: 100%;
}

.userNotesExpanded {
  transition: all 0.2s linear;

  margin-right: $notesDrawerWidth + 10px;
}

.prospectTopBar {
  padding-left: 175px;
}

.prospectTopBarLogo {
  max-width: 128px;
}

.prospectTopBarDivider {
  height: 40px;
  margin: 0 30px;
  border-left: 1px solid $athens-gray;
}

.prospectTopBarMenu {
  display: flex;
  gap: 40px
}

.prospectTopBarMenuItem {
  color: $slate-gray;
  font-size: 14px;
  font-weight: 700;
}

.prospectTopBarMenuItemActive {
  color: $primary-color;
}

.rotate {
  transform: rotate(180deg);
}

.actionsMenu {
  padding: 10px;
  border-radius: $small-border-radius;
  overflow: visible;

  &:before {
    background: transparent;
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: $modal-background-color;
    content: "";
    display: block;
    position: absolute;
    left: 12px;
    top: -16px;
  }
}

.actionsMenuItem {
  min-width: 150px;
  padding: 8px;
  border-radius: $small-border-radius;
  background: none !important;

  + .actionsMenuItem {
    margin-top: 4px;
  }

  &:hover {
    background: $gray-background-color !important;
  }
}

.actionsMenuItemLink {
  color: $black;
  text-decoration: none;
}
