@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }

  tr {
    td, th {
      &:nth-child(1) {
        width: 15% !important;
      }
      &:nth-child(2) {
        width: 20% !important;
      }
    }
  }

  tbody {
    position: relative;

    .parentRow {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}


.scrollableDiv {
  overflow-y: scroll;
  max-height: 240px !important;
}
