@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.table {
  width: 100%;

  table tbody {
    max-height: 60vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 75vh !important;
    }
  }

  tr {
    td,
    th {

      &:nth-child(7) {
        width: 75px;
      }
      
    }
    td {
      &:nth-child(7) {
        padding-left: 30px;
      }
    }
  }
}

.linkBtn {
  cursor: pointer;
  vertical-align: middle;

  text-decoration: none;
  color: inherit;

  + .linkBtn {
    margin-left: 5px;
  }
}

.reportingStatus {
  display: inline-block;
  font-size: $main-font-size;
  padding: 3px 8px;
  border-radius: $small-border-radius;
  color: $white;
  cursor: default;
  text-align: center;
}

.iconMenu {
  padding: 5px 10px;
  box-shadow: 0 0 1px 0 rgba($black, 0.2);
  border-radius: $main-border-radius;
}

.iconMenuItem {
  min-width: 80px;
  padding: 10px;
  font-size: 14px;
  line-height: 21px;
}

.link {
  cursor: pointer;
}
