@import '../../assets/styles/colors';

.wrapper {
  margin: 25px auto 0px;
  max-width: 524px;

}

.heading {
  font-weight: 800;
  font-size: 20px !important;
  line-height: 25px;
  color: $dark-blue;
  text-align: left;
}

.button {
  margin-top: 5px;
  height: 48px;
  width: 100%;
}

.iconVisibility {
  height: 28px;

}

.iconButtonVisibility {
  color: $p-color;
  height: 28px;
  margin-right: 3px;
}