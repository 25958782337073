.textArea {
  :global {
    .MuiInputBase-root {
      height: auto;

      textarea {
        height: auto;
      }
    }
  }
}

.infoItems {
  padding-bottom: 20px;
}
