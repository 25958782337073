@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tbody {
    max-height: unset !important;
    height: 60vh;

    @media only screen and (max-height: 800px) {
      height: 78vh;
    }
  }

  tr {
    td, th {
      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(2) {
        width: 10%;
      }

      &:nth-child(3) {
        width: 16%;
      }

      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {
        width: 10%;
      }

      &:nth-child(5) {
        width: 12%;
      }

      &:nth-child(6) {
        width: 12%;
      }
    }
  }
}

.exportButton {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 15px;
  color: $secondary-color;
  font-size: $main-font-size;
  line-height: 20px;
  cursor: pointer;
}

.exportButtonDisabled {
  cursor: not-allowed;
  opacity: .6;
}
