@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.table {
  overflow: auto;

  tr {
    td, th {
      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3), &:nth-child(4) {
        width: 25%;
      }
    }
  }
}

.fullScreenModal {
  overflow-y: auto;

  > div {
    padding: 20px;
  }
}
