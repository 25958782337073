@import "../../assets/styles/colors";

.arrow {
  width: 15px;
  height: 15px;
  margin-right: 4px;
  vertical-align: middle;

  &.down {
    rotate: 90deg;
    color: $red-text;
  }
  &.up {
    rotate: -90deg;
    color: $green-text;
  }
}
