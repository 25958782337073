
@import '../../../assets/styles/colors';

.modalBody{
  width: 350px;
  height: 350px;
}

.button {
  width: 100%;
  height: 40px;
}

.exit{
  font-size: 14px;
line-height: 18px;

display: flex;
align-items: center;
text-align: center;
text-decoration-line: underline;


color: $primary-color;
cursor: pointer;
}

.descriptionTitle{
  color: $slate-gray;
  font-size: 14px;
  padding-bottom: 10px;


}
