@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.container {
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 14px;
  display: flex;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.subTitle {
  color: $black;
  font-size: 12px;
}

.subTitleMonth {
  color: $secondary-color;
  font-weight: 700;
}

.subTitleMonthLoader {
  display: inline-block;
}

.textField {
  width: 120px;

  @include text-field-with-top-label;
}

.selectFieldWrapper {
  width: 120px;
}

.selectField {
  width: 120px;
  height: 36px;
}

.inputContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.data {
  font-size: 12px;
  line-height: 15px;
}

.row {
  padding: 12px;
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: $white;
  }

  &:nth-child(odd) {
    background-color: $row-background-color;
  }
}
