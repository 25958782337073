@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formContent {
  padding-top: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  max-width: 670px;

  > div {
    flex: 0 calc(50% - 12px);

    &.fullWidthRow {
      flex: 0 100% !important;
    }
  }
}

.inputLabel {
  text-align: left;
  color: $secondary-color;
  letter-spacing: 0.2px;
  font-weight: bolder;
}

.inputField {
  :global {
    .MuiInputBase-input {
      font-weight: normal;
      text-align: left;
    }
  }
}

.autocompleteField {
  :global {
    .MuiAutocomplete-inputRoot {
      min-height: 48px;
      height: 48px;
      border: 1px solid $input-border-color;
      border-radius: 5px;
      padding: 8px 36px 8px 8px !important;

      .MuiInputBase-input {
        font-size: 14px;
      }

      .MuiAutocomplete-endAdornment {
        right: 5px;
      }

      &:before {
        display: none;
      }
    }
  }
}

.formHeading {
  text-align: left;
  font-size: 20px;
  line-height: 24px;
  font-weight: 800;
}

.withTopMargin {
  margin-top: 12px;
}

.erpSelectorWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.erpSelector {
  padding: 9px 23px;
  background: $border-color;
  border-radius: 5px;
  font-size: 14px;
  color: $secondary-color;
  cursor: pointer;
}

.erpSelectorSelected {
  background: $primary-color;
  color: $white;
}
