@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  border: 1px solid $wild-sand;
  border-radius: $small-border-radius;
  cursor: pointer;
  overflow: hidden;

  &.itemExpanded {
    border-color: $primary-color;
  }
}

.itemHeading {
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $gray-background-color;
  border-radius: $small-border-radius $small-border-radius 0 0;
  min-height: 37px;
}

.itemTags {
  display: flex;
}

.itemTag {
  display: flex;
  padding: 4px 8px;
  background: $white;
  color: $secondary-color;
  font-size: 10px;
  line-height: 13px;
  font-weight: 700;
  border-radius: $small-border-radius;
}

.itemActions {
  display: flex;
  gap: 4px;
  align-items: center;
}

.itemTitle {
  padding: 0 15px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  color: $secondary-color;
}

.itemMenuIcon {
  width: 24px;
  height: 24px;
}

.itemImpactToAvailability {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  border-radius: $small-border-radius;

  &.itemImpactToAvailabilityPositive {
    color: $green-text;
    background: $green-background;
  }

  &.itemImpactToAvailabilityNegative {
    color: $red-text;
    background: $red-background;
  }
}

.itemData {
  padding: 0 15px 8px;
  color: $tundora;
  font-size: 12px;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.itemBottom {
  padding: 0 15px 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
  line-height: 12px;
  color: $p-color;
}

.itemExpandRepliesContainer {
  padding-bottom: 5px;
  text-align: center;
  font-weight: 700;
}

.itemExpandRepliesText {
  color: $primary-color;
  cursor: pointer;
}

.itemReplies {
  padding-bottom: 5px;
  flex: 1;
  max-height: 30vh !important;
  overflow: auto;
}
