@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.iconWrapper {
  position: fixed;
  right: 30px;
  bottom: 60px;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary-color;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
}

.modalRoot {
  padding: 14px 10px;
  display: flex;
  width: 742px;
  min-height: 70vh;
  max-height: calc(100vh - 120px);
}

.modalBody {
  text-align: left;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 60px;

  h2 {
    padding-left: 10px;
  }

  span {
    margin-left: 10px;
    color: $gothic;
    font-size: $header2-font-size;
    font-weight: bold;
  }

  > div {
    display: flex;
    align-items: center;
  }
}

.modalTitleIcon {
  width: 14px;
  height: 14px;

  path {
    fill: $gothic;
  }
}

.modalContent {
  padding: 10px;
  flex: 1 1 auto;
  max-height: 100%;
  overflow: hidden;
}
