@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.card {
  :global {
    .MuiCardContent-root {
      > div {
        padding: 10px 0;
      }
    }
  }
}

.backButton {
  margin: 10px 15px;
  width: 50px;
  height: 25px;

  > a {
    display: flex;
    align-items: center;
    font-size: $main-font-size;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    color: $primary-color;
  }
}

.reportingSummaryItem {
  padding-top: 0 !important;
}

.navigationBar {
  @include navigation-bar;
  z-index: 3;

  &.navigationBarWithNotes {
    width: calc(100% - #{$drawer-collapsed-width} - #{$notesDrawerWidth});
    left: $drawer-collapsed-width;

    @media only screen and (min-width: 1800px) {
      width: calc(100% - #{$drawer-collapsed-width} - #{$bigNotesDrawerWidth});
    }
  }

  &.navigationBarWithSidebar {
    width: calc(100% - #{$drawer-expanded-width});
    left: $drawer-expanded-width;

    &.navigationBarWithNotes {
      width: calc(100% - #{$drawer-expanded-width} - #{$notesDrawerWidth});
      left: $drawer-expanded-width;

      @media only screen and (min-width: 1800px) {
        width: calc(100% - #{$drawer-expanded-width} - #{$bigNotesDrawerWidth});
      }
    }
  }

  &.navigationBarModal {
    width: 95%;
    left: 2.5%;
    z-index: 1301;
  }
}

.navigationBarLabel {
  @include navigation-bar-label;
}

.reportingTables {
  margin-bottom: 100px;
}

.container {
  height: calc(100vh - $header-height);
  position: relative;
}

.navigationWithAdditionalFiles {
  margin-bottom: 75px;
}
