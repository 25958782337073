

.stickyFooter {
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
  height: 48px !important;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 10;
  >td {
   font-size: 14px !important;
  }
}
.activeItemsCount {
  font-size: 16px !important;
  font-weight: 700 !important;
}

