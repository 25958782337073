@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.icon {
  position: absolute;
  top:calc(50% - 5px);
  background-color: transparent;
  border-radius: $main-border-radius;
  pointer-events: none;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 8px;

  svg {
    width: 10px;
    height: 10px;
    position: static;
  }
}
