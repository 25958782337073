@import "../../../assets/styles/mixins";

.selectField {
    width: 150px;
    height: 36px;
  
    @include select-field-with-top-label;

  }


  .chartContainer {
    position: relative;
    height: calc(100vh - 500px);
    min-height: 400px;;
    max-height: 750px;
    width: 100vw;
  }

  .averageLabel{
    font-size: 12px;
    color: $black;
    font-weight: 500;
  }

  .chartContainerLoanCommittee {
    position: relative;
    min-height: 400px;
    height: calc(50vh);
    max-height: 550px;
    width: 100vw;
  }