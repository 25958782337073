@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.header {
  font-size: 18px;
  line-height: 1.25;
  font-weight: 800;
}

.withBorder {
  border-bottom: 1px solid $button-border-color;
}

.label {
  margin-bottom: 12px;
  color: $slate-gray;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
}

.value {
  color: $secondary-color;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}

.valuesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.valuesItem {
  padding: 9px 24px;
  font-size: 14px;
  line-height: 1.25;
  color: $secondary-color;
  background: $border-color;
  border-radius: $small-border-radius;
}

.documentTable {
  tr {
    height: 50px;

    th, td {
      &:nth-child(1) {
        padding-left: 20px !important;
      }

      &:nth-child(2) {
        padding-left: 45px !important;
      }
    }
  }
}

.documentTableItemTitle {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.documentTableItemCount {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: $success-green;
  border-radius: 50%;
  text-align: center;
  font-size: $main-font-size;
  line-height: 20px;
  color: $white;
  font-weight: 700;
}

.container {
  height: calc(100vh - $header-height);
  position: relative;
}

.navigation {
  position: absolute;
  bottom: 0;
  right: 0;
}
