@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
  display: inline-flex;
  height: 16px;
  width: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent !important;

  &:hover {
    background-color: $thin-blue;
  }
}

.sortIcon {
  width: 12px;
  height: 12px;
}

.sortIconUp {
  transform: rotate(180deg);
}

.sortIconDisable {
  transform: rotate(270deg);
}
