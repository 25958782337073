@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.modalWrapper {
  right: calc($notesDrawerWidth + 10px);
  z-index: 1199;

  @media only screen and (min-width: 1800px) {
    right: calc($bigNotesDrawerWidth + 10px);
  }
}

.modal {
  position: absolute;
  background: $light-gray;
  padding: 0;
  top: $header-height;
  bottom: 0;
  left: unset;
  right: -$notesDrawerWidth;
  outline: 0;
  width: 800px;
  height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;
  box-shadow: -6px 0px 20px 0px rgba($black, 0.1);
  transition: right 300ms linear !important;

  @media only screen and (min-width: 1800px) {
    right: -$bigNotesDrawerWidth;
  }

  &.modalOpened {
    right: 0;
  }
}

.modalBackdrop {
  background: none;
  right: calc($notesDrawerWidth + 10px);

  @media only screen and (min-width: 1800px) {
    right: calc($bigNotesDrawerWidth + 10px);
  }
}

.modalHeader {
  padding: 14px 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: $wild-sand;
}

.modalClose {
  cursor: pointer;
  width: 27px;
  height: 27px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  flex: 1;
}
