@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.cardContainer {
  margin-top: 24px;
}

.table {
  table tbody {
    max-height: 60vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 75vh !important;
    }
  }
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 10%;
      }
    }
  }
}

.value {
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: 600;
}

.tooltip {
  background-color: white;
  color: black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.tooltipArrow {
  color: white;
}

.tableCell {
  cursor: pointer;
}

.supplementalLabel {
  text-align: left;
  font-size: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-top: 5px;
}

.ratingTag {
  display: inline-block;
  font-size: 10px;
  line-height: 14px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  box-sizing: border-box;
}

.footer {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 20px;
}

.keyTitle {
  color: var(--Black, #1a202c);
  font-size: 14px;
  font-weight: 600;
}

.keyItem {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
}

.keyGreen {
  background: rgba(102, 180, 133, 0.25);
  color: var(--Action-Sucess, #66b485);
}

.keyYellow {
  background: $yellow-tag-background;
  color: $yellow-tag;
}

.keyRed {
  background: $red-tag-background;
  color: $red-tag;
}

.selectField {
  width: 105px;
  height: 36px;
}