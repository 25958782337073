@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.small {
  .itemRoot {
    padding: 9px;
    height: 36px;
    width: 36px;
    font-size: 14px;
    line-height: 18px;
  }
}

.itemRoot {
  border: none !important;
  color: $secondary-color;
  background: $border-color !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 8px;
  padding: 12px;
  height: 44px;
  width: 44px;

  &.itemFocusVisible, &:hover {
    background: $light-blue-gray !important;
  }

  &.itemSelected {
    color: $white;
    background: $primary-color !important;
    pointer-events: none;
    &:hover {
      background: $primary-color-hovered !important;
    }
  }

  &.itemDisabled {
    opacity: 0.2;
  }
}
