@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.tableCellNumber {
  text-align: end !important;

  > div {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .filterTitleWrapper {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  }

  > div > button {
    position: absolute;
    top: 5px;
    left: 10px;
  }

  &:hover {
    > div {
      .filterTitleWrapper {
        width: calc(100% - 25px);

        .filterTitle {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.dateFilter {
  :global {
    .MuiTextField-root {
      > div {
        max-width: 110px;
      }

      .MuiFormHelperText-root {
        position: absolute;
        top: 36px;
        margin: 0;
        font-size: 10px;
        white-space: break-spaces;
      }

      .MuiInputAdornment-positionEnd {
        margin-left: 5px;
      }
    }
  }
}

.table {
  tr {

    td,
    th {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 25%;
      }

      &:nth-child(4) {
        width: 20%;
      }
    }
  }

  tbody {
    max-height: calc(100vh - 246px) !important;

    tr {
      background: $white;

      background: $white;

      &.predictedRow {
        background: rgba($gold, .25);
      }

      &.activeRow {
        background: $dropdown-selected-color;
      }

      td {
        :global {
          .MuiFormControl-root {
            > div {
              width: 100% !important;
              max-width: none !important;;
            }
          }
        }
      }
    }
  }
}

.predictionCard {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $main-border-radius;
  color: $white;
  background-color: $chip-blue;
  cursor: pointer;
}

.actionBtn {
  cursor: pointer;
}

.itemsCount {
  flex: 1;
  margin-top: 10px;
  padding: 6px;
  font-size: $main-font-size;
  line-height: 20px;
  text-align: left;
  color: $dark-blue;
}

.backButton {
  margin: 10px 15px;
  width: 50px;

  > a {
    display: flex;
    align-items: center;
    font-size: $main-font-size;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    color: $primary-color;
  }
}

.mappingActiveToolbar {
  order: 1;

  > div {
    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 25%;

      :global {
        .MuiFormControl-root {
          > div {
            width: 100% !important;
            max-width: none !important;;
          }
        }
      }
    }

    &:nth-child(4) {
      width: 20%;
    }
  }
}

.linkIcon {
  margin-left: 7px;
  vertical-align: middle;
  height: 15px;
  width: 15px;

  path {
    stroke: $primary-color;
  }
}

.fullScreenModal {
  table {
    tbody {
      // Padding + card header + table header + table footer
      max-height: calc(95vh - 195px) !important;
    }
  }
}

.modal {
  height: 80vh;
  padding: 23px 0px 0px 0px !important;
  min-width: 1000px;
  > div > svg {
    margin-right: 0px !important;
  }
}

.aliasesTable {
  table {
    tbody {
      max-height: calc(80vh - 220px);
    }
  }
}

.arSummaryTable {
  table {
    tbody {
      max-height: calc(100vh - 555px) !important;
    }
  }
}

.container {
  height: calc(100vh - $header-height);
  position: relative;
}

.navigation {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.textField {
  width: 80px;

  @include text-field-with-top-label;
}
