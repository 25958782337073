@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables";



.dashboardHeaderWrapper {
    background: $off-white;
    height: 140px;
    width: 100%;
    border-radius: $main-border-radius;
    margin-bottom: 10px;
    color: $black !important;
    padding: 10px 15px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .dashboardCard {
    background: $white;
    border-radius: 8px;
    height: 110px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .cardHeader {
    font-weight: 500;
    font-size: 14px;
    color: $transparent-gray !important;
  }
  
  .cardValue {
    text-align: right;
    font-weight: 700;
    font-size: 18px;
  }
  

  .positivePct {
    float: right;
  
    color: $success-green;
    font-weight: 600;
    font-size: 16px;
    padding-left: 10px;
  }
  
  .negativePct {
    font-weight: 600;
    font-size: 16px;
    color: $red;
    padding-left: 10px;
    float: right;
  }
  

.cardFooter {
  color: $transparent-gray;
  text-align: right;
  font-weight: 600;
  font-size: 14px;
}