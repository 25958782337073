@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.select {
  font-size: $main-font-size;
  line-height: 20px;
  color: $input-color;
  border-radius: $small-border-radius;

  &:hover {
    .clearIndicator {
      display: inline-flex;
    }

    .popupIndicator {
      display: none;
    }
  }

  &.focused {
    .clearIndicator {
      display: inline-flex;
    }

    .popupIndicator {
      display: none !important;
    }


    .inputRoot {
      padding: 0 8px 0 0px !important;
    }

    .tag {
      max-width: calc(50% - 32px) !important;
    }

    .inputRoot {
      flex-wrap: wrap !important;
    }

    > div {
      height: auto;

      .inputRoot {
        height: auto;
      }
    }
  }

  &.hideTags {
    .tag {
      pointer-events: none;
      color: $black;
      background: none;
      font-weight: 400;
    }

    &.focused {
      .tag {
        display: none;
      }
    }
  }
}

.withBorderSelect {
  border: 1px solid $input-border-color;
}

.inputRoot {
  padding: 0 36px 0 0px !important;

  background-color: $input-background-color;
  border-radius: $small-border-radius !important;
  flex-wrap: nowrap !important;
  gap: 4px;
  overflow: hidden;

  &::after {
    display: none;
  }

  &:before {
    display: none;
  }
}

.input {
  padding: 4px 0 4px 4px !important;
  line-height: 20px;
  height: 20px;
  cursor: text;
  font-size: $main-font-size;
  text-transform: capitalize;
}

.tag {
  margin: 0 !important;
  padding: 2px 5px;
  color: $slate-gray;
  background: $past-gray-background;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  height: 22px;
  border-radius: $small-border-radius;
  min-width: 81px;
  max-width: calc(50% - 18px) !important;
  z-index: 1;

  > span {
    padding: 0 4px;
  }

  > svg {
    font-size: 14px !important;
    margin: 0 !important;
  }

  + .input {
    &::placeholder {
      color: transparent;
    }
  }
}

.placeholder {
  color: $success-green !important;
  font-size: 14px;
  font-weight: 400;
}

.paper {
  border-radius: $main-border-radius;
}

.listbox {
  padding: 14px 10px;
}

.option {
  padding: 10px !important;
  background: none !important;

  &[aria-selected='true'],
  &:hover,
  &[data-focus='true'] {
    background: $border-color !important;
  }

  + .option {
    margin-top: 10px;
  }
}

.optionLabel {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: $main-font-size;
  line-height: 21px;
  border-radius: $main-border-radius;
  color: $secondary-color;
  min-height: 21px;
}

.optionIconLabel {
  margin-left: 10px;
}

.endAdornment {
  transform: translateY(-50%);
  top: 50%;
}

.clearIndicator {
  margin: 0;
  color: $p-color;
  display: none;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: transparent;
  }
}

.popupIndicatorOpen {
  transform: none;

  svg {
    transform: rotate(180deg);
  }
}

.popupIndicator {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: none;
  }
}

.arrow {
  position: static !important;
  display: flex;
  background: transparent;

  svg {
    path {
      fill: $p-color;
    }
  }
}

.bigArrow {
  position: static !important;
  display: flex;
  background: transparent;

  margin-right: 12px;

  svg {
    path {
      fill: $p-color;
    }
  }
}

.big {
  height: 48px;

  font-size: 14px;
  font-weight: 400;
  background-color: white;

  :global {
    .MuiInput-root {
      height: 48px;
      border-radius: $small-border-radius;
      border: 1px solid $input-border-color;
    }

    .Mui-focused {
      height: 48px !important;
    }

    .MuiInputBase-input {
      padding: 8px 18px;
      font-size: 14px;
      font-weight: 200;
      height: 28px !important;
      color: #666666;
      &:hover {
        font-weight: 400;
        color: $black !important;
      }
      &:focus {
        font-weight: 400;
        color: $black !important;
      }
    }

    .MuiSelect-icon {
      right: 8px;
    }
  }
}

.allTags {
  &.focused {
    .inputRoot {
      padding: 10px !important;
    }
  }

  .inputRoot {
    flex-wrap: wrap !important;
  }

  > div {
    height: auto;
    border: 0.5px $input-border-color solid;
    border-radius: $small-border-radius;

    .inputRoot {
      height: auto;
      padding: 10px !important;

      > div {
        margin: 2px !important;
      }
    }
  }
}

:global {
  .MuiInputBase-root {
    margin-top: 0 !important;
  }
}

.withTopLabel {
  @include select-field-with-top-label;
  :global {
    .MuiInputBase-root {
      overflow: visible !important;
      height: 36px !important;
    }
    .MuiFormControl-root {
      height: 36px;
    }
  }
  label {
    display: none;
  }
}

.withTopLabelFilter {
  @include select-field-with-top-label;
  text-transform: capitalize;

  :global {
    .MuiInputBase-root {
      overflow: visible !important;
    }
  }

  &.borderSelected {
    fieldset {
      border-color: $black !important;
    }
  }
}

.borderStandard {
  :global {
    .MuiInputBase-root {
      overflow: visible;
    }
    .MuiInputBase-root:hover {
      fieldset {
        border-color: $input-border-color;
      }
    }
  }
  fieldset {
    span {
      display: none;
    }
  }
}

.filterLabel {
  fieldset {
    border-color: $input-border-color;
  }
  label {
    display: none;
  }
}

.textContainerFilter {
  height: 48px;

  background-color: white;

  :global {
    .MuiInputBase-root {
      height: 48px;

      &:hover .MuiInputBase-input {
        font-size: 14px;
        font-weight: 600;
        color: $black;
      }

      &:focus .MuiInputBase-input {
        font-size: 14px;
        font-weight: 600;
        color: $transparent-gray;
      }

      &:focus:hover .MuiInputBase-input {
        color: $transparent-gray !important;
      }

      &:hover > svg:nth-of-type(1) {
        > path {
          fill: $black;
        }
      }
    }

    .Mui-focused {
      height: 48px !important;
    }

    .MuiInputBase-input {
      padding: 8px 18px;
      font-size: 14px;
      font-weight: 600;
      height: 28px !important;
      color: $transparent-gray;
      &::placeholder {
        opacity: 1;
      }
    }

    .MuiSelect-icon {
      right: 8px;
    }
  }
}

.filterBox {
  position: relative;
}
