@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tbody {
    max-height: 75vh !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 35%;
      }
    }
  }
}

.linkIcon {
  margin-top: 3px;
  margin-left: 7px;
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

.salesforceLinkIcon {
  margin-top: 3px;
  margin-left: 7px;
  vertical-align: middle;
  height: 25px;
  width: 25px;
}

.status {
  padding: 4px 8px;
  border-radius: $small-border-radius;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;

  &.statusNotStarted {
    color: $primary-color;
    background: rgba($primary-color, 0.15);
  }

  &.statusInReview {
    color: $corn;
    background: rgba($corn, 0.15);
  }

  &.statusActionRequired {
    color: $red-text;
    background: $red-background;
  }

  &.statusCompleted {
    color: $success-green;
    background: $light-green;
  }
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer + new filters
      max-height: calc(95vh - 267px) !important;
    }
  }
}

.refreshButton {
  margin-left: auto;
  margin-right: 15px;
}

.tooltip {
  background-color: $primary-color;
}
