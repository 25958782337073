@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.card {
  height: 100%;
}

.managerSelect {
  border: 1px solid $input-border-color;
  border-radius: 5px;
}

.iconRoot {
  width: 100%;
  height: 100%;
}

.sendNotificationButton {
  padding: 0;
  background-color: transparent;
  color: $active-blue;
  text-align: left;
  font-weight: 600;
  font-size: 12px;

  &:hover,
  &:focus {
    color: $navy-blue;
    background-color: transparent;
  }

  span {
    display: block;
  }
}

.table {
  tr {
    td,
    th {
      &:nth-child(4) {
        width: 150px;
      }

      &:nth-child(5) {
        width: 70px;
      }
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.editModal {
  width: 406px;
  background: $modal-background-color;
}

.editModalFooter {
  justify-content: space-between;
  margin-top: 8px;
  padding-bottom: 0 !important;
}

.editModalTitle {
  margin-bottom: 22px;
}

.active {
  font-size: 12px;
  background-color: $light-green;
  color: $success-green;
  border-radius: $small-border-radius;
  padding: 3px 7px;
  line-height: 14px;
  display: inline-block;
  font-weight: 600;
}

.iconRoot {
  vertical-align: middle;
}

.inviteButton {
  padding: 0;
  background-color: transparent;
  color: $active-blue;
  text-align: left;
  font-weight: 600;
  font-size: 12px;

  &:hover,
  &:focus {
    color: $navy-blue;
    background-color: transparent;
  }

  span {
    display: block;
  }
}

.table {
  tr {
    td,
    th {
      &:nth-child(4) {
        width: 150px;
      }

      &:nth-child(6) {
        width: 90px;
      }

      &:nth-child(7) {
        width: 60px;
      }
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  td {
    :global {
      .MuiCheckbox-root {
        padding: 0;
      }
    }
  }
}

.editModal {
  width: 406px;
  background: $modal-background-color;
}

.editModalFooter {
  justify-content: space-between;
  margin-top: 8px;
  padding-bottom: 0 !important;
}

.editModalTitle {
  margin-bottom: 22px;
}

.notAuthorized {
  color: $red-text !important;
  font-weight: 500;
  font-size: 12px;
}

.MuiPopover-paper {
  box-shadow: none !important;
}

.fieldsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  td {
    padding-bottom: 2px !important;
    width: 100%;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    color: $secondary-color;
  }
}

.submitModalButton {
  width: 100%;
  margin-top: 10px;
  min-height: 52px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.tableContainerData {
  margin-top: 10px;
}

.filtersHeader > div > div {
  align-items: center !important;

  button {
    text-transform: capitalize;
  }
}
