@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.card {
  margin-left: 26px;
}

.checkBox {
  flex-direction: row !important;
  margin-top: 64px !important;
}

.pageHeader {
  font-size: $header1-font-size;
  font-weight: 800;
}

.overrideInterestTitle {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
}

.box {
  min-height: 24px;
  align-items: flex-start;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;

  label {
    margin-bottom: 0;
    min-width: 190px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  :global {
    .MuiFormControl-root {
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 24px;

      .MuiInput-root {
        width: 100% !important;
      }
    }

    .MuiAutocomplete-root {
      width: 100% !important;

      .MuiInputBase-input {
        padding: 4px 0 5px;
      }
    }

    .MuiCheckbox-root {
      padding: 0 7px 0 0;
    }
  }

  + .box {
    margin-top: 14px;
  }
}

.summaryList {
  margin-top: 10px;
}

.summaryListItem {
  font-size: $main-font-size;
  line-height: 15px;
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 10px;
}

.summaryListItemBorder {
  margin-top: 10px;
  font-size: $main-font-size;
  line-height: 15px;
  color: $slate-gray;
  display: flex;
  position: relative;
  padding-bottom: 5px;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $slate-gray;
  }
}

.summaryListData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.rowTitleBold {
  font-weight: 700;
  font-size: $main-font-size;
  color: $slate-gray;
}

.rowTitle {
  font-weight: 500;
  font-size: $main-font-size;
  color: $slate-gray;
}

.rowValueBold {
  font-weight: 700;
  font-size: $main-font-size;
  color: $black;
}

.rowValue {
  font-weight: 500;
  font-size: $main-font-size;
  color: $black;
}

.headerRow {
  margin-top: 24px;
}

.buttonMarginRight {
  margin-right: 15px;
}

.iconButton {
  cursor: pointer;
}
