@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.searchContainer {
  transition: width 0.3s linear;
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.searchField {
  margin-right: 15px;
  display: none;
}

.searchFieldOpen {
  display: block;
}

.startAdornment {
  margin-left: 10px;
}

.dataContainer {
  height: 260px;
  overflow-y: scroll;
  margin-bottom: 28px;
}

.dataRow {
  padding: 10px;
  font-size: $header3-font-size;
  display: flex;
}

.submitButton {
  width: 100%;
  font-size: 16px;
}
