@import "../../../assets/styles/colors";

.container {
  :global {
    .rdw-editor-main {
      height: 420px;
    }
  }
}

.textEditor {
  background: transparent !important;
  border: $border-color 1.5px solid;
  padding: 0 !important;

  > div {
    > *:first-child {
      border-bottom: $border-color solid 1px;
      padding: 10px;
    }
  }
}
