@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins';

.closeButton {
  position: absolute;
  bottom: 30px;
  left: 20px;
  color: $black;
  opacity: 0.5;

  svg {
    height: 14px;
  }
}

.closeButton:hover {
  color: $black;
  opacity: 1;
}

.attachmentsContainerReadOnly {
  margin-top: 10px;
  display: flex;
}

.attachmentsContainer {
  margin-top: 10px;
  z-index: 1;
  border: none;
  bottom: 0;
  display: flex;
}

.attachmentImage {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: $small-border-radius;
  cursor: pointer;
}

.attachmentImage:hover {
  opacity: 0.5;
  stroke: $black;
}

.attachmentNonImage {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: $small-border-radius;
  cursor: pointer;
}

.attachmentUnit {
  position: relative;
}
