@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 20%;
      }
    }
  }

  thead {
    tr {
      th {
        padding: 2px 5px 3px;
        line-height: 28px;

        &.highlightedCurrent {
          background: $nepal;
          color: $white;
        }

        &.highlightedDate {
          background: $slate-gray;
          color: $white;
        }
      }
    }
  }

  tbody {
    scrollbar-width: none;
  }
}


.activeRow {
  span {
    background: $sunshade !important;
  }
}



.locationName{
  display:grid;
}

.originalLocationName{
  font-size: 10px;
  color: $raven;
}

.selectField {
  width: 105px;
  height: 36px;
}

.editLink {
  font-size: 12px;
  line-height: 23px;
  margin-left: 10px;
}