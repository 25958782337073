@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.warningContainer {
  margin: 10px 40px 0 32px;
  padding: 10px 24px !important;
  border: 1px solid $button-border-color;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  svg {
    width: 32px;
    height: 32px;

    circle {
      fill: $icon-color;
    }

    path {
      stroke: $icon-color;
    }
  }

  span {
    color: $secondary-color;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }
}
