@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.notificationItemUnreadIcon {
  width: 12px;
  height: 12px;
  background: $coral-red;
  border-radius: 50%;
}

.notificationItemContent {
  font-size: 14px;
  line-height: 1.5;
  color: $slate-gray;
  font-weight: 400;

  b {
    color: $secondary-color;
    font-weight: 600;
  }
}

.notificationItemContentAlt {
  color: $slate-gray;
  font-weight: 400;
}

.notificationItemContentNote {
  margin: 4px 0;
  padding-left: 10px;
  border-left: 1px solid $transparent-gray;
  font-size: 12px;
  font-weight: 400;
}
