@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.diligenceProgress {
  width: 320px;
  padding: 40px 40px 28px 28px;
  background: $cobalt;
  border-radius: $main-border-radius;
  display: flex;
  flex-direction: column;
  gap: 20px
}

.diligenceProgressTitle {
  font-size: 20px;
  line-height: 1.25;
  color: $white;
  font-weight: 800;
}

.diligenceProgressSteps {
  .diligenceProgressStepConnectorRoot {
    margin-left: 24px;
  }

  .diligenceProgressStepConnectorLine {
    border-color: $raven;
    border-left-width: 1px;
    border-left-style: dashed;
    min-height: 17px;
  }

  .diligenceProgressStepItemRoot {
    padding: 8px;
  }

  .diligenceProgressStepItemLabel {
    font-size: 16px;
    line-height: 1.5;
    color: $white;
    font-weight: 500;
    opacity: 0.45;
  }

  .diligenceProgressStepItemLabelActive, {
    color: $white;
    opacity: 1;
  }

  .diligenceProgressStepItemIcon {
    width: 33px;
    height: 32px;
  }
}

.diligenceProgressDateContainer {
  padding: 25px 30px 20px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba($white, 0.15);
  border-radius: 8px;
}

.diligenceProgressDateTextContainer {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.diligenceProgressDateLabel {
  font-size: 14px;
  line-height: 1.25;
  color: $white;
}

.diligenceProgressDate {
  font-size: 20px;
  line-height: 1.25;
  color: $white;
  font-weight: 800;
}

.diligenceContainer {
  padding: 60px 42px;
  display: flex;
  flex-direction: column;
  gap: 60px
}

.diligenceProgressCards {
  display: flex;
  gap: 25px;
}

.diligenceProgressCardItem {
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
  border-radius: $main-border-radius;
  border: 1px solid $button-border-color;
  box-shadow: 0 8px 24px 0 rgba($black, 0.04);
  height: 245px;
  width: 220px;
}

.diligenceProgressCardItemProgressContainer {
  position: relative;
  display: inline-flex;
  width: 92px;
  height: 92px;
}

.diligenceProgressCardItemProgressText {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 800;
  color: $secondary-color;
}

.diligenceProgressCardItemProgress {
  position: absolute;
}

.diligenceProgressCardItemProgressCircleBackground {
  stroke: rgba($mountain-meadow, 0.24);
}

.diligenceProgressCardItemProgressCircle {
  stroke: $success-green;
  stroke-linecap: round;
}

.diligenceProgressCardItemTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  max-width: 100%;
}

.diligenceProgressCardItemTextLabel {
  font-size: 12px;
  color: $slate-gray;
}

.diligenceProgressCardItemTextTitle {
  font-size: 14px;
  font-weight: 700;
  color: $secondary-color;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.diligenceProgressCardItemLink {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  color: $primary-color;

  &:hover {
    color: $primary-color-hovered;

    svg {
      circle {
        &:nth-child(2) {
          fill: $primary-color-hovered;
        }
      }
    }
  }

  svg {
    margin-left: 6px;
  }
}

.diligenceQuestions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.diligenceQuestionsText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  font-size: 14px;
}

.diligenceQuestionsTextTitle {
  font-weight: 800;
  color: $secondary-color;
}

.diligenceQuestionsTextDescription {
  color: $p-color;
}

.diligenceQuestionsManagers {
  display: flex;
  padding: 40px 34px;
  width: 100%;
  align-items: flex-start;
  gap: 40px;
  border-radius: $main-border-radius;
  border: 1px solid $button-border-color;
  box-shadow: 0 8px 24px 0 rgba($black, 0.04);
}

.diligenceQuestionsManagerItem {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 23px;
}

.diligenceQuestionsManagerItemAvatar {
  width: 48px;
  height: 48px;
}

.diligenceQuestionsManagerItemContacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.diligenceQuestionsManagerItemContactsName {
  color: $secondary-color;
  font-weight: 600;
  font-size: 16px;
}

.diligenceQuestionsManagerItemContactsData {
  display: flex;
  align-items: center;

  a {
    color: $slate-gray;
  }

  svg {
    margin-right: 5px;

    path, rect {
      stroke: $slate-gray;
    }
  }
}
