@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.uploadHeader,
.documentsHeader,
.commentsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 5px 0;
  color: $secondary-color;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 1px solid $button-border-color;
  cursor: pointer;

  svg {
    fill: black;
  }
}

.uploadSection {
  max-width: unset;
  
}

.documentsAndCommentsSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.commentsWrapper,
.documentsSection {
  flex: 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
  transition: flex-basis 0.4s;
  scroll-behavior: smooth;
  @include thin-scrollbar;

  &.commentsWrapperExpanded,
  &.documentsSectionExpanded {
    flex-basis: 100%;
  }
}

.comment {
  display: flex;
  flex-direction: column;
  gap: 2px;

  + .comment {
    position: relative;

    &:before {
      content: '';
      height: 1px;
      width: 100%;
      background: $button-border-color;
      position: absolute;
      top: -15px;
      left: 0;
      right: 100%;
    }
  }
}

.commentUserAndActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commentUser {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: $black;
}

.commentText {
  padding: 2px 8px 0 8px;
  font-size: 14px;
  line-height: 24px;
}

.commentActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.commentDate {
  text-align: right;
  color: $slate-gray;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}

.addCommentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.commentButton {
  width: 100%;
}

.commentsEmpty {
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: $p-color;
  font-size: 12px;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;

    circle {
      fill: $p-color;
    }
    path {
      stroke: $p-color;
    }
  }
}

.templateLink {
  cursor: pointer;
  color: $primary-color;
  font-size: $main-font-size;
}

.commentText {
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
  div > div {
    margin: 0;
    vertical-align: middle;
  }
  :global {
    .rdw-editor-toolbar {
      display: none;
    }
  }
}
