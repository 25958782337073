@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.scrollableDiv {
  overflow-y: scroll;
  max-height: 240px !important;
}



.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }
}
