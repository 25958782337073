@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.wrapper {
  line-height: 15px;
  display: inline-block;
  margin-left: 2px;
  vertical-align: middle;

  &.isOpen {
    .icon {
      display: inline !important;
    }
  }
}

.icon {
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.popover {
  width: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dataLabel {
  color: $transparent-gray;
  font-size: 12px;
  line-height: 15px;
  align-self: flex-start;
}

.dataValue {
  color: $secondary-color;
  font-size: 12px;
  line-height: 15px;
  align-self: flex-start;
}

.dataContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
