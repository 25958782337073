@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

:global {
  .MuiInput-underline.Mui-error:after {
    border: none;
  }

  .MuiInput-underline::after {
    border: none;
  }
}


.root {
  font-size: $main-font-size;
  line-height: 20px;
  color: $input-color;
  border-radius: $small-border-radius;

  &:focus {
    background-color: $input-hovered-background-color;
  }
}

.inputField {
  font-size: $main-font-size;
  background-color: $input-background-color;
  border-radius: $small-border-radius;
  padding-left: 5px;
}
.withBorder{
  border: 1px solid $input-border-color;
  border-radius: $small-border-radius;
}

.inputFieldMediumHeight {
  input {
    box-sizing: border-box;
    height: 32px;
  }
}
.withBorder {
  border: 1px solid $input-border-color;
  border-radius: $small-border-radius;
}




.inputFieldLargeHeight {
  font-size: 14px;
  font-weight: 400;
  background-color: white;

  :global {
    .MuiInput-root {
      height: 48px;
    }
    .MuiInputBase-input {
      padding: 8px 18px;
      font-size: 14px;
    }

    .MuiSelect-icon {
      right: 8px;
    }
  }
}

.input {
  background-color: $input-background-color;
  border-radius: $small-border-radius;
  &::before {
    display: none;
  }

  input {
    padding: 8px 14px !important;
    line-height: 20px;
    height: 20px;
    cursor: text;
    font-size: $main-font-size;
  }
}

.error {
  &::after {
    border-bottom: none;
  }
}

.popper {
  display: block;
  box-shadow: 0px 2px 1px -1px $gothic;
  border-radius: $small-border-radius;
  overflow: hidden;
  min-width: fit-content !important;
  z-index: 2005;


  > div {
    margin: 0;
  }

  ul {
    padding: 0;
    color: $black;

    li {
      box-sizing: content-box;
      font-size: $main-font-size;
      padding: 10px !important;

      &:hover,
      &[data-focus='true'] {
        background: $dropdown-hovered-color !important;
      }

      &[aria-selected='true'] {
        background: $dropdown-selected-color;

        &:hover {
          background: $dropdown-selected-color;
        }
      }
    }
  }
}

.endAdorn {
  // align to the end
  margin-left: auto;
  margin-right: 0;
  padding-right: 0;
}


.withEndAdornment {
  padding-right: 20px;
}


.inputError {
  border: 1px solid $light-red;
  border-color: $red-border!important;

}

.inputError:focus-within {
  border: 1px solid $primary-color;
    border-color: transparentize($primary-color, 0);
    border-radius: $small-border-radius;


}
