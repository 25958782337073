@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.container {
  margin: 10px 32px 85px;
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 14px;
  display: flex;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.subTitle {
  color: $black;
  font-size: 14px;
}

.subTitleMonthLoader {
  display: inline-block;
  margin-left: 5px;
}

.textField {
  width: 120px;

  @include text-field-with-top-label;
}

.selectFieldWrapper {
  width: 120px;
}

.selectField {
  width: 120px;
  height: 36px;

  @include select-field-with-top-label;
}

.inputContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.table {
  overflow: auto;
  margin-left: -10px;
  width: calc(100% + 10px);
  padding-bottom: 13px;

  thead {
    width: 100% !important;
    border-spacing: 0 !important;
    margin: 0 !important;

    th {
      &.tableCellProjection {
        background: $botticelli;
      }
    }
  }

  tbody {
    display: table !important;
    max-height: initial !important;
    border-collapse: separate !important;
    position: relative;

    tr {
      td {
        &:nth-child(1) {
          position: relative;
          overflow: visible;
        }
      }

      &.headerRow {
        td {
          border-top: 1px solid $cadet-blue;
          border-bottom: 1px solid $cadet-blue;
        }
      }

      &.indentedRow {
        td {
          &:nth-child(2) {
            padding-left: 30px !important;
          }
        }
      }
    }
  }

  &.tableCellAutoWidth {
    tr {
      td,
      th {
        &:nth-child(n+4) {
          width: auto;
        }
      }
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        padding: 0;
        position: sticky !important;
        left: 0;
        width: 10px;
        background: $white;
        border: none !important;
        z-index: 2;
        overflow: visible;

        &.withExpandIcon {
          z-index: 3;
        }
      }
      &:nth-child(2) {
        position: sticky !important;
        background-color: inherit !important;
        left: 10px;
        z-index: 1;
        min-width: 350px;
        width: 350px;
        text-align: left;
      }

      &:nth-child(3) {
        position: sticky !important;
        background-color: inherit !important;
        left: 360px;
        z-index: 1;
        min-width: 200px;
        width: 200px;
        //box shadow on the right
        box-shadow: 5px 0 20px -10px rgba(0, 0, 0, 0.2);
      }

      &:nth-child(n+4) {
        min-width: 150px;
        width: 150px;
      }
    }

    &.highlightedRow {
      td {
        &:not(:first-of-type) {
          background: $slate-gray !important;
          color: $white;
          > span {
            color: $white;
          }
        }
      }
    }
  }

  &.tableWithMultipleSummary {
    tr {
      td,
      th {
        &:nth-child(3) {
          min-width: 175px;
          width: 175px;
          box-shadow: none;
        }

        &:nth-child(4) {
          position: sticky !important;
          background-color: inherit !important;
          left: 535px;
          z-index: 1;
          min-width: 175px;
          width: 175px;
          //box shadow on the right
          box-shadow: 5px 0 20px -10px rgba(0, 0, 0, 0.2);
        }

        &:nth-child(n+5) {
          min-width: 150px;
          width: 150px;
        }
      }
      &.highlightedRow {
        td,
        th {
          &:nth-child(4) {
            background: $slate-gray !important;
          }
        }
      }
    }
    &.tableCellAutoWidth {
      tr {
        td,
        th {
          &:nth-child(n+5) {
            width: auto;
          }
        }
      }
    }
  }

  :global {
    tr {
      &.activeRow {
        td {
          &:nth-child(1) {
            background: $white !important;
          }

          > div {
            color: $tundora !important;
          }
        }
      }
    }
  }
}

.expandIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -7px;
  left: 3px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
  }
}

.fullScreenModal {
  .container {
    margin: 10px 32px;
  }

  .table {
    max-height: calc(95vh - 165px);
  }
}

.amountHolder {
  font-weight: 700;
}

.amountHolderNegative {
  color: $red-text;
}

.chartContainer {
  height: calc(100vh - 500px);
  min-height: 400px;
}

.chartIcon {
  height: 20px;
  width: 20px;
}

.fullScreen {
  height: calc(100vh - 230px);
}
