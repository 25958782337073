@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import '../../assets/styles/mixins';

.container {
  margin: 10px 40px 10px 32px;
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  border-radius: $main-border-radius;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  padding: 7px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.subTitle {
  font-size: $main-font-size;
  color: $secondary-color;
  font-weight: 700;
}

.editLink {
  font-size: $main-font-size;
  font-weight: 600;
  line-height: 24px;
}

.subTitleLoader {
  display: inline-block;
}

.data {
  display: flex;
  align-items: flex-start;
  gap: 100px;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.sectionTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0 5px 7px 0;
  border-bottom: 1px solid $button-border-color;
}

.sectionTitle {
  flex: 1;
  color: $secondary-color;
  font-size: 16px;
  font-weight: 800;
  line-height: 150%;
  text-align: left;
}

.sectionData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.dataRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 16px;
  padding: 7px 12px 7px 7px;

  &:nth-child(even) {
    background-color: $row-background-color;
  }

  &:hover {
    background: $border-color;
  }
}

.dataRowFull {
  flex-direction: column;
  align-items: flex-start;
  background: $white !important;

  .value {
    padding: 2px 0 1px;
  }
}

.label {
  display: flex;
  justify-content: space-between;
  flex: 1;
  color: $slate-gray;
  font-size: $main-font-size;
  line-height: 1.25;
  font-weight: 700;
  text-transform: capitalize;
  vertical-align: center;
}

.previousValue {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  font-size: $main-font-size;
  color: $slate-gray;
  text-transform: capitalize;
  line-height: 1.25;
  vertical-align: center;
}

.value {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: $secondary-color;
  font-size: $main-font-size;
  line-height: 1.25;
  font-weight: 700;

  :global {
    [class*="SelectField_root"] {
      background: $white;
    }
  }
}

.selectField {
  width: 170px;
  height: 40px;

  @include select-field-with-top-label;
}
