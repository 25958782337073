@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  table tbody {
    max-height: 60vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 75vh !important;
    }
  }
  tr {
    td,
    th {
    }
  }
}

.linkIcon {
  margin-top: 3px;
  margin-left: 7px;
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

.salesforceLinkIcon {
  margin-top: 3px;
  margin-left: 7px;
  vertical-align: middle;
  height: 25px;
  width: 25px;
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer + new filters
      max-height: calc(95vh - 267px) !important;
    }
  }
}

.dashboardCard {
  background: $white;
  border-radius: 8px;
  height: 120px;
  position: relative;
  padding-left: 16px;
  box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.14);
}

.cardHeader {
  font-weight: 600;
  font-size: 14px;
  padding-top: 12px;
  color: $slate-gray;
}

.cardValue {
  position: absolute;
  left: 16px;
  bottom: 45px;
  font-weight: 800;
  font-size: 20px;
  color: $secondary-color;
}

.dashboardHeaderWrapper {
  background: $off-white;
  height: 152px;
  width: 100%;
  border-radius: $main-border-radius;
  margin-bottom: 20px;

  padding: 0 16px 0 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.refreshButton {
  margin-left: auto;
  margin-right: 15px;
}

.cardFooter {
  color: $transparent-gray;
  bottom: 12px;
  position: absolute;
  left: 16px;
  font-weight: 600;
  font-size: 14px;
}

.tooltip {
  background-color: $primary-color;
}

.positivePct {
  float: right;

  color: $success-green;
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
}

.negativePct {
  font-weight: 600;
  font-size: 16px;
  color: $red;
  padding-left: 10px;
  float: right;
}
