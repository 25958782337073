@import '../../../assets/styles/colors';

.table {
  margin-top: 10px;
  padding: 0;

  tbody {
    tr {
      td {
        :global {
          .MuiFormControl-root {
            align-items: center !important;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        padding-top: 10px;
        border-top: none;
      }
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 20%;
        position: relative;
        overflow: visible;

        &.hasChild {
          border-bottom: none;
        }
      }

      &:nth-child(2) {
        width: 13%;
        position: relative;
        overflow: visible;
      }

      &:nth-child(3) {
        width: 17%;
        position: relative;
      }

      &:nth-child(4) {
        width: 10%;

        :global {
          .MuiAutocomplete-root {
            .MuiAutocomplete-input {
              text-overflow: unset;
            }
          }

          .MuiFormControl-root {
            > div {
              width: 100%;
              max-width: unset;
            }
          }
        }
      }

      &:nth-child(5) {
        width: 10%;

        :global {
          .MuiFormControl-root {
            align-items: center;
          }
        }
      }

      &:nth-child(6) {
        width: 6%;

        :global {
          .MuiFormControl-root {
            > div {
              width: 175px;
              max-width: 175px;
            }
          }
        }
      }

      &:nth-child(7) {
        width: 20%;
      }

      &:nth-child(8) {
        width: 95px;
      }

      :global {
        .MuiAutocomplete-root {
          max-width: calc(100% - 10px);
        }

        .MuiFormControl-root {
          height: 23px !important;

          > div {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
      }
    }

    td {
      &:nth-child(6) {
        :global {
          .MuiFormControl-root {
            width: 100%;

            > div {
              width: 100%;
              max-width: unset;
            }

            .MuiFormControlLabel-root {
              margin-left: 0;
              margin-right: 0;
              flex: 1;
              justify-content: flex-start;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}

.iconButton {
  vertical-align: middle;

  + .iconButton {
    margin-left: 5px;
  }
}

.overridesList {
  width: 100%;
}



.childBorders {
  position: absolute;
  top: 14px;
  left: 10px;
  height: 1px;
  width: calc(100% - 10px);
  background: $gothic;

  &:before {
    content: ' ';
    position: absolute;
    top: -15px;
    left: 0;
    height: 15px;
    width: 1px;
    background: $gothic;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 1px;
    background: $gothic;
  }
}

.childRow {
  &:not(.lastChildRow) {
    td {
      &:nth-child(1) {
        border-bottom: none;
      }
    }
  }
}

.lastChildRow {
  .childBorders {
    &:after {
      display: none;
    }
  }
}

.filtersHeader > div > div {
  align-items: center !important;

  button {
    text-transform: capitalize;
  }
}

.submitModalButton {
  width: 100%;
  margin-top: 10px;
  min-height: 52px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
