@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.select {
  font-size: $main-font-size;
  line-height: 20px;
  background-color: $input-background-color;
  color: $input-color;
  border-radius: $main-border-radius;
  border: none;

  &:focus {
    background-color: $input-hovered-background-color !important;
    border-radius: $main-border-radius;
  }

  &::before {
    display: none;
    border: none !important;
  }
}

.menuPaper {
  box-shadow: 0px 2px 1px -1px $gothic;
  border-radius: $main-border-radius;

  ul.menuList {
    color: $dark-blue;

    .listItemRoot {
      border-radius: $main-border-radius;
      margin: 4px;
      height: 33px;

      &:hover {
        background: $dropdown-hovered-color;
      }

      &.listItemRootDisabled {
        cursor: default;
      }
    }

    :global {
      .Mui-selected, .Mui-selected:hover {
        background: $dropdown-selected-color;
      }
    }
  }

}

.icon {
  padding: 8px 7px;
  display: flex;
}

.open {
  transform: rotate(180deg);
}
