@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.menuItemLink {
  color: $black;
  text-decoration: none;
}

.modalRoot {
  width: 465px !important;
}

.fieldsContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.textFormField {
  padding-top: 28px;

  :global {
    .MuiInput-root {
      max-height: 150px;
      overflow: scroll;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}