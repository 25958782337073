@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.dateField {
  width: 100%;
  height: 36px !important;

  :global {
    .MuiInput-root {
      width: 100% !important;
      height: 36px !important;
    }
  }
}
