@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  padding: 10px 32px;
  border-radius: 10px;
  table tbody {
    max-height: calc(95vh - 300px) !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 12%;
      }

      &:nth-child(2) {
        width: 12%;
      }

      &:nth-child(3) {
        width: 8%;
      }

      &:nth-child(4) {
        width: 8%;
      }

      &:nth-child(5) {
        width: 8%;
      }

      &:nth-child(6) {
        width: 8%;
      }

      &:nth-child(7) {
        width: 8%;
      }
      &:nth-child(8) {
        width: 8%;
      }
      &:nth-child(9) {
        width: 8%;
      }
      &:nth-child(10) {
        width: 8%;
      }
      &:nth-child(11) {
        width: 4%;
      }
      &:nth-child(12) {
        width: 8%;
      }
    }
    &.newRow {
      background-color: $mabel !important;
    }

    td {
      :global {
        .MuiSelect-select {
          padding: 2px 7px;
        }
        .MuiSelect-icon {
          top: 4px;
          padding: 2px 5px;
        }
        .MuiCheckbox-root {
          padding: 0;
        }
        .MuiFormControlLabel-root {
          margin-left: 0;
        }
      }
    }
  }
}

.skuTypesList {
  width: 97%;
}

.selectColumn {
  overflow: visible;
}
