@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins.scss";

.container {
  max-width: 1136px;
}

.tabContainer {
  display: flex;
  margin-bottom: 20px;
}

.header {
  line-height: 40px !important;
  margin-bottom: 6px !important;
}

.description {
  font-size: 14px;
  line-height: 24px;
  color: $slate-gray;
}

.tableWrapper {
  padding: 28px;
  background: $white;
  width: 100%;
}

.table {
  margin-top: 20px;

  table {
    tbody {
      max-height: calc(100vh - 372px);
    }

    tr {

      th, td {
        &:nth-child(1) {
          width: 50%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 20%;
        }
      }
    }
  }
}

.headingRow {
  height: 32px;
  color: $secondary-color;
  font-size: 14px;
  font-weight: 700;

  svg {
    path {
      fill: $primary-color;
    }
  }

  button {
    margin-right: 10px;
  }
}

.nameWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.name {
  flex: 1;
  white-space: break-spaces;
}

.count {
  width: 20px;
  height: 20px;
  background: $success-green;
  border-radius: 50%;
  text-align: center;
  font-size: $main-font-size;
  line-height: 20px;
  color: $white;
  font-weight: 700;
}

.status {
  padding: 4px 8px;
  border-radius: $small-border-radius;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;

  &.statusNotStarted {
    color: $primary-color;
    background: rgba($primary-color, 0.15);
  }

  &.statusInReview {
    color: $corn;
    background: rgba($corn, 0.15);
  }

  &.statusActionRequired {
    color: $red-text;
    background: $red-background;
  }

  &.statusCompleted {
    color: $success-green;
    background: $light-green;
  }
}

.modal {
  width: 600px;
  height: 100vh;
  padding: 0;
  top: 0;
  right: 0;
  left: unset;
  transform: none;
  border-radius: 0;
}

.helperRow {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 30px;
}

.helperText {
  color: $p-color;
  font-size: 14px;
}
