@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins.scss";

.container {
  max-width: 100%;
}

.inputField {
  font-size: $header3-font-size;
  background-color: $off-white;
  border-radius: $main-border-radius;
  padding: 10px !important;
  width: 100%;
  max-width: 539px;
  z-index: 1299;
}

.inputFieldMediumHeight {
  input {
    padding: 4px !important;
    box-sizing: border-box;
    height: 20px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: $secondary-color;

    &::placeholder {
      color: $transparent-gray;
    }
  }
}

.inputIcon {
  height: 20px;
}

.inputLoaderIcon {
  margin-right: 2px;

  svg {
    color: $cadet-blue;
  }
}

.input {
  border-radius: $main-border-radius;
  width: 482px !important;

  &::before {
    display: none;
  }

  input {
    padding: 8px 14px !important;
    line-height: 30px;
    height: 30px;
    cursor: text;
    font-size: $header3-font-size;
  }
}

.focused {
  background-color: $input-hovered-background-color;
}

.focusedInput {
  outline: 1px solid $primary-color !important;
}

.boldedSearch {
  font-weight: bold;
  color: $primary-color !important;
}

.popper {
  z-index: 1298;
}

.paper {
  transform: translate(-12px, -52px);
  padding: 52px 12px 12px;
  max-width: 565px;
  width: calc(100% + 26px);
  border-radius: $medium-border-radius;
  box-shadow: 0 32px 48px -8px rgba(0, 0, 0, 0.10), 0px 0px 14px -4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.listbox {
  order: 1;
  margin-top: 14px;
  padding: 0;
  @include thin-scrollbar;

  > li {
    padding: 10px 0 10px;
    border-top: 1px solid $button-border-color;

    &:first-of-type {
      padding-top: 0;
      border-top: none;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.groupLabel {
  padding: 6px 12px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: $slate-gray !important;
}

.option {
  &:hover, &[data-focus="true"] {
    background-color: $input-background-color !important;
  }

  &[aria-selected="true"] {
    background: $white;
  }
}

.listItem {
  padding: 6px;
  min-height: 40px;
  box-sizing: border-box;
  gap: 16px;
  max-width: 100%;
  cursor: pointer;

  &:hover {
    background: $gray-background-color;
  }
}

.listItemIcon {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid $geyser;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.listItemIconSvg {
  width: 20px;
  height: 20px;
}

.listItemIconFunding {
  path  {
    stroke: $slate-gray
  }
}

.listItemNavigate {
  cursor: pointer;
}

.listItemText {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.listItemTextPrimary {
  display: block;
  overflow: hidden;
  max-height: 48px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 14px;
  line-height: 24px;
  color: $secondary-color;
  font-weight: 600;
}

.listItemTextSecondary {
  font-size: 12px;
  line-height: 21px;
  font-weight: 600 !important;
  color: $pale-sky !important;
}

.endAdornment {
  right: 10px;
  top: calc(50% - 10px)
}

.clearIcon {
  padding: 0;

  svg {
    width: 20px;
    height: 20px;
    fill: $transparent-gray;
  }

  &:hover {
    background: none;

    svg {
      fill: $icon-color;
    }
  }
}

.seeAllButton {
  margin-top: 8px;
  padding: 12px;
  width: 100%;
  color: var(--neutral-07, #1A1D1F);
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  border: 1px solid $catskill-white;
  border-radius: $small-border-radius;
  cursor: pointer;
  order: 2
}

.avatar {
  width: 39px;
  height: 39px;
  img {
    object-fit: contain;
  }
}
