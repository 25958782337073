@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }
  tbody {
    max-height: 300px !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 300px !important;
      }

      &:nth-child(2) {
        width: 80px !important;
      }

      &:nth-child(3) {
        width: 100px !important;
      }

      &:nth-child(4) {
        width: 200px !important;
      }

      &:nth-child(5) {
        width: 100px !important;
      }

      &:last-child {
        width: 50px !important;
      }
    }
  }


}

.activeToolbar {
  padding: 0 32px 0 24px;
  order: 1;

  > div {
    width: 12%;

    &:nth-child(1) {
      width: 16%;
    }
  }
}

.changedValue {
  white-space: nowrap;
}

.scrollableDiv {
  overflow-y: scroll;
  max-height: 240px !important;
}



.submitModalButton {
  width: 100%;
}

.label{
  margin-top: 8px;
}

.wireFeeCheckboxWrapper {
  text-align: left;
}

.modalRoot {
  max-height: 90vh;
  overflow: hidden;
}

.modalBody {
  max-height: calc(90vh - 114px);
  overflow-x: hidden;
  overflow-y: auto;

  @include thin-scrollbar;
}


.textFormField {
  :global {
    .MuiInput-root {
      height: 48px;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}


.dateFormField {
  width: 100%;
  height: 48px !important;

  :global {
    .MuiInput-root {
      padding: 8px 14px;
      height: 48px !important;
      width: 100% !important;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}
