@import '../../assets/styles/colors';

.iconVisibility {
  font-size: 16px;
  transform: rotateY(180deg);
}

.iconButtonVisibility {
  color: $p-color;
}

.forgotPassword {
  text-decoration: underline;
}

.emailInput {
  text-align: left;
  color: #1a202c;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 21px;
  font-weight: 600;
  border-color: $primary-color;
}

.loginButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  width: 428px;
  height: 56px;

  /* Blue */

  background: $primary-color;
  border-radius: 5px;
}

.loginButton:hover {
  background-color: #003cbe;
}

.textInput {
  /* bg */
  width: 428px;
  background-color: #ffffff;
  border: 1px solid $input-border-color;
}

.floatContainer {
  width: 428px;

  height: 24px;
  margin-bottom: 4%;
}

.rememberMe {
  float: left;
  font-size: 16px;
  color: #1a202c;
  /* identical to box height, or 24px */
  display: flex;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.forgotPassword {
  color: $primary-color;
  float: right;
  text-align: right;
  align-content: flex-end;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.valError {
  background-color: white;
  border-color: white;
  color: $red-border;
}
