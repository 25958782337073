@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.inputLabel {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  color: $secondary-color;
  margin-bottom: 10px;
}

.inputLabelMedium {
  font-size: 14px;
  color: $blue-wale;
}

.inputLabelVertical {
  margin-bottom: 0;
  margin-right: 10px;
}

.inputLabelHorizontal {
  margin-bottom: 0;
  margin-right: 10px;
}
