@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  table tbody {
    max-height: 60vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 75vh !important;
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 36px;
      }
      &:nth-child(2) {
        width: 14%;
      }

      &:nth-child(3) {
        width: 10%;
      }

      &:nth-child(4) {
        width: 10%;
      }

      &:nth-child(5) {
        width: 10%;
      }

      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: calc(46% - 36px) ;
      }
    }
  }
}

.isRowExpanded{
  background-color: $border-color !important;
}