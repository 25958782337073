@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.redirectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $secondary-color;
  background-color: $gray-background-color;
}

.redirectText {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  font-size: $header1-font-size;
  height: 100vh;
  align-content: center;
}

.dwightLogo {
  display: block;
}
