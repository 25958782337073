@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.aliasesTable {
  margin-top: 10px;

  table {
    tbody {
      max-height: calc(100vh - 534px);
    }

    tr {
      th, td {
        padding-left: 20px !important;

        &:nth-child(1) {
          width: 45%;
        }

        &:nth-child(2) {
          width: 30%;
        }

        &:nth-child(3) {
          width: 25%;
        }
      }

      td {
        :global {
          .MuiAutocomplete-root {
            overflow: hidden;

            .MuiInput-root {
              border-radius: 0;
            }
          }

          .MuiFormControl-root {
            .Mui-focused:not(.MuiFormHelperText-root) {
              outline: none !important;
              border-radius: 0 !important;
              transform: none;
            }

            .MuiAutocomplete-endAdornment {
              right: 5px;

              button {
                svg {
                  path {
                    fill: $transparent-gray;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.typesTable {
  margin-top: 10px;

  table {
    tbody {
      max-height: calc(100vh - 494px);
    }
  }
}
