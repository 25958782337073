@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';
.root {
  :global {
    .MuiInput-underline:before,
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
      display: none;
    }

    .MuiFormControl-root {
      width: 100%;

      &:focus {
        border: 1px solid $primary-color;
        border-color: transparentize($primary-color, 0) !important;
      }
    }

    .MuiInput-root {
      padding: 1px 5px;
      border-radius: $small-border-radius;
      height: 36px;
      background: $input-background-color;
      color: $input-color;
      width: 96% !important;
      border: 1px solid $input-border-color;

      &:focus {
        border: 1px solid $primary-color;
        border-color: transparentize($primary-color, 0) !important;
      }
    }

    .MuiInputBase-input {
      padding: 0;
      height: 20px;
      cursor: text;
      font-size: $main-font-size;
      text-align: right;

      &:disabled {
        color: $disabled-input;
      }
    }

    .MuiInputAdornment-root {
      width: 18px;
      height: 18px;
      margin-right: 2px;

      .MuiButtonBase-root.MuiIconButton-root {
        width: 18px;
        height: 18px;
        padding: 0;
        margin: 0;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .MuiInput-root.Mui-disabled {
      background-color: $disabled-input-background;
    }
  }
}

.big {
  width: 100% !important;
  :global {
    width: 100% !important;
    .MuiInput-underline:before,
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
      display: none;
    }

    .MuiFormControl-root {
      width: 100% !important;
      &:focus {
        border: 1px solid $primary-color;
        border-color: transparentize($primary-color, 0) !important;
      }
    }

    .MuiInput-root {
      padding: 8px 8px;
      height: 48px;
      background: $white;
      color: $input-color;
      width: 100% !important;
      border: 1px solid $input-border-color;
      border-radius: $small-border-radius;

      &:focus {
        border: 1px solid $primary-color;
        border-color: transparentize($primary-color, 0) !important;
      }
    }

    .MuiInput-root.Mui-focused {
      border: 1px solid $primary-color;
      border-color: transparentize($primary-color, 0) !important;
    }

    .MuiInputBase-input {
      padding: 0;
      height: 20px;
      cursor: text;
      font-size: 14px;
      font-weight: 400;
      text-align: right;
      &:disabled {
        color: $disabled-input;
      }
    }

    .MuiInputAdornment-root {
      width: 18px;
      height: 18px;
      margin-right: 2px;

      .MuiButtonBase-root.MuiIconButton-root {
        width: 18px;
        height: 18px;
        padding: 0;
        margin: 0;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .MuiInput-root.Mui-disabled {
      background-color: $disabled-input-background;
    }
  }
}

.withTopLabel {
  @include text-field-with-top-label;

  :global {
    .MuiInputBase-root {
      overflow: visible !important;
      height: 36px !important;
    }
    .MuiFormControl-root {
      height: 36px;
    }
  }

  > div {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.inputError {
  border: 1px solid $light-red;
  border-color: $red-border !important;
}

.inputError:focus-within {
  border: 1px solid $primary-color;
  border-color: transparentize($primary-color, 0);
  border-radius: $small-border-radius;
}

.calendarSelectOnly {
  :global {
    .MuiFormControl-root,
    .MuiInput-root,
    .MuiInputBase-input {
      cursor: pointer !important;
    }

    .MuiInputBase-input {
      pointer-events: none;
    }
  }
}

.isDateGreaterThanMax,
.isDateLessThanMin {
  :global {
    .MuiInput-root {
      border: 1px solid $red;
    }
  }
}


