@import '../../../assets/styles/colors';

.root {
  padding: 0;
  font-weight: inherit;
  height: 30px;
}

.editable {
  background: linear-gradient(0deg, $alice-blue, $alice-blue), $white;
}

.filters {
  padding-bottom: 30px;
  height: 70px !important;
  vertical-align: top;

  th {
    border: none;
  }
}

.newFilters {
  th {
    border: none;
  }
}

