@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.dashboardBlock {
  position: relative;
  padding: 10px;
  border-radius: $main-border-radius;
  min-height: 100%;
  min-width: 450px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.dashboardBlockTitle {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: $secondary-color;
}

.dashboardBlockContent {
  display: flex;
  font-size: $main-font-size;
  line-height: 20px;
  align-items: center;
  justify-content: space-between;

  + .dashboardBlockContent {
    margin-top: 10px;
  }
}

.dashboardBlockContentIcon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.dashboardBlockContentLabel {
  color: $gothic;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  width: 50%;

}

.dashboardBlockContentValue {
  color: $dark-blue;
  font-weight: 700;
}

.button {
  float: right
}

.mappingContainer {
  padding: 0;
  min-height: 100%;
}

.mappingContainerContent {
  height: calc(95vh - 118px);
}

.mappingContainerTitle {
  padding: 14px 0;
  text-align: left;
  font-size: 24px;
  line-height: 40px;

}

.mappingContainerTitleText {
  margin-left: 25px;
}

.mappingContainerLegend {
  padding: 0 25px;
  justify-content: flex-start;
  gap: 25px;
}

.fullScreenModalBody {
  .mappingTable {
    height: 100%;

    tbody {
      max-height: calc(95vh - 320px) !important;
    }
  }
}
.fileSheetName {
  font-weight: 600;
  // add ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}


.accountMappingContainer {
  max-width: 450px;
}

.mappingContainerTabs{
  margin-top: 13px;
}

.selectField {
  width: 250px;
}