@import "../../assets/styles/colors.scss";

@import "../../assets/styles/variables.scss";

.table {
  tbody {
    max-height: 60vh !important;
  }

  tr {
    td, th {
      &:nth-child(1) {
        width: 50px;
      }

      &:nth-child(2), &:nth-child(3) {
        width: 140px;
      }

      &:nth-child(5) {
        width: 170px;
      }

      &:nth-child(6), &:nth-child(7) {
        width: 60px;
      }
    }
  }
}

.statusSelect {
  > div {
    background-color: transparent;
  }

  svg {
    display: none;
  }

  &:hover {
    > div {
      background-color: $white;
    }

    svg {
      display: block;
    }
  }
}

.status {
  padding: 3px 5px;
  border-radius: $small-border-radius;
  font-weight: 600;
  margin-right: 5px;
}

.statusOption {
  padding: 3px 5px;
  border-radius: $small-border-radius;
  font-weight: 600;
}

.green {
  background-color: $light-green;
  color: $green-text;
}

.yellow {
  background-color: $yellow-background;
  color: $yellow-text;
}

.blue {
  background-color: $blue-background;
  color: $primary-color;
}

.statusChip {
  margin-left: 0;
  background-color: $gray-background-color;
  color: $secondary-color;
}

.nextIcon {
  width: 8px;
  height: 16px;

  path {
    fill: $primary-color;
  }
}

.nestedRowColumn {
  padding: 10px 15px;
  background: $white;

  > div {
    + div {
      margin-top: 15px;
    }
  }
}
