@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
  position: relative;
  box-shadow: none;
  padding: 10px 32px;
  border-radius: $main-border-radius;
  background-color: transparent;

  &.withBorder {
    border: 1px solid $border-color;
  }

  &.noPadding {
    padding: 0;
    border-radius: 0;
  }

  &.noVerticalPadding {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.content {
  padding: 0;
  width: 100%;

  &:last-child {
    padding-bottom: 0;
  }

  &.noPadding {
    padding: 0;
  }
}

.headerRoot {
  padding: 0;
  width: 100%;
}

.headerWrap {
  margin-top: 10px;
  margin-bottom: 10px;

  &.withBorder {
    margin: 0;
    padding: 10px;
    border: 1px solid $border-color;
    border-radius: $small-border-radius $small-border-radius 0 0;
  }
}

.noHeaderMargin {
  margin: 0;
}

.noHeaderTopMargin {
  margin-top: 0;
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.expandIcon {
  margin: 0;
  padding: 0 0 2px 0;
  position: static;
  color: $primary-color;
  transition: 0.5s;
}

.divider {
  margin-top: 12px;
}
