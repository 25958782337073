.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.generateTermSheet {
  min-width: 150px;
  display: flex;
  justify-content: center;
}
