@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.table {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 21%;
      }
      &:nth-child(8) {
        width: 7%;
      }
    }
  }
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer
      max-height: calc(95vh - 368px) !important;
    }
  }

  > div {
    padding: 20px;
  }
}
