@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      // Padding + card header + table header + table footer + new filters
      max-height: calc(95vh - 267px) !important;
    }
  }
}

.headerContainer {
  padding: 24px;
}

.bodyContainer {
  padding-left: 18px;
  padding-right: 54px;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-right: 40px;
}
