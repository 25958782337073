@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../pages/ClientIntakePage/ClientIntakePage.module';

.formContent {
  max-width: 660px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  > div {
    flex: 0 calc(50% - 12px);
  }
}

.fullWidthRow {
  flex: 0 100% !important;
}

.erpSelectorWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.erpSelector {
  padding: 9px 23px;
  background: $border-color;
  border-radius: 5px;
  font-size: 14px;
  color: $secondary-color;
  cursor: pointer;
}

.erpSelectorSelected {
  background: $primary-color;
  color: $white;
}
