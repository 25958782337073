@import './colors.scss';
@import './variables.scss';

@mixin thin-scrollbar {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding: 0 2px;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: $scrollbar-color;
    border-radius: 8px;
    background-clip: content-box;
  }
}

@mixin navigation-bar {
  position: fixed;
  padding: 15px 45px;
  height: 75px;
  width: calc(100% - #{$drawer-collapsed-width});
  left: $drawer-collapsed-width;
  bottom: 0;
  background: $white;
  box-shadow: 0 0 2px rgba($black, 0.06), 0 8px 24px rgba($black, 0.1);
}

@mixin navigation-bar-label {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: $slate-gray;
}

@mixin text-field-with-top-label {
  :global {
    label {
      padding: 2px 4px;
      z-index: 1;
      left: 8px;
      top: -6px;
      background: $white;
      transform: translate(0, -1.5px) scale(0.75) !important;

      + .MuiInputBase-root {
        margin-top: 0;
      }
    }

    .MuiFormHelperText-root {
      margin: 0;
      color: $coral-red !important;
      transform: scale(0.75);
    }
  }
}

@mixin select-field-with-top-label {
  fieldset {
    border-width: 1px !important;
    border-color: $input-border-color;
    legend {
      max-width: unset;
      overflow: visible;
      span {
        padding: 0;
        font-size: 12px;
        transform: translate(0, -3px) scale(0.75) !important;
        opacity: 1;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
