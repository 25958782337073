@import "../../assets/styles/variables";
@import "../../assets/styles/colors";

.clientBannersContainer {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px
}

.clientBanner {
  display: flex;
  height: 60px;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  width: 100%;
  background: rgba($french-pass, 0.75);
  border-radius: $main-border-radius;
  color: $secondary-color;
}

.clientBannerContent {
  flex: 1;
  font-size: 14px;
  line-height: 21px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.title {
  font-weight: 600;
}

.link {
  color: $secondary-color !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: right !important;
  text-decoration-line: underline !important;
  cursor: pointer;

  &:hover {
    color: $black !important;
  }
}

.modalTitle {
  font-size: 18px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: left;
  max-height: 50vh;
}

.helperText {
  color: $secondary-color;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
}

.cashDominionCustomerContainer {
  display: flex;
  flex: 1;
  gap: 20px;
  overflow-y: auto;
}

.cashDominionCustomerColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.cashDominionCustomerHeader {
  padding-bottom: 7px;
  color: $secondary-color;
  font-size: 14px;
  line-height: 21px;
  font-weight: 800;
  border-bottom: 1px solid $button-border-color;
}

.cashDominionCustomerContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.cashDominionCustomerRow {
  display: flex;
  padding: 12px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-size: 12px;
  line-height: 15px;
  color: $secondary-color;

  &:nth-child(odd) {
    background-color: $row-background-color;
  }
}

.cashDominionCustomerRowLabel {
  font-weight: 700;
  color: $slate-gray;
}

.cashDominionCustomerRowValue {
  font-weight: 700;
  text-align: right;
}
