@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables';

.mainCard {
  height: 100%;
}

.mainCardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.subHeader {
  font-size: $header2-font-size;
  font-weight: bold;
  color: black;
}

.actionTitle {
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: bold;
  color: $secondary-color;
}

.requestFundingWrapper {
  padding-top: 10px;
}

.cardInProgress {
  width: 1400px;
  padding-top: 55px;
  padding-bottom: 110px;

  @media only screen and (max-width: 1599px) {
    width: 1000px;
  }

  @media only screen and (max-width: 1279px) {
    width: 700px;
  }
}

.cardInProgressWrapper {
  padding-bottom: 20px !important;
}

.descriptionInProgress {
  color: $slate-gray;
  font-size: 14px;
  max-width: 292px;
}

.documentItem {
  padding: 4px 0;
}

.documentItemContainer {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.documentItemTitle {
  font-size: $main-font-size;
  font-weight: 700;
  color: $blue-wale;
  margin-bottom: 10px;
}

.documentItemFiles {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: 423px;
}

.inputsBlock {
  position: relative;
  margin-top: 3px;
}

.amountTypeRadios {
  display: flex;
  flex-direction: column;

  label {
    margin-left: 0 !important;
    height: 35px;

    :global {
      .MuiTypography-root {
        font-weight: normal;
      }
    }
  }
}

.inputContainer {
  position: absolute;
  top: 40px;
  left: 148px;

  :global {
    .MuiFormHelperText-root {
      margin-top: 0;
      text-align: end;
    }
  }
}

.textInput {
  margin-top: 6px;
  width: 100px;
  height: 24px;

  :global {
    .MuiInputBase-input {
      padding: 2px 12px;
      line-height: 20px;
    }
  }
}

.documentItemFilesItem {
  display: block;
  margin-top: 4px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.iconSuccess,
.iconPending {
  align-items: center;
  margin-bottom: 8px;
}

.stepIcon {
  height: 24px;
  width: 24px;
  color: $slate-gray;
}

.stepIconActive {
  color: $blue-gray !important;
}
.stepIconInactive {
  margin-top: 4px;
  margin-bottom: 4px;
}

.completedStepConnector {
  .stepConnectorLine {
    border-color: $primary-color;
  }
}

.activeStepConnector {
  .stepConnectorLine {
    border-color: $primary-color;
  }
}
.stepConnectorAlternativeLabel {
  left: calc(-50% + 30px);
  right: calc(50% + 30px);
}

.stepConnectorLine {
  border-color: $blue-gray;
  border-top-width: 1px;
  border-radius: 1px;
}

.stepLabelActive {
  color: $primary-color !important;
}

.dueReportList {
  margin: 0 auto 15px;
}

.dueReport {
  display: flex;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  color: $blue-wale;
}

.dueReportMark {
  position: relative;
  margin-right: 9px;
}

.dueReportStatus {
  position: absolute;
  left: 10px;
  bottom: 2px;
}

.reportUploadText {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  color: $blue-wale;
}

.radioLabel {
  font-size: 14px;
  font-weight: 600;
  color: $black;
}

.radio {
  padding-left: 12px;
  margin-bottom: 11px;
}

.radioIcon {
  color: $primary-color;
}

.trackFundsLink {
  font-size: 14px;
  font-weight: 600;
  color: $primary-color;
  text-decoration-line: underline;
  cursor: pointer;
}

.header {
  margin-bottom: 20px;
}

.currncyRoot {
  height: 48px;
}

.specificAmount {
  margin-bottom: 0px !important;
}

.amountField {
  padding-left: 12px;
}

.submitButton {
  padding: 0 82px 0 0 !important;
  align-self: flex-end;
}

.gridContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
