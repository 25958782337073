@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.rootChip {
  justify-content: flex-start;
  padding: 2px 4px;
  border-radius: $small-border-radius;
  display: inline-block;
  position: sticky;

  right: 115px;
  top: 3px;
  line-height: 16px;
  max-width: 60px;
  text-align: right;
  @media only screen and (max-width: 1845px) {
    right: 0%;
  }

  color: $red-text;
  background: $red-background;
  .MuiTableCell-root {
    text-align: right;
  }

  font-size: 12px;
  &.down {
    color: $red-text;
  }
  &.up {
    color: $green-text;
    background: $green-background;
  }
}

.root {
  justify-content: flex-start;
  text-align: left;
  .MuiTableCell-root {
    text-align: left;
  }

  font-size: 12px;
  svg {
    font-weight: 700 !important;
    margin-right: 5px;
  }
}

.up {
  color: #66b485;
}
.upChevron {
  fill: #66b485;
}

.down {
  color: #cc5858;
}
.downChevron {
  fill: #cc5858;
}
