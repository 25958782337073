@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.typeFilter {
  background-color: white;
  color: $input-border-color;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid $input-border-color;
  border-radius: $small-border-radius;
  width: 100%;
  height: 48px;
  text-align: left;
}

.amountFilter {
  width: 150%;
}

.typeFilterActive {
  background-color: white;
  color: $black;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid $input-border-color;
  width: 100%;
  height: 48px;
  text-align: left;
  border-radius: $small-border-radius;
}

.filterPopover {
  width: 100%;
}

.filterPopoverPaper {
  box-shadow: 0 2px 8px rgba(35, 47, 53, 0.09);
  width: 250px;
}

.filterButton {
  background-color: white;
  color: $input-border-color;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  height: 48px;
  justify-content: flex-start;
  border: 1px solid $input-border-color;

  &:hover {
    background-color: $white;
    color: $p-color;
    border: 1px solid $input-border-color;
  }
}

.filterDownIcon {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.filterButtonActive {
  color: $black !important;
  border: none;
  &:hover {
    border: none !important;
  }
}

.transparent {
  background-color: transparent;
}

.filterButtonActive:hover {
  background-color: $white;
  color: $p-color;
}

.buttonFocused {
  border: none;
}

.dateFilterGrid {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: $white;
}

.filterTitleHeader {
  position: absolute;
  top: 0;
}

.applyButton {
  width: 100%;
  color: $white;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  height: 36px;
}

.autocompleteField {
  > div > div {
    margin-top: 0px;
  }
}

.filterMenuPopoverPaper {
  width: 680px;
  padding: 20px;
}

.filterMenuViewMoreButton {
  height: 15px;
  margin-top: 10px;
  padding: 0;
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  &:hover {
    border: none;
    outline: none;
  }
}

.filterBox {
  .filterButtonText {
    display: inline-block;
    max-width: calc(100% - 28px);
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    color: $transparent-gray;

    &::placeholder {
      opacity: 1;
    }
  }

  &:hover .filterButtonText {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }

  &:focus .filterButtonText {
    font-size: 14px;
    font-weight: 600;
    color: $transparent-gray;
  }

  &:focus:hover .filterButtonText {
    color: $transparent-gray !important;
  }

  &:hover .iconSelected {
    > path {
      fill: $transparent-gray !important;
    }
  }
  &:hover .iconSelectedPound {
    > path {
      stroke: $transparent-gray !important;
    }
  }

  &:hover .startAdornmentAmount {
    > path {
      fill: $black;
    }
  }
  &:hover .startAdornmentPound {
    > path {
      stroke: $black;
    }
  }
}

.filterButtonTextSelected {
  font-weight: 400;
  color: $black !important;

  &:hover {
    font-weight: 400 !important;
    color: $black !important;
  }
}

.withTopLabel {
  :global {
    .MuiTouchRipple-root {
      padding: 0;
      font-size: 12px;
      transform: translate(0, -3px) scale(0.75) !important;
      opacity: 1;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.filterBox {
  position: relative;
}

.startAdornment {
  width: 20px;
  height: 20px;

  > path {
    width: 18px;
    height: 11px;
  }
}

.startAdornmentAutocomplete {
  margin-left: 10px;
}

.startAdornmentAmount {
  width: 16px;
  height: 16px;
  padding-left: 2px;

  > path {
    width: 18px;
    height: 11px;
  }
}

.calendarIcon {
  path {
    stroke: $transparent-gray;
  }
}

.quickFiltersContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.quickFiltersLabel {
  color: $secondary-color;
  font-size: 12px;
  font-weight: 400;
}

.quickFiltersItem {
  padding: 4px 8px;
  margin-left: 0;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.quickFiltersItemActive{
  color: $white;
  background: $primary-color;
}
