.container {
  display: inline-flex;
  align-items: center;
  position: relative;
  
  &.left {
    flex-direction: row;
  }
  
  &.right {
    flex-direction: row-reverse;
  }
  
  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    color: #666;
    position: relative;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease, color 0.2s ease;
    
    &:hover {
      color: #333;
    }
    
    svg {
      transition: transform 0.2s ease;
    }
    
    &:active svg {
      transform: scale(0.9);
    }
  }
  
  &:hover .iconWrapper {
    opacity: 1;
  }
}

.content {
  margin: 0 4px;
}

.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  animation: fadeIn 0.3s, fadeOut 0.3s 1.7s;
  z-index: 1;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
} 

.icon {
  width: 16px;
  height: 16px;
}
