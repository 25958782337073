@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins';

.container {
  padding: 10px 18px;
  // 20px = page offset, 25px = MarketNewsPage cardContainer margin-top, 54px = MarketNewsTable filterContainer, 20px = table padding
  max-height: calc(100vh - 20px - $header-height - 25px - 54px - 20px) !important;
  overflow-y: auto !important;
  height: 95%;
}

.containerWithFilter {
  height: 90% !important;
}

.table {
  // 20px = page offset, 25px = MarketNewsPage cardContainer margin-top
  height: calc(100vh - 20px - $header-height - 25px);
}

.infiniteScroll {
  overflow: visible !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item {
  line-height: 19px;
  padding: 12px 20px;
  background: $white;
  cursor: pointer;

  :global {
    .MuiCardContent-root {
      flex-direction: column !important;
    }
  }
}

.titleRectangle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
}

.title {
  padding: 10px 14px 2px 14px !important;
  height: 452px;
  background-color: $white !important;
  border-radius: $main-border-radius !important;
}

.title > div:first-child {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.itemIcon {
  width: 16px;
}

.itemData {
  flex: 1 calc(100% - 16px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.itemDescription {
  font-size: 10px;
  font-weight: 700;
  color: $blue-header;

  display: inline-block;
  line-height: 14px;
  padding: 4px 8px;
  border-radius: $small-border-radius;
  color: $secondary-color;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  > svg {
    top: 0px;
    position: absolute;
    > path {
      fill: transparent;
    }
  }
}

.itemHeadlineTitle {
  color: var(--Greyscale-900, #1a202c);
  font-size: 15px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  max-width: 97%;
}

.itemDate {
  font-size: 10px;
  font-weight: 500;
  color: $slate-gray;
}

.itemSubtitles {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  align-items: center;
}

.itemSubtitlesHover:hover {
  text-decoration: underline;
}

.noNews {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding-top: 25px;
}

.editTitle {
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  font-weight: 600;
}

.newsContainer {
  width: 62.5%;
}

.filterContainer {
  padding: 15px 18px 0 18px;
  > div > div {
    padding-bottom: 0px;
  }
}

.tagsContainer {
  display: flex;
  gap: 6px;
}

.linkedAccounts {
  font-size: 14px;
  font-weight: 400;
}

.linkedAccountsTitle {
  padding-right: 5px;
  color: $transparent-gray;
}

.isCurrentItem {
  border-color: $primary-color !important;
}
.tableRoot {
  // 20px = page offset, 25px = MarketNewsPage cardContainer margin-top, 54px = MarketNewsTable filterContainer
  height: calc(100vh - 20px - $header-height - 25px - 54px);
}

.datePickerContainer {
  width: 100% !important;
  padding-top: 18px;

  :global {
    .MuiInput-root {
      width: 100% !important;
      height: 48px !important;
    }
    .MuiTextField-root {
      width: 100% !important;
      height: 48px !important;
    }
  }
}

.submitButton {
  width: 100%;
  height: 56px;
}

.summarizeButton {
  height: 32px;
  width: 32px;
}
