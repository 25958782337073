@import "../../assets/styles/colors";

.wrapper {
  border-style: solid;
  border-color: transparent;
  border-width: 90px 90px 0 0;
  border-top-color: $yellow-text;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1299;
}

.label {
  position: fixed;
  left: -20px;
  top: 22px;
  font-size: 18px;
  line-height: 27px;
  color: $white;
  font-weight: 700;
  width: 110px;
  text-align: center;
  rotate: -45deg;
  text-transform: uppercase;
}
