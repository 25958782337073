@import '../../assets/styles/colors.scss';

.container {
  width: 100%;
}

.datePickerContainer {
  margin-top: 10px;
  width: 400px;
}

.previewButton {
  cursor: pointer;
  color: $primary-color;
}

.previewButtonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
