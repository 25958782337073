@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
  text-transform: none !important;
  font-weight: 500;
  font-size: $header3-font-size;
  line-height: 20px;
  height: 48px;
  background-color: $primary-color;
  color: $white;
  padding: 8px 30px;
  box-shadow: none;
  border-radius: $small-border-radius;
  white-space: nowrap;

  &:hover {
    background-color: $cerulean;
    box-shadow: none;
  }
}

.loading {
  background-color: $primary-color !important;
  color: transparent;
}

.loadingIndicator {
  svg {
    color: $white;
  }
}

.disabled {
  background-color: $perano;
  pointer-events: none;
}

.secondary {
  color: $primary-color;
  background: none;
  border: 1px solid $primary-color;
  box-shadow: none;

  &:hover {
    color: $cerulean;
    border-color: $cerulean;
    background: none;
    box-shadow: none;
  }

  &.loading {
    background: none !important;
    border: 1px solid $primary-color;
    color: transparent !important;

    .loadingIndicator {
      svg {
        color: $primary-color;
      }
    }
  }
}

.secondary.disabled {
  color: $perano;
  border-color: $perano;
  background: none;
}

.error {
  color: $white;
  background-color: $red-text;
  border: 1px solid $red-text;
  box-shadow: none;

  &:hover {
    background-color: $red-background;
    color: $red-text;
    box-shadow: none;
  }
}

.error.disabled {
  background: $sundown;
  border-color: $sundown;
}

.small {
  line-height: 16px;
  padding: 8px;
  border-radius: $small-border-radius;
  font-size: 14px;
  height: 32px;
}

.activeBorder {
  border: 1px solid $primary-color !important;
}

.fieldset {
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  border-radius: inherit;
  border: 1px solid $input-border-color;

  height: 48px;
}

.legend {
  position: absolute;
  top: -6px;
  left: 8px;
  display: block;
  padding: 0 8px;
  height: auto;
  font-size: 0.75em;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
  background: white;
  line-height: 12px;
  text-align: left;
  margin-left: 2px;
  visibility: visible;

  > span {
    font-size: 10px !important;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 350;
  }
}

.endIcon {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.borderSelected {
  border: 1px solid $black;
}
