.table {
  text-align: left;
  border-spacing: 0;
}

.column {
  padding-bottom: 12px;

  &:first-child {
    width: 1%;
    vertical-align: top;
    padding-top: 7px;
    white-space: nowrap;
  }
}
