@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.chip {
  background-color: $catskill-white;
  padding: 5px 10px 5px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.64px;

  border-radius: 5px;
  // shift up slightly
  margin-top: -10px;
  margin-left: 10px;
}

.filterItems {
  width: 100%;
  background-color: $row-background-color;
  border-radius: 5px;
  padding: 3px 10px;
  text-align: left;
}

.filterIcon {
  // center on the line
  margin-top: 2px;
  margin-right: 5px;
}
