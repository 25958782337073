@import '../../assets/styles/colors';

.integrationsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 20px;
  line-height: 1.25;
  font-weight: 800;
  margin-bottom: 16px;
}

.description {
  color: $slate-gray;
  font-size: 14px;
  line-height: 1.25;
  margin-bottom: 35px;
}

.connectButton {
  margin-top: 25px;
  background: $success-green;

  &:hover {
    background: $currency-up;
  }
}
