@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.tableContainer {
  tbody {
    max-height: unset !important;
    height: 60vh;

    @media only screen and (max-height: 800px) {
      height: 70vh;
    }
  }

  tr {
    height: 32px;
    td,
    th {
      &:nth-child(5) {
        width: 150px;
      }

      &:nth-child(6) {
        width: 180px;
      }

      &:nth-child(7) {
        width: 60px;
      }
    }

 
  }
}

.textCell {
  text-align: unset !important;

  :global {
    .MuiFormControl-root {
      align-items: start !important;
    }
  }
}

.roleSelect {
  width: 170px !important;
  max-width: 170px !important;
  text-align: left !important;

  :global {
    .MuiSelect-root,
    .MuiSelect-icon {
      border-radius: $main-border-radius;
    }
  }
}

.textInput {
  :global {
    .MuiInput-root {
      border-radius: $main-border-radius;
    }

    .MuiInputBase-input {
      padding: 2px 7px;
    }
  }
}

.active {
  font-size: 12px;
  background-color: $active-color;
  color: $dark-blue;
  border-radius: $main-border-radius;
  padding: 3px 7px;
  line-height: 14px;
  display: inline-block;
}

.inviteButton {
  padding: 4px 8px;
  height: 26px;
}

.filtersHeader > div > div {
  align-items: center !important;

  button {
    text-transform: capitalize;
  }
}

.relations {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 20px;
  font-size: 12px;
  line-height: 14px;
  background: $derby;
  border-radius: $main-border-radius;
  text-align: center;
  cursor: pointer;

  > *:not(:last-child) {
    margin-right: 4px;
  }
}

.relationsDisabled {
  opacity: 0.5;
}

.assignedModalNameContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.assignedModalNameLabel {
  color: $gothic;
  font-size: $main-font-size;
  line-height: 20px;
}

.assignedModalName {
  color: $dark-blue;
  font-size: 16px;
  line-height: 20px;
}

.assignedModalList {
  margin: 0;
  padding: 0;

  > *:not(:last-child) {
    margin-bottom: 9px;
  }
}

.assignedModalListItem {
  display: flex;
  width: fit-content;
  padding: 2px 6px;
  align-items: center;
  list-style: none;
  background: $derby;
  border-radius: $main-border-radius;
}

.assignedModalListItemClientName {
  margin-right: 6px;
  font-size: $main-font-size;
  line-height: 20px;
  color: $dark-blue;
}

.assignedModalListItemDelete {
  width: 14px;
  height: 14px;
}

.addNewUserCell {
  display: flex;
  justify-content: flex-start;
}

.addNewUser {
  margin-top: 10px;
}

.actionsContainer {
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.userManagementTab {
  padding: 4px 8px !important;
  margin-left: 0;
}

.submitModalButton {
  width: 100%;
  margin-top: 30px;
  min-height: 52px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.fieldsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  td {
    padding-bottom: 2px !important;
    width: 100%;
  }

  label {
    font-weight: 600;
    font-size: 14px;
    color: $secondary-color;
  }
}

.checkboxContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: -8px;
  padding-top: 10px;

  td {
    padding-bottom: 2px !important;
    padding-top: 6px !important;
  }

  label {
    font-weight: 600;
    font-size: $header3-font-size;
    color: $secondary-color;
  }
}

.clientsTitle {
  display: flex;
  font-size: $header3-font-size;
  font-weight: 600;
  margin-bottom: 14px;
  padding-left: 8px;
}
