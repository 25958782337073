@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/variables.scss';

body {
  min-width: $min-width;
  font-size: $main-font-size;
  line-height: 20px;

  @media (max-width: calc($min-width - 1px)) {
    overflow: scroll;
  }
}

.container {
  min-width: $min-width;
  display: flex;
  min-height: 100%;
}


.content {
  min-width: $min-width - $drawer-collapsed-width - $drawer-margin;
  flex: 1;
  margin-top: $header-height;
  overflow: auto;
  justify-content: center;
  display: flex;

}
.childrenContent{
  padding: 0 15px 0 15px;
  width: 100%;
}

.childrenContentClient{
  width: 1300px;

}

.blankLayoutContent {
  height: 100%;
  margin-left: 0;
  margin-top: 0;
}


.notesContainer {
  margin-top: 60px;
}

.loggedAsClientContent {
  margin-top: $header-height + $login-as-client-container-height;
}

.loginAsClientContainer {
  z-index: 1100;
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  width: 100%;
  height: $login-as-client-container-height;
  background: $vis-vis;
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 14px;
  line-height: 1.5;
  padding-left: $drawer-collapsed-width + $drawer-margin;

  &.loginAsClientContainerExpanded {
    padding-left: $drawer-expanded-width + $drawer-margin;
  }

  &.loginAsClientContainerProspect {
    padding-left: 24px;
  }

  .loginAsClientContainerLink {
    color: $primary-color;
    text-decoration: underline !important;
    cursor: pointer;
  }
}
