@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/forms";

.clientIntakeWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.clientIntakeCard {
  width: 100%;
  max-width: 865px;
  padding: 40px 56px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 24px;
  box-shadow: 0 2px 2px rgba($black, 0.05);
  border: none;
}

.clientIntakeCardContent {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formContent {
  width: 100%;
  max-width: 520px;
}

.buttonDivider {
  width: 100%;
  height: 1px;
  background: $border-color;
  margin: 16px auto 16px;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.inputLabel {
  text-align: left;
  color: $secondary-color;
  letter-spacing: 0.2px;
  font-weight: bolder;
}

.inputField {
  :global {
    .MuiInputBase-input {
      font-weight: normal;
    }
  }
}

.selectField {
  :global {
    .MuiSelect-select {
      line-height: 30px;
      border-radius: $small-border-radius;
      font-size: 14px;
      outline: none !important;
      border: 1px solid $input-border-color;
    }

    .MuiSelect-icon {
      top: 12px
    }
  }
}

.datePickerField {
  width: 100%;

  :global {
    .MuiInput-root {
      width: 100% !important;
      height: 48px;
      padding: 8px;

      .MuiInputBase-input {
        font-size: 14px;
      }
    }
  }
}

.autocompleteField {
  :global {
    .MuiAutocomplete-inputRoot {
      min-height: 48px;
      height: 48px;
      border: 1px solid $input-border-color;
      border-radius: 5px;
      padding: 8px 36px 8px 8px !important;

      .MuiInputBase-input {
        font-size: 14px;
      }

      .MuiAutocomplete-endAdornment {
        right: 5px;
      }

      &:before {
        display: none;
      }
    }
  }
}

.button {
  height: 48px;
  min-width: 125px;
}

.clientIntakeSuccessCard {
  padding: 40px;
  display: flex;
  width: 500px;
  height: 420px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white;
  border-radius: 16px;
  box-shadow: 0 2px 2px rgba($black, 0.05);
  gap: 20px
}

.clientIntakeSuccessTitle {
  font-size: 32px;
  line-height: 1.25;
  font-weight: 800;
  color: $secondary-color;
}

.clientIntakeSuccessDescription {
  font-size: 16px;
  font-weight: 500;
  color: $p-color;
  text-align: center;
}
