@import '../../assets/styles/colors';
@import '../../assets/styles/variables';


.dashboardBlock {
  position: relative;
  padding: 14px 24px;
  border-radius: $main-border-radius;
  min-height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.participantSettingsButton {
  display: inline-flex;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  padding: 7px 8px;
  border-radius: 5px;
  background: none;
  color: $primary-color;
  border: 1px solid $primary-color;
  box-shadow: none;

  &:hover {
    color: $cerulean;
    border-color: $cerulean;
  }
}

.infoItems {
  margin-top: 15px;
}

.gridContainer {
  flex-direction: column;
}



.gridContainerWrapper {
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px 14px;
  gap: 26px;
  position: relative;
}


.dashboardBlockTitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: $secondary-color;
}

.dashboardBlockContent {
  display: flex;
  font-size: $main-font-size;
  line-height: 20px;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
}

.dashboardBlockContentIcon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.dashboardBlockContentIconEdit {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  svg {
    fill: $slate-gray;
    width: 32px;
    height: 32px;
  }
}

.dashboardBlockContentLabel {
  color: $gothic;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.dashboardBlockContentValue {
  color: $dark-blue;
  font-weight: 600;
  justify-content: flex-start;
  font-size: 18px;
  padding-top: 7px;
}

.dashboardBlockContentValueDescription {
  font-size: 14px;
}

.dashboardBlockContentValueNumbers {
  color: $dark-blue;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding-top: 7px;
}
.headerContainer {
  padding-bottom: 20px;
}