@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables";

.table {
  tbody {
    max-height: 350px !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 25%;
      }

      &:nth-child(2) {
        width: 14%;
      }

      &:nth-child(3) {
        width: 14%;
      }

      &:nth-child(4) {
        width: 47%;
      }
    }
  }
}

.tableHeaderContainer {
  display: flex;
  padding-bottom: 5px !important;

  div {
    padding-bottom: 0 !important;
  }
}
