@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.container {
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 24px 14px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.title {
  font-size: 18px;
  font-weight: 800;
}

.editLink {
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
}

.data {
  font-size: 12px;
  line-height: 15px;
}

.row {
  padding: 12px;
  border-top: 1px solid $border-color;
  display: flex;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: $white;
  }

  &:nth-child(odd) {
    background-color: $row-background-color;
  }
}
