@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.avatar {
    height: 140px;
    width: 140px;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 64px;
    color: $secondary-color;
    background-color: $white !important;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
    position: relative;
  }
  
  .avatarContainer {
    height: 130px;
    width: 130px;
    justify-content: center;
    align-items: center;
    display: flex;
  
  }
  
  .avatarImg {
    max-height: 130px;
    max-width: 130px;
  }
  
  .headerTitle {
    text-align: left;
    color: $transparent-gray;
    font-size: 14px;
    font-weight: 500;
  }
  
  .headerValue {
    text-align: left;
    color: $secondary-color;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
  }
  
  .infoDivider {
    width: 100%;
    border-bottom: 1px solid $gallery;
    padding-top: 12px;
    margin-bottom: 12px;
  }
  
  .editLink {
    font-size: 12px;
    line-height: 23px;
    margin-left: 10px;
  }
  

.borrowingBaseCard {
  margin-top: 24px;
  margin-bottom: 24px;
}

.subTitle {
    font-size: 12px;
    color: $secondary-color;
    font-weight: 700;
  }

.link {
  text-decoration: none;
  color: $primary-color;
}

.avatarGridContainer {
  max-width: 220px !important;
}

.headerContainer {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  align-items: center;
  margin-top: 16px;
  min-height: 140px;
}

.contentContainer {
  flex: 1;
  margin-bottom: 8px;
  height: 100%;
  display: flex;
  align-items: center;
}

.avatarGridContainer {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 140px;
}