@import '../../../assets/styles/colors';

:global {
  .MuiSnackbar-root {
    flex-direction: column !important;
    align-items: flex-end !important;
  }

  .MuiAlert-root {
    margin-bottom: 7px;
  }
}

.snackbarSuccess {
  /* Alert
Float message type you can show when there is need to highlight some info or request user to do something
*/

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: left;
  gap: 16px;

  background: $success-alert-background;
  /* Action/Error */

  border: 1px solid $success-green;
  border-radius: 5px;
  color: $alert-text;
  font-weight: bold;
}

.snackbarInfo {
  /* Info

Float message type you can show when there is need to highlight some info or request user to do something
*/

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: left;
  gap: 16px;

  background: rgba($blue-background, 0.5);

  border: 1px solid $primary-color;
  border-radius: 5px;
  color: $alert-text;
  font-weight: bold;
}

.snackbarError {
  /* Alert

Float message type you can show when there is need to highlight some info or request user to do something
*/

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: left;
  gap: 16px;

  background: rgba(255, 177, 177, 0.5);
  /* Action/Error */

  border: 1px solid $red-border;
  border-radius: 5px;
  color: $alert-text;
  font-weight: bold;
}
