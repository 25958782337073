@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  color: $primary-color;
  margin-right: 20px;
  min-width: 90px;

  &.actionsButton {
    min-width: auto;
    margin-right: 0;
    margin-left: 5px;
  }
}

.linkButton {
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  height: 32px;
  color: $primary-color;
  background: none;
  border: 1px solid $primary-color;
  box-shadow: none;
  vertical-align: middle;

  &:hover {
    color: $cerulean;
    border-color: $cerulean;
  }
}

.tabContainer {
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 6px;
}



.headerSummaryContainer {
  padding-left: 24px !important;
  margin-bottom: 20px;
}

.submitButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  min-width: 80px;
}

.warningIcon {
  margin-right: 4px;
  padding-top: 1px;
  vertical-align: middle;
  color: $sunshade !important;
  stroke: $sunshade;
  height: 150%;
}

.warningButton {
  color: $sunshade !important;
  border-color: $sunshade !important;
}

.iconWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin-right: 20px;
}

.exportIcon {
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.blockHeading {
  margin: 10px 0;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: $secondary-color;
}

.inventoryCharts {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.titleWrapper {
  display: flex;
  gap: 10px;
}

.backButton {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 20px;
  font-weight: 600;
  color: $primary-color;
  cursor: pointer;
}

.backIcon {
  vertical-align: middle;
}

.icons {
  font-size: 10px;
  height: 22px;
  width: 23px;
}
