@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.description {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.notificationsList {
  margin-top: 10px;
  max-height: calc(100vh - 255px);
  overflow-y: auto;
}

.notificationItemRoot {
  max-width: 750px;
  &:before {
    flex: 0;
    padding: 0;
  }
}

.notificationItemDot {
  background: $border-color;
  box-shadow: none;
}

.notificationItemConnectorTransparent {
  background: transparent;
}

.notificationItemConnector {
  background: $input-border-color;
  width: 1px;
}

.notificationItemContent {
  min-height: 77px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  &:hover {
    background: $gray-background-color;
    cursor: pointer;
  }
}

.notificationItemLoader {
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  color: $secondary-color;
}

.notificationItemDate {
  flex: 0 0 140px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: $slate-gray;

}

.notificationItemContainer {
  flex: 1 1 calc(100% - 140px - 24px - 45px - 24px - 24px - 12px); // Date - Gap - Avatar - Gap - Gap - Unread icon
}

.notificationItemAvatar {
  height: 45px;
  width: 45px;
}
