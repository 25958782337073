@import '../../assets/styles/colors';
@import '../../assets/styles/variables.scss';

.table {
  tr {
    height:32px;
    td,
    th {
      &:nth-child(1) {
        width: 40%;
      }
    }

    .infoPreviewIcon {
      display: none;
    }

    &:hover {
      .infoPreviewIcon {
        display: inline;
      }
    }
  }

  &.tableLocation {
    tr {
      td,
      th {
        &:nth-child(1) {
          width: 22%;
        }
        &:nth-child(2) {
          width: 14%;
        }
        &:nth-child(3),
        &:nth-child(6) {
          width: 70px;
        }
        &:nth-child(4) {
          width: 16%;
        }
        &:nth-child(5) {
          width: 18%;
        }
        &:nth-child(7) {
          width: 100px;
        }
      }

      .infoPreviewIcon {
        display: none;
      }

      &:hover {
        .infoPreviewIcon {
          display: inline;
        }
      }
    }
  }

  tr {
    &.newRow {
      background-color: $mabel !important;
    }
  }
}

.locationName {
  display: grid;
}

.originalLocationName {
  font-size: 10px;
  color: $raven;
}

.fieldsList {
  width: 120px;
}

.dashboardBlockTitle {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: $black;
  position: relative;
  left: -5px;
  bottom: -1px;
}

.dashboardBlockContentIconEdit {
  width: 24px;
  height: 24px;

}
.ineligbleFieldsCard {
  padding: 2px 10px;
  overflow: visible;
}
.deleteBadge {
  position: absolute;
  right: -10px;
  top: -12px;
}
.deleteIcon {
  height: 26px;
  width: 26px;
  stroke: $slate-gray;
  > svg {
    height: 26px;
    width: 26px;
  }
}

.fullScreenModal {
  overflow-y: auto;

  table {
    tbody {
      max-height: calc(95vh - 215px) !important;
    }
  }

  .cardContainer {
    height: calc(95vh - 30px);

    :global {
      .MuiCardContent-root {
        height: 100%;
      }

      .MuiTableContainer-root {
        max-height: calc(95vh - 55px);
      }
    }
  }
}

.fullScreenCard {
  padding: 24px !important;
}
