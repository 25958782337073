@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.burgerMenuIcon {
  position: fixed;
  top: calc(($header-height - 24px) / 2);
  left: $drawer-collapsed-width + $drawer-margin + 10px;
  z-index: 1102;
  width: 24px;
  height: 24px;
  color: $icon-color;
  cursor: pointer;
  transition: all 0.2s linear;
}

.wrapper {
  background: $light-gray;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 0px 27px 0 27px;

  a {
    text-decoration: none;
  }
}

.drawer {
  transition: width 0.2s linear;
  overflow: hidden;
  z-index: 1101;
}

.drawerPaper {
  width: $drawer-collapsed-width;
  background: none;
  border-right: 1px solid $border-color;
  overflow: hidden;
  transition: width 0.2s linear;
}

.logoWrapper {
  position: 0px;
  left: 10px;
  top: 20px;
}

.expanded {
  width: $drawer-expanded-width;
  z-index: 1101;

  .active {
    .navItem {
      background-color: $primary-color;
    }
  }

  .navItem {
    width: 202px;
    padding: 6px 16px 6px 0px;
  }

  .drawerPaper {
    width: $drawer-expanded-width;
  }

  .wrapper {
    align-items: flex-start;
  }

  .toggleButton {
    svg {
      margin-right: 22px;
    }
  }

  .dropdownIcon {
    left: 12px;
  }

  .burgerMenuIcon {
    left: $drawer-expanded-width + $drawer-margin + 10px;
  }

  .logo {
    left: 15px;
    width: 200px;
  }

  .profileWrapper {
    width: $drawer-expanded-width - 20px;
  }

  .sideMenuItem {
    transition: background-color 0.2s linear;
    border-radius: $main-border-radius;
    color: $slate-gray;
  }
  :global {
    #pageTitle {
      margin-left: $drawer-expanded-width + $drawer-margin;
      transition: all;
    }
  }
  + div {
    margin-left: $drawer-expanded-width - $drawer-expanded-width;
    transition: all;
  }
}

.sideMenuItems {
  margin-top: 0px;
}

.collapsed {
  width: $drawer-collapsed-width;
  .collapsedIcon {
    display: flex;
    justify-content: center;
  }
  .navItem {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .sideMenuItems {
    margin-top: 20px;
  }
  .logo {
    width: 30px;
    height: 30px;
    margin-top: 20px;
  }
  .icon {
    width: 33px;
    height: 33px;
    border-radius: 5px;
  }

  .active {
    .collapsedIcon {
      height: 33px;
      width: 33px;
      border-radius: 5px;
      background: $primary-color;
      margin-left: 12px;
    }
  }
}

.sideMenuItem {
  width: 100%;

  + .sideMenuItem {
    margin-top: 0px;
  }
}

.navItem {
  &:hover {
    background-color: transparent;
  }
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 16px 6px 16px;
  height: 56px;

  .label {
    font-size: 14px;
    color: $slate-gray;
    font-weight: 600;
  }
}

.icon {
  width: 59px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.colorIcon {
  > svg path {
    width: 24px;
    height: 24px;
    stroke: $slate-gray;
  }
}

.nestedNav {
  background-color: $light-blue-gray;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0;
  width: 60px;
}

.nestedItem {
  font-size: $main-font-size;
  min-width: unset;
  padding: 0;
  color: $dark-blue;
  position: relative;
}

.active {
  .navItem {
    justify-content: flex-start;
    border-radius: 10px;
  }
  .label {
    color: $white;
  }
  .colorIcon {
    > svg circle,
    svg ellipse,
    svg path,
    rect {
      stroke: $white;
    }
  }
  .exportIcon,
  .activityIcon {
    circle,
    ellipse,
    path {
      fill: $white;
    }
  }
}

.toggleButton {
  display: flex;
  justify-content: space-between;
  padding: 14px 25px;
  border: 1px solid $light-blue-gray;
  border-radius: $main-border-radius;
  color: $dusty-gray;
  text-transform: unset;
  height: 40px;
}

.navItemExpanded {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  .icon {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.isHovered {
  background-color: $thin-blue;
  transition: none;
}

.expandableLabel {
  position: absolute;
  left: 60px;
  margin-left: 15px;
  color: $bright-navy-blue;
  width: 170px;

  &:hover {
    color: $navy-blue;
  }
}

.expandableIcon {
  position: absolute;
  bottom: 3px;
}

.nestedIcon {
  background-color: transparent;

  &:hover {
    path {
      fill: $navy-blue;
    }
  }
}

.profileWrapper {
  width: $drawer-collapsed-width - 20px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.paddingTop10 {
  padding-top: 10px;
}

.profileMenuItem {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  color: $slate-gray;
  cursor: pointer;

  &:first-child {
    margin-bottom: 15px;
  }
}

.profileIcon {
  width: 40px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > svg path,
  rect {
    width: 16px;
    height: 16px;
    fill: $black;
  }
}

.labelName {
  font-size: 18px;
  font-weight: 700;
  margin-left: 10px;
}

.activityIcon {
  path,
  rect {
    stroke: none !important;
  }
}

.smallLogo {
  max-width: 30px !important;
  max-height: 30px !important;
}


.prospectsIcon{
  height: 35px;
  margin-top: 5px;
}

.prospectsIconActive{
  height: 35px;
  margin-top: 5px;
  fill: $white;
}

.skeleton {
  border-radius: $small-border-radius;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $border-color;
  margin: 10px 0;
}

.loggedAsClientDrawer {
  .burgerMenuIcon {
    top: calc(($header-height - 24px) / 2 + $login-as-client-container-height);
  }
}
