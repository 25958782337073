@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.wrapper {
  line-height: 15px;
  display: inline-block;
  margin-left: 2px;
  vertical-align: middle;

  &.isOpen {
    .icon {
      display: inline !important;
    }
  }
}

.popover {
  width: 520px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.popoverSmall {
  min-width: 200px;
  width: auto;
}

.row {
  display: flex;
  align-items: center;
  gap: 3px;

  &.dataRow {
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  .dataBlock {
    align-self: stretch;
  }
}

.avatarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 73px;
  height: 73px;
}

.avatarImg {
  width: 73px;
  max-height: 73px;
}

.name {
  color: $secondary-color;
  font-size: 18px;
  font-weight: 700;
}

.description {
  color: $secondary-color;
  font-size: 12px;
  line-height: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* Number of lines you want to clamp */
  line-clamp: 3; /* Number of lines you want to clamp */
}

.dataBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex: 1;
  padding: 6px 0;
}

.dataLabel {
  color: $transparent-gray;
  font-size: 12px;
  line-height: 15px;
  align-self: flex-start;

  &.dataLabelLong {
    width: 73px;
    min-width: 73px;
  }
}

.dataValue {
  color: $secondary-color;
  font-size: 12px;
  line-height: 15px;
  align-self: flex-start;
}

.clientLink {
  text-decoration: none;
}

.clientContainer{
  display: flex;
  align-items: center;
  gap: 3px;
}

.websiteLink {
  > div {
    width: 16px !important;
    height: 16px !important;
    padding: 0 !important;

    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
}
