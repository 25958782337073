@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins';

.container {
  padding: 0px 18px 10px 18px;
  max-height: calc(100vh - $header-height - 48px);
  overflow-y: auto;
}

.infiniteScroll {
  overflow: visible !important;
  display: flex;
  flex-direction: column;
}

.item {
  padding: 7px 0px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: flex-start;
  align-items: flex-start;
  height: 98px;
  line-height: 19px;

  + .item {
    border-top: 1px solid $input-border-color;
  }
}

.titleRectangle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
}

.title {
  padding: 10px 14px 2px 14px !important;
  height: 452px;
  background-color: $white !important;
  border-radius: $main-border-radius !important;
}

.title > div:first-child {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.itemIcon {
  width: 16px;
}

.itemData {
  flex: 1 calc(100% - 16px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.itemDescription {
  font-size: 12px;
  font-weight: 600;
  color: $blue-header;
}

.itemLink {
  color: var(--Greyscale-900, #1a202c);
  font-size: 14px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemDate {
  font-size: 10px;
  font-weight: 500;
  color: $slate-gray;
}

.itemSubtitles {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  align-items: center;
}

.itemSubtitles:hover {
  text-decoration: underline;
}

.noNews {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding-top: 25px;
}

.editTitle {
  display: flex;
  justify-content: flex-end;
  padding-right: 18px;
  font-weight: 600;
}

.titleActions {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}

.lastRefreshed {
  padding-left: 18px;
  font-size: 10px;
  font-weight: 500;
  color: $slate-gray;
}
