@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.avatar {
  height: 140px;
  width: 140px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 64px;
  color: $secondary-color;
  background-color: $white !important;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
  position: relative;
}

.avatarContainer {
  height: 130px;
  width: 130px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatarImg {
  max-height: 130px;
  max-width: 130px;
}

.headerTitle {
  text-align: left;
  color: $transparent-gray;
  font-size: 14px;
  font-weight: 500;
}

.headerValue {
  text-align: left;
  color: $secondary-color;
  font-size: 18px;
  font-weight: 600;
}

.infoDivider {
  width: 100%;
  border-bottom: 1px solid $gallery;
  padding-top: 24px;
}

.fieldsContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.modalRoot {
  width: 465px !important;
}

.textFormField {
  padding-top: 28px;

  :global {
    .MuiInput-root {
      max-height: 150px;
      overflow: scroll;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}

.selectField {
  min-width: 150px;
}

.editIcon {
  position: absolute;
  bottom: 7px;
  right: 7px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.avatar:hover .editIcon {
  visibility: visible;
  opacity: 1;
  > path {
    fill: $white;
  }
}

.avatar:hover .avatarContainer {
  filter: brightness(50%) grayscale(60%);
  background-color: $white;
}

.modalFooter {
  width: 100%;
}

.avatarWrapper,
.imageUploadWrapper {
  padding-top: 10px;
}

.avatarText {
  width: 130px;
  height: 130px;
  text-align: center;
  align-content: center;
}

.avatar:hover .avatarText {
  filter: brightness(50%) grayscale(60%);
  background-color: $white;
}

.headerContainer {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  align-items: center;
  margin-top: 16px;
  min-height: 140px;
}

.contentContainer {
  flex: 1;
  margin-bottom: 8px;
  height: 100%;
  display: flex;
  align-items: center;
}

.avatarGridContainer {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 140px;
}

.modalDescription {
  text-align: left;
  color: $slate-gray;
}
