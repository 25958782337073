@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.wrapper {
  max-width: 554px;
  width: 100%;
  margin: 0 auto 20px;
}

.stepConnectorLine {
  border-color: $blue-gray;
  border-top-width: 1px;
  border-top-style: dashed;
}

.completedStepConnector {
  .stepConnectorLine {
    border-color: $primary-color;
  }
}

.activeStepConnector {
  .stepConnectorLine {
    border-color: $primary-color;
  }
}

.stepCompleted {
  cursor: pointer;
}

.stepLabelActive {
  color: $primary-color !important;
}

.stepIcon {
  height: 32px;
  width: 32px;
  color: $white;
  overflow: visible;
  stroke: $input-border-color;
}

.stepIconText {
  fill: $slate-gray;
  stroke: none;
}

.stepIconActive {
  color: $primary-color !important;
  stroke: none;

  .stepIconText {
    fill: $white;
  }
}
