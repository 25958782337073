@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.select {
  font-size: $main-font-size;
  padding: 8px 14px;
  line-height: 20px;
  background-color: $input-background-color;
  color: $input-color;

  text-align: left;

  &:focus,
  &[aria-expanded='true'] {
    border-radius: $main-border-radius;
    outline: 1.5px solid $cerulean !important;
  }

  :global {
    .MuiFormControl-root .MuiSelect-select {
      .MuiInputBase-root.Mui-disabled {
        & > div {
          color: $disabled-input;
        }
      }
    }
  }
}

.menuPaper {
  box-shadow: 0px 2px 8px rgba(35, 47, 53, 0.09);
  margin-top: 7px;

  ul.menuList {
    color: $dark-blue;

    li.listItemRoot {
      &:hover {
        background: $dropdown-hovered-color;
      }
    }

    li.selected,
    li.selected:hover {
      background: $dropdown-selected-color;
    }
  }
}

.rightAlignSelect {
  text-align: right;
  padding-right: 25px !important;
}

.listItemRightAlign {
  display: flex;
  justify-content: flex-end;
}

.inputLabel {
  color: $input-border-color;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px !important;
}

.filterMenu {
  height: 48px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;

  :global {
    .MuiInput-root {
      height: 48px;
      width: 243px;

      border-radius: $small-border-radius;
    }

    .MuiInputBase-input {
      padding: 8px 8px;
      font-size: 14px;
      font-weight: 400;
    }

    .MuiSelect-icon {
      right: 8px;
      top: calc(50% - 0.5em);
    }
  }
}
.filterMenuItem {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #1a202c;
  letter-spacing: 0.2px;
}

.filterInputLabel {
  // put in center of input
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  font-size: 14px;
}

.applyButton {
  width: 93%;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  height: 36px;
  justify-content: center;
}

.divider {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.placeholder {
  color: $transparent-gray;
  opacity: 1;
  font-weight: 600;
  text-transform: capitalize;

  &:hover {
    font-size: 14px;
    opacity: 1;

    font-weight: 600;
    color: $black;
  }

  &:focus {
    font-size: 14px;
    font-weight: 600;
    color: $transparent-gray;
  }

  &:focus:hover {
    color: $transparent-gray !important;
  }
}

.root {
  :global {
    .MuiSelect-select {
      width: 60% !important;
    }
  }
}

.focused {
  background-color: #ffffff !important;
}

.options {
  max-height: 300px;
  overflow-y: auto;
}

.withTopLabel {
  @include select-field-with-top-label;
  text-transform: capitalize;

  &.borderSelected {
    fieldset {
      border-color: black !important;
    }
  }
}

.filterBox {
  position: relative;
  label {
    display: none;
  }
  :global {
    .MuiFormControl-root {
      height: 48px;

      > div {
        padding: 0 36px 0 8px;
      }
    }
  }

  .placeholder {
    color: $transparent-gray;
    opacity: 1;
    font-weight: 600;
    text-transform: capitalize;
  }

  &:hover .placeholder {
    font-size: 14px;
    opacity: 1;

    font-weight: 600;
    color: $black;
  }

  &:focus .placeholder {
    font-size: 14px;
    font-weight: 600;
    color: $transparent-gray;
  }

  &:focus:hover .placeholder {
    color: $transparent-gray !important;
  }

  &:hover .filterMenu > svg:nth-of-type(1) {
    > path {
      fill: $black;
    }
  }

  &:hover .borderSelected > svg:nth-of-type(1) {
    > path {
      fill: $transparent-gray !important;
    }
  }
}

.filterBoxBorder {
  :global {
    .MuiFormControl-root {
      border: 1px solid $input-border-color;
      border-radius: 5px;
    }
  }
}

.startAdornment {
  width: 20px;
  height: 20px;
}
