@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';

.documentsTitleRow {
  min-height: 40px;
}

.fileName {
  overflow: hidden !important;
}

.documentTable {
  margin-top: 10px;

  table {
    tbody {
      max-height: unset;
    }

    tr {

      th,
      td {
        padding-left: 20px !important;

        &:nth-child(1) {
          width: 4%;
          padding-left: 0 !important;
        }

        &:nth-child(2) {
          width: 41%;
          position: relative;
          overflow: visible;
        }

        &:nth-child(3) {
          width: 25%;
        }

        &:nth-child(4) {
          width: 25%;
        }

        &:nth-child(5) {
          width: 75px;
        }
      }
    }
  }

  &.documentTableWithRecordDate {
    table {
      tr {
        th,
        td {
          &:nth-child(2) {
            width: 36%;
          }

          &:nth-child(3),
          &:nth-child(4) {
            width: 20%;
          }

          &:nth-child(5) {
            width: 10%;
          }

          &:nth-child(6) {
            width: 75px;
          }
        }
      }
    }
  }

  &.documentTableWithBankInfo {
    table {
      tr {
        th,
        td {
          &:nth-child(2) {
            width: 26%;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            width: 13%;
          }

          &:nth-child(8) {
            width: 75px;
          }
        }
      }
    }
  }

  &.documentTableReadOnly {
    table {
      tr {
        th,
        td {
          &:nth-child(1) {
            padding-left: 20px !important;
            width: 50%;
          }

          &:nth-child(2) {
            width: 25%;
          }

          &:nth-child(3) {
            width: 25%;
          }
        }
      }
    }

    &.documentTableWithRecordDate {
      table {
        tr {
          th,
          td {
            &:nth-child(1) {
              width: 40%;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              width: 20%;
            }
          }
        }
      }
    }

    &.documentTableWithBankInfo {
      table {
        tr {
          th,
          td {
            &:nth-child(1) {
              width: 30%;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              width: 14%;
            }
          }
        }
      }
    }
  }
}

.fileNameIcon {
  vertical-align: middle;
  margin-right: 5px;
}

.selectedItems {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: $secondary-color;
}

.nextButtonIcon {
  transform: rotate(-90deg);

  path {
    fill: $white;
  }
}

.downloadButton {
  padding: 12px 16px;
  line-height: 20px;
  height: 44px;
  background-color: white;
  border: 1px solid $button-border-color !important;
  color: $secondary-color;
  margin-right: 10px;

  &:hover {
    background: transparent;
    border-color: $secondary-color !important;
    color: $secondary-color;
  }

  :global {
    .MuiLoadingButton-loadingIndicator {
      svg {
        color: $secondary-color !important;
      }
    }
  }
}

.addDocumentButton {
  height: 36px;
  border-color: transparent;

  &:hover {
    svg {
      path {
        stroke: $cerulean;
      }
    }
  }
}

.dropWrapper {
  position: relative;
}

.dropOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 1px dashed $input-border-color;
  border-radius: $main-border-radius;
  background-color: $gray-background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  z-index: 11;
}

.uploadOverlay {
  padding-left: 3%;
}

.dueDiligenceDocumentSelect {
  :global {
    .MuiInput-input {
      border: 1px solid $input-border-color;
      height: 18px !important;
      min-height: 18px !important;
    }
  }
}

.dueDiligenceDocumentCurrency {
  height: 36px !important;

  :global {
    .MuiInput-root {
      height: 36px !important;
    }
  }
}

.dueDiligenceDocumentInvalid {
  :global {
    .MuiInput-root,
    .MuiInput-input {
      border-color: $red-text;
    }
  }
}

.fileElement {
  display: inline;
}

.dueDiligenceDocumentDate {
  width: 100%;
  height: 36px !important;

  :global {
    .MuiInput-root {
      height: 36px !important;
    }
  }
}

.iconButton {
  cursor: pointer;
  vertical-align: middle;
  border-radius: $small-border-radius;

  + .iconButton {
    margin-left: 10px;
  }
  &:hover {
    background-color: $button-border-color;
  }
}

.iconButtonDelete {
  cursor: pointer;
  vertical-align: middle;
  margin-right: 10px !important;
  border-radius: $small-border-radius;

  + .iconButtonDelete {
    margin-left: 10px;
  }
  &:hover {
    background-color: $button-border-color;
  }
}

.dueDiligenceDocumentSuccess {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;

  path {
    fill: $green-text;
  }
}

.dueDiligenceDocumentHasError {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;
  fill: $red-text;
}

.link {
  color: $black !important;
  text-decoration: none !important;
}

.downChevron {
  margin-left: 10px;
  margin-top: 3px;
  fill: $primary-color;
}

.actionsMenuItem {
  min-width: 91px;
}
.isParentRow {
  z-index: 10;
  position: relative;
}

.childBorders {
  position: absolute;
  top: 25px;
  left: 34px;
  height: 1px;
  width: calc(100% - 20px);
  background: $gothic;

  &:before {
    content: ' ';
    position: absolute;
    top: -50px;
    left: 0;
    height: 50px;
    width: 1px;
    background: $gothic;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 0px;
    width: 1px;
    background: $gothic;
  }
}

.unprocessedFlowsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  max-height: calc(70vh - 288px);
  overflow-y: auto;
}

.unprocessedFlowItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  height: 38px;
}

.unprocessedFlowItemNameContainer {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unprocessedFlowItemName {
  font-size: 16px;
  color: $primary-color !important;
  display: block;
  height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none !important;
  text-align: left;
}

.unprocessedFlowItemDeleteContainer {
  background: $light-red;
  border-radius: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.disabledMessage {
  font-size: 14px;
  color: $red-text;
}
