@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.title {
  margin-right: 10px;
  color: $black;
  font-weight: 700;
  font-size: 18px;
  line-height: 48px;
}

.reportingDocumentsContainer {
  flex: 1;
  max-width: 920px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.submitButtonContainer {
  flex: 1;
  max-width: 920px;
}

.reportingDocumentsContainerListWrapper {
  width: 450px;
  padding: 5px 5px 5px 18px;
  max-height: calc(100vh - $header-height - 172px);
  overflow-y: auto;
  background: $white;
  z-index: 1;

  &.reportingDocumentsContainerListWrapperShort {
    max-height: calc(100vh - $header-height - 216px);
  }
}

.reportingDocumentsContainerList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reportingDocumentsContainerListItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 13px 21px;
  border: 1px solid $button-border-color;
  border-radius: $small-border-radius;
  cursor: pointer;
  min-height: 75px;
}

.reportingDocumentsContainerListItemSelected {
  border-color: $primary-color;
}

.reportingDocumentsContainerListItemText {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.reportingDocumentsContainerListItemName {
  color: $secondary-color;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
}

.reportingDocumentsContainerListItemHelperText {
  color: $slate-gray;
  font-size: 10px;
  line-height: 16px;
}

.reportingDocumentsContainerListItemIcons {
  display: flex;
  align-items: center;
}

.reportingDocumentsContainerListItemOverDue {
  font-size: 10px;
  line-height: 16px;
  color: $red-text;
}

.reportingDocumentsContainerListItemDue {
  font-size: 10px;
  line-height: 16px;
  color: $slate-gray;
}

.reportingDocumentsContainerListItemCount {
  width: 20px;
  height: 20px;
  background: $success-green;
  border-radius: 50%;
  text-align: center;
  font-size: $main-font-size;
  line-height: 20px;
  color: $white;
  font-weight: 700;
  margin-left: 5px;
}

.documentsUploadWrapper {
  max-width: 480px;
  margin-left: 10px;
  padding: 5px 10px;
  flex: 1 1;
  display: flex;
  min-height: 100%;
  min-width: 300px;
}

.documentsUpload {
  padding: 10px 15px 10px 15px;
  flex: 1 1;
  box-shadow: 0 0 15px 0 rgba(17, 24, 38, 0.08);
  border-radius: 10px;
}

.submitButton {
  color: #ffffff;
  background-color: $primary-color;
  height: 48px;
  width: 120px;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
}

.submitButton:hover {
  background-color: #003cbe;
}

.submitModalButton {
  color: #ffffff;
  background-color: $primary-color;
  font-size: 16px;
  width: 100%;
  height: 56px;
}

.actionTitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 30.24px;
  padding: 5px;
  color: #1a202c;
  top: 24px;
}

.cardInProgressWrapper {
  width: 500px;
  height: 400px;
}

.cardInProgress {
  padding-top: 55px;
  padding-bottom: 110px;

  @media only screen and (max-width: 1599px) {
    width: 1000px;
  }

  @media only screen and (max-width: 1279px) {
    width: 700px;
  }
}

.bankAccountSelect {
  width: 155px;
}
.accountBalanceInput {
  width: 200px;
}

.documentsWrapper {
  margin: 10px 0;
}

.documentItem {
  padding: 2px 0;
}

.documentItemTitle {
  margin-bottom: 10px;
}

.documentItemTitleName {
  font-size: $main-font-size;
  font-weight: 700;
  color: $blue-wale;
}

.documentItemTitleLabel {
  display: inline-block;
  margin-left: 5px;
  padding: 3px 7px;
  color: $dark-blue;
  background: $concrete;
  font-size: 12px;
  line-height: 14px;
  border-radius: $main-border-radius;
}

.documentItemDescription {
  padding-top: 8px;
  margin-left: 10px;
  width: 100%;
  font-weight: 500;
}

.documentItemDescriptionLabel {
  color: #1a202c;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
}

.documentItemDescriptionTitle {
  color: $blue-wale;
  font-size: 12px;
}

.documentItemFiles {
  flex: 10;
  display: flex;
  flex-wrap: wrap;
  &:first-child {
    margin-top: 10px;
  }
}

.overdueDocumentItemFiles {
  flex: 10;
  display: flex;
  flex-wrap: wrap;

  &:first-child {
    margin-top: 10px;
  }
}
.uploadedFiles {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.uploadFile {
  color: $red;
}

.inputsBlock {
  position: relative;
  margin-top: 10px;
}

.amountTypeRadios {
  display: flex;
  flex-direction: column;

  label {
    margin-left: 0 !important;
    height: 35px;

    :global {
      .MuiTypography-root {
        font-weight: normal;
      }
    }
  }
}

.inputContainer {
  position: absolute;
  color: $red;
  top: 35px;
  left: 148px;
}

.textInput {
  margin-top: 6px;
  width: 105px;
  height: 24px;
  font-size: 4px;

  :global {
    .MuiInputBase-input {
      padding: 2px 12px;
      line-height: 20px;
    }
  }
}

.documentItemFilesItem {
  display: block;
  justify-content: center;
  margin-top: 2px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.descriptionInProgress {
  font-size: 18px;
  font-weight: 500;
  color: $slate-gray;
}

.iconPending {
  margin-bottom: 45px;
}

.backToDashboard {
  width: 100%;
  height: 56px;
  background-color: $primary-color;
  font-size: 16px;
  font-weight: 600;
}

.documentContainer {
  margin-top: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmButtonContainer {
  display: flex;
  float: bottom;
}

.capTableRadios {
  padding-left: 42px !important;
}

.radioRoot {
  padding-left: 5px !important;
}

.reportingDocumentsContainerListItemDisabled {
  opacity: 0.5;
  cursor: default;
}

.submitButtonDisabled {
  opacity: 0.5;
  cursor: default;
}

.sampleLink {
  color: $primary-color;
  cursor: pointer;
  font-weight: 600;
}
