@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins.scss";

.navigationBar {
  @include navigation-bar;
  z-index: 3;

  &.navigationBarWithNotes {
    width: calc(100% - #{$drawer-collapsed-width} - #{$notesDrawerWidth});
    left: $drawer-collapsed-width;

    @media only screen and (min-width: 1800px) {
      width: calc(100% - #{$drawer-collapsed-width} - #{$bigNotesDrawerWidth});
    }
  }

  &.navigationBarWithSidebar {
    width: calc(100% - #{$drawer-expanded-width});
    left: $drawer-expanded-width;

    &.navigationBarWithNotes {
      width: calc(100% - #{$drawer-expanded-width} - #{$notesDrawerWidth});
      left: $drawer-expanded-width;

      @media only screen and (min-width: 1800px) {
        width: calc(100% - #{$drawer-expanded-width} - #{$bigNotesDrawerWidth});
      }
    }
  }

  &.navigationBarModal {
    width: 95%;
    left: 2.5%;
    z-index: 1301;
  }
}
.navigationBarLabel {
  @include navigation-bar-label
}
