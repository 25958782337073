@import "../../assets/styles/colors";

.wrapper {
  margin: 0 auto;
  max-width: 700px;
}

.heading {
  font-weight: 800;
  font-size: 20px !important;
  color: $secondary-color;
  text-align: left;
  line-height: 1.25;
}

.allEnabledWrapper {
  justify-content: center;

  > label {
    margin-left: 6px;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.25;
    font-weight: 800;
  }
}

.checkbox {
  padding: 0;
}

.settingsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.settingsRow {
  display: grid;
  grid-template-columns: 1fr 100px 100px;
  font-size: 14px;
  line-height: 1.25;

  > div {
    &:nth-child(2),&:nth-child(3) {
      text-align: center;
    }
  }
}

.settingsRowHeader {
  border-bottom: 1px solid $border-color;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 800;
}
