@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";
@import '../../../assets/styles/mixins';

.iconMenu {
  padding: 6px 9px;
  box-shadow: 0 0 2px 0 rgba($black, 0.2);
  border-radius: $small-border-radius;
}

.iconMenuItem {
  min-width: 100px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
}

.aggregationTable {
  border-radius: $main-border-radius;

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 125px;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 35%;
      }
      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(6) {
        width: 50px;
      }
    }

    td {
      &:nth-child(5) {
        padding-right: 10px;
      }
    }

    &.deletedRow {
      opacity: 0.5;
    }
  }
}

.aggregationTableFullHeight {
  table tbody {
    max-height: unset !important;
    height: 61vh;
    @media only screen and (max-height: 900px) {
      height: 47vh !important;
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 125px;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 35%;
      }

      &:nth-child(4) {
        width: 15%;
      }





    }
  }
}

.aggregationTableType {
  color: $primary-color;
  font-weight: 500;
  cursor: pointer;
}

.linkIcon {
  margin-left: 5px;
  vertical-align: middle;
  width: 22px;
  height: 22px;
}

.linkIconFilled {
  path, circle {
    stroke: $primary-color !important
  }
}



.selectFormField {
  border: 1px solid $input-border-color;
  border-radius: $small-border-radius;
  height: 48px;

  :global {
    .MuiSelect-icon {
      top: 12px
    }

    .MuiSelect-select {
      font-size: 14px;
    }
  }
}

.creatableSelectFormField {
  :global {
    .MuiInput-input {
      font-size: 14px;
    }

    .MuiInput-root {
      height: 48px;
    }
  }
}

.dateFormField {
  width: 100%;
  height: 48px !important;

  :global {
    .MuiInput-root {
      padding: 8px 14px;
      height: 48px !important;
      width: 100% !important;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}

.textFormField {
  :global {
    .MuiInput-root {
      height: 48px;
    }

    .MuiInput-input {
      font-size: 14px;
    }
  }
}


.plusIcon {
  path {
    stroke: $white;
  }
}

.selectField {
  width: 104px;
  height: 36px;

  @include select-field-with-top-label;

}

.openBoxView {
  cursor: pointer;
  color: $primary-color;
  font-weight: 500;
}