@import "../../assets/styles/colors";
@import "../../assets/styles/variables";


.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formContent {
  padding-top: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 36px 24px;
  width: 100%;
  max-width: 670px;

  > div {
    flex: 0 calc(50% - 12px);
  }
}

.inputLabel {
  text-align: left;
  color: $secondary-color;
  letter-spacing: 0.2px;
  font-weight: bolder;
}

.iconButtonVisibility {
  color: $p-color;
  padding: 2px;
  margin-right: 5px;
}

.inputField {
  :global {
    .MuiInputBase-input {
      font-weight: normal;
      text-align: left;
    }
  }
}

.selectField {
  :global {
    .MuiSelect-select {
      line-height: 30px;
      border-radius: $small-border-radius;
      font-size: 14px;
      outline: none !important;
      border: 1px solid $input-border-color;
    }

    .MuiSelect-icon {
      top: 12px
    }
  }
}

.datePickerField {
  width: 100%;

  :global {
    .MuiInput-root {
      width: 100% !important;
      height: 48px;
      padding: 8px;

      .MuiInputBase-input {
        font-size: 14px;
      }
    }
  }
}

.autocompleteField {
  :global {
    .MuiAutocomplete-inputRoot {
      min-height: 48px;
      height: 48px;
      border: 1px solid $input-border-color;
      border-radius: 5px;
      padding: 8px 36px 8px 8px !important;

      .MuiInputBase-input {
        font-size: 14px;
      }

      .MuiAutocomplete-endAdornment {
        right: 5px;
      }

      &:before {
        display: none;
      }
    }
  }
}

.booleanFieldRow {
  flex: 1 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booleanFieldRowLabel {
  font-size: 14px;
}
