@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.notesList {
  overflow-y: auto;
  position: relative;
}

.tagSection {
  margin-bottom: 1.5vh;
  min-height: 3.5vh;
  display: flex;
  flex-direction: column;
}

.tagSectionHeader {
  border-bottom: 1px solid $button-border-color;
  margin-bottom: 1.5vh;
  padding: 0 0 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tagSectionTitle {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  color: $black;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  > svg {
    width: 14px;
    height: 14px;

    > path {
      fill: $primary-color;
    }
  }
}

.tagSectionNotes {
  gap: 12px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}
