@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.wiresDebtorsWrapper {
  padding: 0 !important;
}

.wiresDebtorsTitle {
  color: $primary-color;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
}

.wiresDebtorsTable {
  tbody {
    max-height: 50vh !important;

    @media only screen and (max-height: 800px) {
      max-height: 60vh !important;
    }
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 150px;
      }

      &:nth-child(2) {
        width: 20%;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 20%;
      }

      &:nth-child(5) {
        width: 20%;
      }

      &:nth-child(6),
      &:nth-child(7) {
        width: 10%;
      }

      &:nth-child(8) {
        width: 5%;
      }
    }
  }

  tbody {
    td {
      :global {
        .MuiFormControl-root {
          > div {
            width: 100% !important;
            max-width: none !important;
          }
        }
      }
    }
  }
}

.wiresReconciliationTable {
  tr {
    td,
    th {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 25%;
      }

      &:nth-child(3) {
        width: 45%;
      }
    }
  }
}

.wiresDebtorsTable,
.wiresReconciliationTable {
  tbody {
    tr {
      background-color: $white;

      &.validRow {
        background: $green-background;
        color: $black;
        font-weight: 700;
        span {
          color: $black;
        }
      }

      &.inValidRow {
        background: $red-background !important;
        color: $black;
        font-weight: 700;
        span {
          color: $black;
        }
      }

      .actionBtn {
        display: none;
        cursor: pointer;
      }

      .negativeIcon {
        margin-right: 10px;

        path {
          fill: $light-red;
        }
      }

      &:hover {
        .actionBtn {
          display: inline-flex;
        }
      }
    }
  }
}

.actionBtn {
  cursor: pointer;
}

.wiresDebtorsMemo {
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.predictionCard {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $main-border-radius;
  color: $white;
  background-color: $chip-blue;
  cursor: pointer;
}

.sectionHeader {
  color: $dark-blue !important;
  margin: 15px 0 10px !important;
}

.columnWrapper {
  position: relative;
}

.negativeLabel {
  position: absolute;
  left: 10px;
  display: inline-block;
  padding: 0 10px;
  color: $gothic;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  background: $white;
  border-radius: 11px;
  border: 1px solid $gothic;
}
