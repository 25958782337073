@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.documentsList {
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 20px;
}

.documentsListItem {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 20px;
}

.documentsListItemLabel {
  min-width: 60px;
  margin-right: 16px;
  color: $dusty-gray;
}

.documentsListItemFieldWrapper {
  display: flex;
  flex-direction: column;
}

.filesListSelect {
  height: 32px;
  width: 100%;

  > button {
    width: 100%;
  }
}

.filesListItemTitle {
  font-weight: 600;
  font-size: $main-font-size;
  color: $secondary-color;
  margin-right: 16px !important;
  margin-top: 10px;;
}

.filesListItemDocument {
  height: 32px;
  margin-right: 10px;
}

.rawToggle {
  padding-top: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: $dusty-gray;
  background-color: $white;
  transition-duration: 0.2s;
}

.fileSelectCard {
  margin-bottom: 10px;
}

.modalPaper {
  background-color: $modal-background-color;
  width: 70vw;
  max-width: 1020px;
}

.modalHeader {
  min-height: 36px;
}

.uploadText {
  font-weight: 600;
  color: $primary-color;
  margin-left: 5px;
}

.uploadDocumentHelper {
  font-weight: 400;
  color: $secondary-color;
  text-align: left;

}

.uploadTextContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.backButton {
  margin-right: 5px;
  cursor: pointer;
}

.modalLink {
  color: $primary-color;
  cursor: pointer;
  text-align: right;
  margin-top: 15px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 800;
}

