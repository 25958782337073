@import './forms';
@import './icons';
@import './variables';
@import './fonts.scss';
@import './colors.scss';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  text-align: right !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html,
body,
#root {
  a {
    text-decoration: none;
  }

  h1,
  h2,
  h3 {
    margin: 0;
    text-align: start;
  }

  h1 {
    font-size: $header1-font-size;
    line-height: $header1-font-size;
    color: $blue-wale;
  }

  h2 {
    font-size: $header2-font-size;
    line-height: $header2-font-size;
    color: $header2-color;
  }

  h3 {
    font-size: $header3-font-size;
    line-height: $header3-font-size;
    color: black;
  }
  p {
    font-weight: 500;
    font-size: $p-font-size;
    color: $p-color;
  }
}
body {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    padding: 0 2px;
    border: 1px solid $wild-sand;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: $scrollbar-color;
    border-radius: 8px;
    background-clip: content-box;
  }

  [aria-hidden='true'] {
    [class*='RightSidebar_drawerPaper'] {
      margin-right: 10px;
    }
  }
}

table {
  tbody {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
      padding: 0 2px;
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      background-color: $scrollbar-color;
      border-radius: 8px;
      background-clip: content-box;
    }
  }
}

.MuiMenu-list {
  padding: 0;

  .MuiListItem-root,
  option {
    padding: 10px;
    font-size: $main-font-size;
    line-height: 20px;

    &:hover {
      background: $gallery;
    }

    &.Mui-selected {
      background: $lily-white;
    }
  }
}

.MuiDialog-root {
  z-index: 1501 !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  padding: 0 2px;
  border: none;
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-color: $scrollbar-color;
  border-radius: 8px;
  background-clip: content-box;
}

#google-map {
  display: none;
}
