@import "../../../assets/styles/colors";

.searchContainer {
  transition: width 0.3s linear;
  display: flex;
  align-items: center;

  &.searchContainerOpen {
    flex: 1;
  }
}

.searchField {
  max-width: 200px;
  display: none;

  :global {
    .MuiInput-root {
      height: 46px;

      .MuiInput-input {
        padding: 13px 18px;
        height: 44px;
        box-sizing: border-box;
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        text-overflow: ellipsis;
      }
    }
  }

  &.searchFieldOpen {
    display: block;
  }

  &.searchFieldFocused {
    max-width: 430px;

    :global {
      .MuiInput-root {
        .MuiInput-input {
          max-width: calc(100% - 36px);
        }
      }
    }
  }

  &:not(.searchFieldOpen) {
    margin-right: 15px;
  }
}

.searchIcon {
  width: 18px;
  height: 18px;
  margin-left: 18px;
}

.searchButton {
  width: 46px;
  height: 46px;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
  color: $secondary-color;

  > svg > path {
    fill: black !important;
  }

  &:hover {
    background-color: $button-border-color;
  }
}

.smallFilter {
  margin-right: 5px !important;
  width: 40px;
  height: 40px;
}

.smallTitle {
  margin-right: 0px !important;

  :global {
    .MuiBox-root {
      margin-right: 0px !important;
    }
  }
}

.hiddenSearchButton {
  display: none;
}

.clearAllButton {
  background-color: transparent;
  text-decoration: underline;
  color: $primary-color;
  margin-left: 5px;

  &:hover {
    background-color: transparent;
  }
}

.title {
  margin-right: 10px;
  color: $black;
  font-weight: 700;
  font-size: 18px;
  line-height: 48px;
}

.spaceBeforeAdditionalActions {
  flex: 1;
}


.quickFiltersContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 5px;
}

.quickFiltersLabel {
  color: $secondary-color;
  font-size: 12px;
  font-weight: 400;
}
