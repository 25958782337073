@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.uploadHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px 0;
  color: $secondary-color;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid $button-border-color;
  cursor: pointer;

  svg {
    fill: black;
  }
}

.documentsAndCommentsSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > div > div {
    max-height: 250px;
  }
}

.documentsSection {
  flex: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow-y: scroll;
  margin-bottom: 10px;
  transition: flex-basis 0.4s;
  scroll-behavior: smooth;
  @include thin-scrollbar;

  &.commentsWrapperExpanded,
  &.documentsSectionExpanded {
    flex-basis: 100%;
  }
}

.commentsEmpty {
  display: flex;
  align-items: center;
  color: $p-color;
  font-size: 12px;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;

    circle {
      fill: $p-color;
    }
    path {
      stroke: $p-color;
    }
  }
}

.templateLink {
  cursor: pointer;
  color: $primary-color;
  font-size: $main-font-size;
}
