@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.modalRoot {
  bottom: 60px;
  top: auto;
  right: 120px;
  left: auto;
  transform: none;
}

.iconWrapper {
  position: fixed;
  right: 30px;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);
}

.iconNotesWrapper {
  bottom: 60px;
  background: $primary-color;

  &:hover {
    background-color: $secondary-color;
  }
}

.iconItemsToMonitorWrapper {
  bottom: 50px;
  background: $white;

  &:hover {
    background-color: $alabaster;
  }
}

.modalRoot {
  padding: 14px 10px;
  display: flex;
  width: 742px;
  min-height: 70vh;
  bottom: 60px;
  top: auto;
  right: 120px;
  left: auto;
  transform: none;
}

.modalBody {
  text-align: left;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 60px;

  span {
    margin-left: 10px;
    color: $gothic;
    font-size: $header2-font-size;
    font-weight: bold;
  }

  > div {
    display: flex;
    align-items: center;
  }
}

.modalTitleIcon {
  width: 14px;
  height: 14px;

  path {
    fill: $gothic;
  }
}

.modalContent {
  padding: 10px;
  flex: 1 1 auto;
  max-height: 100%;
  overflow: hidden;
}
