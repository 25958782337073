@import "../../assets/styles/colors";
@import "../../assets/styles/variables";

.header {
  border: 1px solid $border-color;
  margin-bottom: -8px;
  padding: 0 20px;
  border-radius: $small-border-radius $small-border-radius 0 0;
}

.table {
  tr {
    height: 30px;
    td, th {
      &:nth-child(1) {
        width: 16%;
      }
    }
  }

  &.arTable {
    tr {
      td, th {
        &:nth-child(1) {
          width: 13%;
        }

        &:nth-child(12) {
          width: 7%;
        }
      }
    }
  }

  tbody {
    max-height: calc(100vh - 300px) !important;

    tr {
      td {
        &:first-of-type {
          border-left: 1px solid $border-color;
        }

        &.totals {
          padding-right: 0px !important;

          > p {
            width: 100%;
            display: block;
            background: $catskill-white;
            font-weight: 700;
            color: $black !important;
            text-align: right;
            margin: 0px;
            padding: 2px 10px 2px 10px;
          }
        }
      }
    }
  }
}

.warningIcon {
  margin-left: 10px;
}

.apActiveToolbar {
  order: 2;

  > div {
    width: 14%;

    &:nth-child(1) {
      width: 16%;
    }
  }
}

.arActiveToolbar {
  order: 2;

  > div {
    width: 8%;

    &:nth-child(1) {
      width: 13%;
    }

    &:nth-child(12) {
      width: 7%;
    }
  }
}

.arSummaryActiveToolbar {
  > div {
    width: 12%;

    &:nth-child(1) {
      width: 18%;
    }

    &:nth-child(8) {
      width: 10%;
    }
  }
}

.fullScreenModal {
  padding-top: 20px;
  overflow-y: auto;

  .table {s
    table {
      tbody {
        max-height: calc(95vh - 325px) !important;
      }
    }
  }
}

.select {
  :global {
    .MuiSelect-select {
      padding: 3px 14px;
    }

    .MuiSelect-icon {
      top: 2px;
    }
  }
}

