@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.table {
  thead {
    tr {
      margin: -8px 0;
    }
  }

  tbody {
    position: relative;
    max-height: 240px !important;

    tr {
      td {
        &.totals {
          background: $wild-sand;
          font-weight: 700;
        }
      }
    }
  }
}

.arTable {
  tr {
    td, th {
      &:nth-child(1) {
        width: 182px !important;
      }

      &:nth-child(2) {
        width: 50px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: auto !important;
      }


      &:nth-child(8) {
        width: 158px;
      }
    }
  }
}

.apTable {
  tr {
    td, th {
      &:nth-child(1) {
        width: 212px !important;
      }

      &:nth-child(2) {
        width: 50px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: auto !important;
      }

      &:nth-child(8) {
        width: 96px;
      }
    }
  }
}
