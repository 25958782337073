@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables";


.filesListItemTitle {
  font-weight: 700;
  font-size: $main-font-size;
  color: $blue-wale;
  width: 80px;
}

.filesListItemDocuments {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filesListItemDocument {
  height: 35px;
}

.modal {
  position: relative;
  min-width: 376px;
  background: $white;
  border-radius: $main-border-radius;
  outline: 0;
  width: 60%;
  height: 80vh;
  padding: 0;
}

.aliasesTable {
  table {
    tbody {
      max-height: calc(80vh - 318px);
    }
  }
}

.typesTable {
  table {
    tbody {
      max-height: calc(80vh - 278px);
    }
  }
}


.tabContainer {
  max-width: fit-content;
  margin-left: calc(32px + 24px);
}
