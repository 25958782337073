@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables";

.table {
  min-height: 55vh;

  tr {
    td,
    th {


      &:nth-child(2) {
        width: 35%;
        padding-left: 5px;
      }
      &:nth-child(4){
        width:150px;
      }


    }

    .entityPreviewIcon {
      display: none;
    }

    &:hover {
      .entityPreviewIcon {
        display: inline;
      }
    }
  }
}

.aliasTable {
  min-height: 55vh;

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 35%;
      }

      &:nth-child(2) {
        width: 35%;
        padding-left: 5px;
      }

      &:nth-child(3) {
        width: 30%;
      }

      &:nth-child(4) {
        width: 20%;
      }

      &:nth-child(5) {
        width: 30%;
      }
    }
  }
  tbody {
    max-height: calc(70vh - 197px) !important;

    tr {


      &.predictedRow {
        background: rgba($gold, 0.25);
      }

      &.activeRow {
        background-color: white !important;
      }

      td {
        :global {
          .MuiFormControl-root {
            width: 100%;

            > div {
              width: 100%;
            }

            .Mui-focused {
              background-color: $input-hovered-background-color !important;
              outline: 1.5px solid $cerulean !important;
              border-radius: $small-border-radius !important;
            }
          }

          .Mui-focused {
            outline: none !important;
          }
        }
      }
    }
  }



}



.saveButton {
  padding-top: 6px;
}

.tableHeaderContainer {
  display: flex;
  padding-bottom: 5px !important;

  div {
    padding-bottom: 0 !important;
  }
}

.tabContainer {
  margin-right: 50px;
  padding-top: 8px;
}

.percentMatch {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $small-border-radius;
  color: $white;
  cursor: default;
}

.percentMatchWithPrediction {
  display: inline-block;
  font-size: $main-font-size;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: $small-border-radius;
  color: $white;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  vertical-align: middle;
}

.percentMatchNew {
  background: $cerulean;
}

.percentMatchLow {
  background: $red-background;
  color: $red-text !important;

  :hover {
    background: $red-background;
    color: $red-text;
  }
}

.percentMatchLowActive {
  background: $red-text;

  :hover {
    background: $red-text;
  }
}

.percentMatchMedium {
  background: $yellow-background;
  color: $yellow-text !important;

  :hover {
    background: $yellow-background;
    color: $yellow-text;
  }
}

.percentMatchMediumActive {
  background: $yellow-text;

  :hover {
    background: $yellow-text;
  }
}

.percentMatchHigh {
  background: $green-background;
  color: $green-text !important;

  :hover {
    background: $green-background;
    color: $green-text;
  }
}

.percentMatchHighActive {
  background: $green-text;

  :hover {
    background: $green-text;
  }
}


.newValue {
  background-color: $blue-background !important;
  color: $primary-color !important;
}

.shortLabel {
  max-width: calc(100% - 17px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
