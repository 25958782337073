@import '../../../assets/styles/colors';

.exportButton {
  height: 32px;
  border: none;
  min-width: 32px;
  padding: 8px 4px;
  &:hover {
    border: none;
    background-color: $button-border-color;
  }
  > svg {
    width: 24px;
    height: 24px;
    > path {
      padding: 5.25px;
    }
  }
}

.exportData {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}
