@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables";

.availabilitySummaryTitle {
  font-weight: 800;
  font-size: 16px !important;
  align-content: center;
}

.availabilitySummaryItem {
  margin-top: 10px;
  font-size: $main-font-size;
  line-height: 15px;
  color: $blue-wale;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.availabilitySummaryItemBorder {
  margin-top: 10px;
  font-size: $main-font-size;
  line-height: 15px;
  color: $blue-wale;
  display: flex;
  position: relative;
  padding-bottom: 5px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #718096;
  }
}

.inventoryAvailabilityTitle {
  padding-bottom: 5px;
}

.availabilitySummaryItemTotal {
  margin-top: -2px !important;
}

.tableContainer {
  width: 95%;
  margin: 0 auto;
}

.availabilitySummaryItemTitleHeader {
  font-weight: 700 !important;
}

.availabilitySummaryItemHeader {
  padding-bottom: 5px;
  padding-top: 5px;
}

.summaryColumnLeft {
  padding-left: 40px;
  margin-right: 90px;
}

.availabilitySummaryItemTitle {
  font-size: $main-font-size;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  width: 70%;
  color: #718096;
}

.numbersContainer {
  display: grid;
  align-items: center;
  justify-content: flex-end;
  grid-template-columns: 60% minmax(0, 1fr);
}

.availabilitySummaryItemValue {
  font-weight: 500;
  text-align: right;
}

.availabilitySummaryItemeValueHeader {
  font-weight: 700 !important;
}

.availabilityValue {
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: flex-end;
}

.availabilityHeader {
  font-weight: 700 !important;
}

.popover {
  background: $white;
  border: 1px solid $gallery;
  border-radius: $main-border-radius;
}

.tooltip {
  background: none;
  margin: 0 !important;
  padding: 0;
}

.availabilitySummaryItemTitlePopover {
  font-size: $main-font-size;
  margin: 10px;
  margin-top: 0;
  width: 60%;
}

.infoIcon {
  margin-left: 2px;
  display: flex;
  justify-content: flex-end;
  // vertical-align: middle;
  cursor: pointer;
}

.infoIconSize {
  font-size: 1rem;
}

.flagsBlock {
  position: relative;
  padding: 15px;
  border-radius: $main-border-radius;
  background: $light-blue-gray;
  min-height: 100%;
}

.warningIcon {
  // margin-right: 2px;
  fill: $sunshade;
  font-size: 1.5rem;
}

.flagTextPrimary {
  display: flex;
  justify-content: flex-start;
  margin-left: 2px;
  font-size: 13px;
  font-weight: bold;
  color: $black;
}

.flagTextSecondary {
  display: flex;
  justify-content: flex-start;
  margin-left: 2px;
  font-size: 12px;
  color: $black;
}

.flagListItem {
  display: flex;
  justify-content: space-around;
  margin: 0;
}

.flagListIcon {
  display: flex;
  justify-content: flex-start;
  min-width: 30px;
}

.flagsSummaryTitle {
  font-weight: 700;
}

.availabilityImpact {
  margin-left: 5px;
}

.menuIcon {
  position: absolute;
  right: 5px;
  top: 1px;
  cursor: pointer;
}

.viewDetails {
  color: $black !important;
}

.reported {
  opacity: 50%;
}
