@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables';

.mainCard {
  height: 385px;
  display: flex;
  flex-direction: column;
}

.mainCardWitouthManager {
  height: 452px !important;
}

.newsContainer {
  height: 452px;
}

.mainCardTitle {
  font-size: 24px;
}

.rightPanelContainer {
  height: 100%;
}

.mainCardContent {
  height: 100%;
}

.mainCardTitle {
  font-size: 24px !important;
}
