@import '../../assets/styles/colors';

.iconVisibility {
  font-size: 16px;
  transform: rotateY(180deg);
}

.iconButtonVisibility {
  color: $primary-color;
}

.resetClass {
  width: 420px;
  padding: 30px 0 0 0;
}
