@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.card {
  height: 100%;
}

.invitationSpan {
  font-size: 12px;
  background-color: $dropdown-selected-color;
  color: $slate-gray;
  border-radius: $small-border-radius;
  padding: 3px 7px;
  line-height: 14px;
  display: inline-block;
  font-weight: 600;
}
.active {
  font-size: 12px;
  background-color: $light-green;
  color: $success-green;
  border-radius: $small-border-radius;
  padding: 3px 7px;
  line-height: 14px;
  display: inline-block;
  font-weight: 600;
}
.iconRoot {
  vertical-align: middle;
}

.table {
  tr {
    td,
    th {
      &:nth-child(4) {
        width: 150px;
      }
      &:nth-last-child(3) {
        width: 140px;
      }
      &:nth-last-child(2) {
        width: 90px;
      }
      &:last-child {
        width: 60px;
      }
    }
    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  td {
    :global {
      .MuiCheckbox-root {
        padding: 0;
      }
    }
  }
}

.participantsTable {
  tr {
    td,
    th {
      &:nth-child(4) {
        width: 150px !important;
      }
      &:last-child {
        width: 60px !important;
      }
    }
  }
}

.editModal {
  width: 406px;
  background: $modal-background-color;
}

.editModalFooter {
  justify-content: space-between;
  margin-top: 8px;
  padding-bottom: 0 !important;
}

.editModalTitle {
  margin-bottom: 22px;
}

.signorWarningIcon {
  width: 12px;
  margin-right: 6px;
  fill: $sunshade;
}

.signorWarningText {
  color: $sunshade;
}

.authorized {
  color: $success-green !important;
  font-weight: 500;
  font-size: 12px;
}

.notAuthorized {
  color: $red-text !important;
  font-weight: 500;
  font-size: 12px;
}

.MuiPopover-paper {
  box-shadow: none !important;
}

.fieldsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  td {
    padding-bottom: 2px !important;
    width: 100%;
  }
  label {
    font-weight: 600;
    font-size: 14px;
    color: $secondary-color;
  }
}

.checkboxContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: -8px;
  padding-top: 10px;
  td {
    padding-bottom: 2px !important;
    padding-top: 6px !important;
  }
  label {
    font-weight: 600;
    font-size: 14px;
    color: $secondary-color;
  }
}
.submitModalButton {
  width: 100%;
  margin-top: 10px;
  min-height: 52px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.tableContainerData {
  margin-top: 10px;
}

.filtersHeader > div > div {
  align-items: center !important;
  button {
    text-transform: capitalize;
  }
}
