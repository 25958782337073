@import "./colors";

:global {
  .blue-button {
    height: 67px;
    width: 100%;
    margin-top: 38px;

    background: $curious-blue;
    color: $white;
    border-radius: 3.79px;
    border: none;
    font-size: 26.86px;
    &:hover, &:focus {
      background: $curious-blue;
      border-color: $curious-blue;
      color: $white;
    }
  }

  .auth-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > button {
      @extend .blue-button;

      @media only screen and (max-width: 1279px) {
        font-size: 12px;
        height: 30px;
        margin-top: 15px;
      }
    }
  }
}

.form-input {
  align-self: stretch;
  margin-bottom: 0;
  line-height: 1;

  input {
    border-color: $mercury;
    height: 58px;
    padding-left: 22px;

    @media only screen and (max-width: 1279px) {
      height: 30px;
      padding-left: 10px;
    }
  }
}
