@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.modal {
  position: absolute;
  background: $light-gray;
  padding: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: unset;
  outline: 0;
  width: 500px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  color: $secondary-color;
  font-weight: 800;
  font-size: 20px;
  line-height: 40px;
  text-align: left;
}

.modalHeader {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-top: -2px;
  margin-bottom: 5px;
  padding: 10px 20px;
  border-bottom: 1px solid $button-border-color;
  gap: 8px;
}

.modalBody {
  font-size: $main-font-size;
  line-height: 20px;
  padding: 10px 20px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.modalBodyUW {
  padding: 10px 32px 32px !important;
}
