@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.tooltip {
  padding: 8px 12px;
  background: $white;
  color: $tundora;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
  font-size: 12px;
  line-height: 1.25;
}

.arrow {
  color: $white;
}

.tooltipContent {
  min-width: 80px;
  display: flex;
  flex-direction: column;
}

.tooltipContentHeader {
  margin: 16px 0 4px;
  font-weight: 700;
}
