.table {
  padding-top: 5px;
  min-width: 500px;
  display: flex;
  flex-direction: column;

  table {
    flex: 1;
  }

  &.tableWithMinHeight {
    height: 500px;
  }

  tr {
    td,
    th {
      &:nth-child(4) {
        width: 80px;
      }
    }
  }

  tbody {
    max-height: 365px !important;
  }
}

.paginationWrapper {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}

.saveButton {
  float: right;
  margin-left: 10px;
  margin-top: 5px;
}
