@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.modal {
  position: absolute;
  min-width: 376px;
  background: $white;
  border-radius: $main-border-radius;
  top: 3%;
  right: auto;
  bottom: auto;
  left: 2.5%;
  outline: 0;
  width: 95%;
  height: calc(95vh - 30px);
  padding: 0;
  overflow: hidden;
}

.modalBody {
  font-size: $main-font-size;
  line-height: 20px;
  text-align: center;
  height: calc(95vh - 30px);
  overflow-y: auto;

  :global {
    a {
      text-decoration: none;
    }

    .MuiTableContainer-root {
      // Padding + card header
      max-height: calc(95vh - 105px);
    }
  }

  table {
    tbody {
      // Padding + card header + table header
      max-height: calc(95vh - 136px) !important;
    }
  }
}

.modalClose {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 22px;
  z-index: 1;
}

.noBorder {
  > MuiPaper-root {
    border: none;
  }
}
