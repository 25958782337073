@import '../../assets/styles/colors';

.skipButton {
  background-color: transparent;
  text-decoration: underline;
  color: $primary-color;
  margin-left: 15px;

  &:hover {
    background-color: transparent;
  }
}

.connectButton {
  background: $success-green;

  &:hover {
    background: $currency-up;
  }
}
