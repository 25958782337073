.table {
  padding-top: 5px;
  min-width: 500px;
  min-height: 300px;
    tr {
      td,
      th {
        &:nth-child(4) {
          width: 80px;
        }
      }
    }
    tbody {
      max-height: 50vh !important;
    }
   
  }
  

  .addButton {
    margin-top: 4px;
    margin-right: 50px;
  }

  .saveButton {
    float: right;
    margin-left: 10px;
    margin-top: 5px;;
  }