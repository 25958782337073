@import "../../assets/styles/colors.scss";
@import "../../assets/styles/variables.scss";

.header {
  font-size: 18px;
  line-height: 1.25;
  padding-bottom: 15px;
  font-weight: 800;
  border-bottom: 1px solid $button-border-color;
}

.withBorder {
  border-bottom: 1px solid $button-border-color;
}

.label {
  margin-bottom: 12px;
  color: $slate-gray;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
}

.value {
  color: $secondary-color;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}

.valueWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px
}

.valuesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}

.valuesItem {
  padding: 9px 24px;
  font-size: 14px;
  line-height: 1.25;
  color: $secondary-color;
  background: $border-color;
  border-radius: $small-border-radius;
}

.booleanLabel {
  font-size: 14px;
}

.booleanValue {
  border-radius: $small-border-radius;
  border: 1px solid $button-border-color;
  font-size: 14px;
  line-height: 1.5;
  padding: 9px 10px;
  min-width: 60px;
  text-align: center;
}

.booleanValueTrue {
  border-color: $success-green;
  background: $success-green;
  color: $white;
}

.booleanComment {
  padding: 24px;
  border-radius: $main-border-radius;
  background: $gray-background-color;
  color: $slate-gray;
  font-size: 14px;
  line-height: 2;
}
