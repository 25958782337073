.table {
  width: 100%;
  th, td {
    &:last-child {
      width: 60px;
      text-align: right;
   
    }
  }
}
