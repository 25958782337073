@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.root {
        &:focus {
            outline: 1.5px solid $cerulean;
          }
  :global {
    .MuiInput-root {
      background-color: #FFFFFF;
      border: 1px solid $input-border-color;


      &:focus {
        outline: 1.5px solid $cerulean;
      }

    }



    .MuiInput-root.Mui-focused {
      border: 1px solid $primary-color;          
      border-color: transparentize($primary-color, 0) !important;
      border-radius: $small-border-radius;
    }

    .MuiInput-root.Mui-disabled {
      background-color: $disabled-input-background;
      color: $disabled-input;
      border-color: $input-border-color !important;
    }



    .MuiInputBase-input {
      font-size: $main-font-size;
      line-height: 20px;

    }

    .MuiIconButton-root {
      background-color: transparent;
    }

    .MuiInputAdornment-root {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $input-color;
    }



    .MuiInputAdornment-positionStart {
      margin-left: 3px;
      margin-right: 0px;
    }

    .MuiInputAdornment-positionEnd {
      margin-left: 0px;
      margin-right: 3px;
    }
  }

  &:focus {
    :global {
      .MuiInput-root {

        outline: 1.5px solid $cerulean;
      }

      .MuiInputAdornment-root {
        background-color: $light-blue-gray;
      }
    }
  }
}

.input {
  cursor: text;

}

.inputError {
  border: 1px solid $light-red;
  border-color: $red-border!important;
  
}

.inputError:focus-within {
  border: 1px solid $primary-color;
    border-color: transparentize($primary-color, 0);
    border-radius: $small-border-radius;


}



.inputSmallSize {
  :global {
    .MuiInput-root {
      height: 36px;
      border-radius: $small-border-radius;
    }

    .MuiInputBase-input {
      padding: 2px 7px;
    }

    .MuiSvgIcon-root {
      width: 14px;
      height: 14px;
    }

    .MuiIconButton-root {
      width: 18px;
      height: 18px;
      border-radius: $small-border-radius;
    }

    .MuiInputAdornment-root {
      min-width: 18px;
      min-height: 18px;
      border-radius: $small-border-radius;
    }
  }
}

.inputBigSize {
  :global {
    .MuiInput-root {
      height: 48px;

      border-radius: $small-border-radius;
    }

    .MuiInputBase-input {
      padding: 8px 8px;
      font-size: 14px;
      font-weight: 600;
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }

    .MuiIconButton-root {
      width: 28px;
      height: 28px;
      border-radius: $small-border-radius;
    }

    .MuiInputAdornment-root {
      min-width: 28px;
      min-height: 28px;
      border-radius: $small-border-radius;
    }
  }
}

.inputFilterMenu {
  :global {
    .MuiInput-root {
      height: 48px;
      width: 100%;

      border-radius: $small-border-radius;
    }

    .MuiInputBase-input {
      padding: 8px 8px;
      font-size: 14px;
      font-weight: 400;
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }

    .MuiIconButton-root {
      width: 28px;
      height: 28px;
      border-radius: $small-border-radius;
    }

    .MuiInputAdornment-root {
      min-width: 28px;
      min-height: 28px;
      border-radius: $small-border-radius;
    }
  }
}

.inputHideStartAdornmentBorder {
  :global {
    .MuiInputAdornment-root.MuiInputAdornment-positionStart {
      background-color: transparent !important;
    }
  }

  &:hover,
  &:focus {
    :global {
      .MuiInputAdornment-root.MuiInputAdornment-positionStart {
        background-color: transparent !important;
      }
    }
  }
}

.inputHideEndAdornmentBorder {
  :global {
    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
      background-color: transparent !important;
    }
  }

  &:hover,
  &:focus {
    :global {
      .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
        background-color: transparent !important;
      }
    }
  }
}

.textRight {
  :global {
    .MuiInputBase-input {
      text-align: right;
    }
  }
}

.formHelperTextRoot {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  color: #eb5757;
}

.inputMultiline {
  :global {
    .MuiInput-root {
      height: auto;
    }
  }
}
