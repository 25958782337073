@import '../../../assets/styles/variables';
@import '../../../assets/styles/colors';

.root {
  min-height: 36px;
  box-sizing: border-box;
  border: 1px dashed $input-border-color;
  border-radius: $main-border-radius;
  text-align: center;
  background-color: $gray-background-color;
  padding: 0 10px 0 10px;
  align-items: center;
  display: flex;
}
.container {
  height: 100%;
}
.containerLarge {
  padding: 0 20px 0 20px;
}

.rootLarge {
  text-align: left;
  height: 58px;
  width: 100%;
  background-color: $white;

  :global {
    .MuiDropzoneArea-root {
      width: 100%;
      height: 58px;
    }

    .MuiDropzoneArea-textContainer {
      margin: auto;
    }
  }

  .textContainer {
    margin-left: 0px;
  }

  .chooseFileText {
    display: block;
    margin-top: 5px;
  }
}

.rootExtraLarge {
  height: 75px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  position: absolute;
  top: 24px;
  right: 143px;
  width: 16px;
  height: 16px;
}
.iconWithTitle {
  margin-left: 200px;
}

.textContainer {
  margin-top: auto;
  margin-left: 5px;
  margin-bottom: auto;
  font-size: $main-font-size;
  line-height: 1;
}

.chooseFileText {
  color: $cerulean;
  text-decoration: underline;
}

//place title on left middle of file component

.title {
  line-height: 1;
  color: $black;
  font-size: 14px;
  font-weight: 600;
}

.dropzoneText {
  color: $secondary-color;
  font-size: 12px;
  font-weight: 400;
}
.dropzoneTextContainer {
  display: flex;
  justify-content: flex-end;
}

.dropzoneTextContainerExtraLarge {
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}

.fileSelectButton {
  color: $primary-color;
  cursor: pointer;
  font-weight: 600;
}

.filesAdded {
  padding: 4px 8px;
  background: $light-green;
  border-radius: 5px;
  color: $success-green;
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
}

.manageLink {
  color: $primary-color;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.documentModal {
  text-align: left;
  width: 100%;
}

.fileName {
  font-weight: 500;
  font-size: 14px;
  color: $secondary-color;
  display: block;
  height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link {
  color: $primary-color !important;
  text-decoration: none !important;
}

.fileNameContainer {
  max-width: 329px;
  text-overflow: ellipsis;
}
.line {
  height: 4px;
  background: $success-green;
  border-radius: 10px;
}

.date {
  font-weight: 400;
  font-size: 10px;
  color: $slate-gray;
}

.deleteContainer {
  margin-left: 15px;
  background: $light-red;
  border-radius: 24px;
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}

.deleteGrid {
  display: grid;
  place-items: center;
  align-items: center;
  justify-items: center;
}

.clear {
  color: $primary-color;
  font-weight: 600 !important;
  font-size: 16px;
  cursor: pointer;
}

.modalDropzone {
  width: 100%;
  height: 91px;
  border-radius: $main-border-radius;
  margin-bottom: 20px;
  display: grid;
  place-items: center;
  align-items: center;
  justify-items: center;
  font-weight: 500;
  background-color: $gray-background-color;

  &.modalDropzoneSmall {
    height: 75px;
    margin-bottom: 10px;
  }
}

.accepted {
  border: 2px dashed $mountain-meadow;
  background-color: $light-green;
}

.rejected {
  border: 1px dashed $red;
  background-color: $light-red;
}

.frequency {
  color: $p-color;
  font-weight: 400;
}

.documentContainer {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.disabled {
  pointer-events: none;
  color: $dusty-gray;
}
