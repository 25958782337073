@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.title {
  color: $secondary-color;
  font-size: 20px;
  line-height: 2;
  font-weight: 800;
}

.titleSearchString {
  margin-left: 10px;
  color: $primary-color;
}

.section {
  margin-top: 24px;
  display: flex;
  padding: 14px 24px;
  flex-direction: column;
  gap: 10px;
  background: $row-background-color;
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 800;
}

.sectionTable {
}

.table {
  tr {
    height: 46px;
  }
}

.viewMoreSection {
  margin-top: 35px;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
}

.viewMoreLink {
  color: $primary-color;
  cursor: pointer;
}

.clientAvatar {
  width: 32px;
  height: 32px;
  img {
    object-fit: contain;
  }
}

.notesContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.noteContainer {
  display: flex;
  flex: 1 0 calc(33% - 15px);
  padding: 14px;
  flex-direction: column;
  gap: 8px;
  border-radius: $main-border-radius;
  border: 1px solid $button-border-color;
  background: $white;
}

.noteTitle {
  color: $slate-gray;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.noteTextWrapper {
  display: flex;
  flex: 1;
}

.noteText {
  flex: 1;
  color: $slate-gray;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  cursor: pointer;
}

.noteTextExpanded {
  max-height: none;
  overflow: visible;
  display: block;
}

.noteTextExpandWrapper {
  display: flex;
  justify-content: flex-start;
}

.noteTextExpandButton {
  cursor: pointer;
  margin: 0;
}

.noteFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noteDate {
  color: $p-color;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
}

.noteRelations {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px
}

.noteRelation {
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: $small-border-radius;
  background: $border-color;
  color: $primary-color;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  cursor: pointer;
}

.fullScreenModal {
  text-align: left;
  padding: 50px;
  overflow-y: auto;

  table {
    tbody {
      max-height: calc(95vh - 75px - 100px - 79px - 64px - 64px) !important;
    }
  }

  .notesWrapper {
    max-height: calc(95vh - 75px - 100px - 79px - 72px) !important;
    overflow-y: auto;
  }
}

.fullScreenModalTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid $button-border-color;
  margin-bottom: 24px;

  .title {
    line-height: 1;
  }
}

.fullScreenModalTitle {
  line-height: 30px;
  font-weight: 800;
}
