@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables';

.table {
  tbody {
    max-height: 65vh !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 180px;
      }
      &:nth-child(3) {
        width: 70px;
      }
      &:nth-child(14) {
        width: 80px;
      }
      &:last-child {
        width: 70px;
      }
    }
  }
}

.tableParticipant {
  tbody {
    max-height: 65vh !important;
  }

  tr {
    td,
    th {
      &:nth-child(1) {
        width: 180px;
      }
    }
  }
}

.tableCellWithIcon {
  height: 100%;
  position: relative;
  margin-left: auto;
  text-align: right;
  font-size: $main-font-size;
  padding-left: 40px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.linkBtn {
  cursor: pointer;
}
.actionButton {
  padding: 0 !important;
}
.menuItem {
  color: $black !important;
}
.linkBtn {
  text-decoration: none !important;
}

.pendingIcon {
  position: absolute;
  top: 5px;
  right: 80px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  vertical-align: middle;
}

.clickableNotification {
  cursor: pointer;
}

.clientName {
  display: inline-block;
  position: relative;
}

.speakerNotesIcon {
  position: absolute;
  top: 0.01em;
  margin-left: 20px;
  cursor: pointer;
}
.reportingStatusCell {
  padding: 3px 8px;
  border-radius: $small-border-radius;
  font-weight: 600;
}

.green {
  a {
    color: $green-text !important;
  }
  background-color: $green-background;
}
.red {
  a {
    color: $red-text !important;
  }
  background-color: $red-background;
}

.yellow {
  a {
    color: $yellow-text !important;
  }
  background-color: $yellow-background;
}

.formLabel {
  text-align: left;
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  animation: rotate 2s linear infinite;
  path {
    fill: $jungle-green;
  }
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: $white;
  border-radius: $small-border-radius;
  color: $black;
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  
  &:first-child {
    margin-bottom: 4px;
  }
}

.tooltipTitle {
  font-weight: 700;
}
