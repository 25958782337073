@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";

.table {
  tr {
    td, th {
      &:nth-child(1) {
        width: 50px;
      }

      &:nth-child(6) {
        width: 80px;
      }
    }
  }
}

.icon {
  margin-right: 10px;;
}

.value {
  text-align: left;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.button {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: $white;
  background: $primary-color;
  padding: 8px;
  border-radius: $small-border-radius;
  font-weight: 500;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background: $cerulean;
  }
}

.plusIcon {
  path {
    stroke: $white;
  }
}


.link {
  cursor: pointer;
}